<template>
  <div class="club-activity-page">
    <div style="width: 790px; height: 287px; background: #FFFFFF; border-radius: 10px; margin-bottom: 15px;">
      <club-info :detail="clubDetail" :myRole="myRole" :clubRoles="clubRoles" @refresh="getClubDetail"></club-info>
    </div>
    <club-nav :current="2"></club-nav>
      <div class="club-activity-page-inner" v-if="!isEmpty">
        <div v-for="(item, index) in list" :key="index">
          <activity-item :info="item" @refresh="refresh" ></activity-item>
        </div>
      </div>
      <div class="empty" v-if="isEmpty">
        <div class="empty-title">暂无活动</div>
      </div>
  </div>
</template>

<script>
import clubInfo from '../../components/club/club-info.vue'
import clubNav from '../../components/club/club-nav.vue'
import activityItem from '../../components/club/club-activity-item.vue'
export default {
  data() {
    return {
      list: [],
      pageIndex: 1,
      pageSize: 30,
      nomore: false,
      loading: false,
      isEmpty: false,
      isLoading: false,
      clubDetail: null,
      myRole: 0,
      clubRoles: [],
    }
  },

  components: {
    clubNav,
    activityItem,
    clubInfo
  },

  computed:{
    //这里需要把store 动态的数据放到computed里面才会同步更新 视图
    roles(){
      this.getClubActivity()
      return this.$store.state.clubRoles
    }
  },

  created() {
    this.getMyClubRole()
    this.getClubDetail()
    this.getClubActivity()
    window.addEventListener("scroll",this.scroll); 
  },

  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },

  methods: {
    // 时间格式化
    timeFormat(time) {
      var arr = time.split(/[- :]/);   
      let nndate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
      nndate = Date.parse(nndate);
      let times = new Date(nndate).getTime();
      let timeStr = this.$timeFormat(times, 'mm.dd hh:MM');
      return {times, timeStr}
    },

    refresh() {
      this.list = []
      this.pageIndex = 1
      this.pageSize = 10
      this.nomore = false
      this.getClubActivity()
    },

    async getClubActivity() {
      this.isLoading = true
      try {
        let res = await this.$request.get(`/home/clubHomeActivity?clubId=${this.$route.params.clubId}&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`)
        if(res.page.totalCount == 0) {
          this.isEmpty = true
        }
        else {
          this.isEmpty = false
          let data = res.page.list
          let nowTime = new Date().getTime()
          let dealTime = new Date()
          let nowTimes = dealTime.setHours(dealTime.getHours() + 2);
          let joinEndTime
          let startTime
          let endTime
          let timeFormat
          let address
          let baseUrl = this.$global.baseUrl
          for(let item of data){
            timeFormat = this.timeFormat(item.joinEndTime)
            joinEndTime = timeFormat.times
            endTime = this.timeFormat(item.endTime).times
            startTime = this.timeFormat(item.startTime).times
            if(item.userId == this.$store.state.userInfo.id) {
              item.isMyActivity = true
            }
            else {
              item.isMyActivity = false
            }
            if(nowTime > joinEndTime){
                item.isOverJoin = 1
            }else{
                item.isOverJoin = 2
            }
            if(nowTimes > startTime){
                item.afterCheacked = 1
            }else{
                item.afterCheacked = 2
            }
            if(nowTime > endTime){
                item.isOver = 1
            }else{
                item.isOver = 2
            }
            // 格式化活动开始/结束时间
            item.startTimeStr = this.timeFormat(item.startTime).timeStr
            item.endTimeStr = this.timeFormat(item.endTime).timeStr
            // 处理活动封面图连接
            item.url = `${baseUrl}${item.urlResponseVoList[0].url}?imageMogr2/thumbnail/300x`
            // 截取活动地址
            address = item.address.split('|');
            item.showAddress = address[1];
          }
          this.list = [...this.list, ...data]
          if(res.page.totalPage == res.page.pageIndex) {
            this.nomore = true
          }
          else {
            this.nomore = false
            this.pageIndex++
          }
        }
      } catch (error) {
        // console.log('error', error)
      }
      this.isLoading = false
    },

     // 获取俱乐部信息
    async getClubDetail() {
      try {
        let res = await this.$request.get(`/clubInfo/selectByClubId/${this.$route.params.clubId}`)
        this.clubDetail = res.data
      }
      catch (e) {
        //
      }
    }, 
    
    //获取用户在俱乐部的权限
    async getMyClubRole() {
      try {
        let res = await this.$request.get(`/clubMemberInfo/getMyRole/${this.$route.params.clubId}`)
        let data = {
          clubId: this.$route.params.clubId,
          role: 0,
          canEditNews: false,
          canEditActivity: false,
          canEditMember: false,
          canEditTheme: false,
          canEditData: false,
          canEditClub: false
        }
        if(res.data.role) {
          this.myRole = res.data.role
          this.clubRoles = res.data.roles
          data.role = res.data.role
          for(let i = 0; i < res.data.roles.length; i++) {
            if(res.data.roles[i].roleId == 2) {
              // 俱乐部动态管理
              data.canEditNews = true
            }
            else if(res.data.roles[i].roleId == 3) {
              // 俱乐部活动管理
              data.canEditActivity = true
            }
            else if(res.data.roles[i].roleId == 4) {
              // 俱乐部成员管理
              data.canEditMember = true
            }
            else if(res.data.roles[i].roleId == 5) {
              // 俱乐部相册管理
              data.canEditTheme = true
            }
            else if(res.data.roles[i].roleId == 6) {
              // 编辑俱乐部信息
              data.canEditClub = true
            }
            else if(res.data.roles[i].roleId == 7) {
              // 查看俱乐部数据
              data.canEditData = true
            }
          }
        }
        this.$store.commit('SET_CLUBROLES', data)
      } catch (error) {
        //
      }
    },

    //监听页面滚动
    scroll() {
      // 距离底部50px时加载一次
      let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 50
      if (bottomOfWindow && !this.isLoading && !this.nomore && !this.isEmpty) {
        this.getClubActivity()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.club-activity-page-inner {
  width: 790px;
  box-sizing: border-box;
}
.empty {
  width: 790px;
  height: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  .empty-title {
    color: #444;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>