<template>
  <div class="content" v-loading="loading">
		<div class="article-item" v-for="(item,index) in aritcleArr" :key="index" @click="goArtilceDetail(item.url)">
			<div class="img-card">
				<img-card class="img-pic" :type="2" :url="item.cover" Radius="3px"></img-card>
			</div>
			<h3 class="article-title">{{item.title}}</h3>
			<p class="article-context">{{item.introduction}}</p>
			<p class="artclie-time">{{item.releaseTime}}</p>
		</div>
  </div>
</template>

<script>
	import imgCard from '../components/img-card.vue'
export default {
  data(){
    return{
      pageIndex:1,				// 当前页数
			noMore:false,				// 没有更多数据
      loading: false,			// 数据加载
			aritcleArr: [],			// 文章数据
    }
  },
	components:{
		imgCard
	},
	
  mounted(){
    this.getAritcle()
  },
  
  methods:{
		// 打开文章详情
		goArtilceDetail(url){
			window.open(url,'_blank')
		},

    // 获取文章列表
    async getAritcle(){
			if(this.noMore) {
				return
			}
      this.Loading = true
			try{
        let res = await this.$request.get(`/home/getWxOfficialAccountArticle?pageIndex=${this.pageIndex}&pageSize=10`)
				this.aritcleArr = res.page.list
				if (res.page.totalPage == this.pageIndex) {
					this.noMore = true
				} else {
					this.pageIndex += 1
					this.noMore = false
				}
					
			}catch(e){
				// 
			}
      this.Loading = false
    },     
  }
}
</script>

<style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    width: 1180px;
		display: flex;
		flex-wrap: wrap;
    .article-item {
			cursor: pointer;
			width: 580px;
			height: 350px;
			box-sizing: border-box;
			background-color: #fff;
			border-radius: 10px;
			margin-bottom: 20px;
			padding:20px 20px 10px 20px;
      transition: all ease 0.2s;
			overflow: hidden;
			position: relative;
			.img-card{
				box-sizing: border-box;
				height: 220px;
				width: 100%;
				border-radius: 3px;
				padding-bottom: 15px;
				overflow: hidden;
				.img-pic{
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					border-radius: 3px;
				}
			}
			.article-title{
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-size: 16px;
				font-weight: 500;
				color: #000;
			}
			.article-context{
				font-size: 14px;
				color: #444444;
			}
			.artclie-time{
				position: absolute;
				bottom: 10px;
				right: 20px;
				font-size: 14px;
				color: #A8A8A8;
			}
		}
		.article-item:nth-child(2n){
			margin-left:20px;
		}
		.article-item:hover {
			transform: scale(1.02);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
		}
  }
</style>