<template>
  <div class="home-nav">
    <div class="top-item" :class="current === 1 ? 'current' : ''" @click="clickTab(1)">
      <span class="text">动态</span>
    </div>
    <div class="top-item" :class="current === 2 ? 'current' : ''" @click="clickTab(2)">
      <span class="text">活动</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      haveAuthority: false,
    }
  },

  props: {
    current: {
      type: Number,
      default: 0
    }
  },

  methods: {
    clickTab(index) {
      if(index == 1) {
        this.$router.push({name: 'ClubMoment'})
      }
      else if(index == 2) {
        this.$router.push({name: 'ClubActivity'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-nav {
  width: 790px;
  height: 51px;
  background-color: #fff;
  border-radius: 5px 5px 0 0 ;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  .top-item {
    width: 50px;
    margin: 0 35px;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 14px;
    }
  }
  .current {
    color: #1465CF;
  }
}
</style>