import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.timeout = 5000;
axios.interceptors.request.use(function (config) {
    config.headers.common['token'] = localStorage.getItem("imlatte_token")
    config.headers.common['terminalType'] = 'web'
    return config
})

export default {
    get: function (path = '', data = {}) {
        return new Promise((resolve, reject) => {
            axios.get(path, data)
                .then((res) => {
                    // console.log('接口数据 -- get', res)
                    if (res.data.code === 200) {
                        return resolve(res.data)
                    } else if (res.data.code === 400) {
                        // 参数不合法
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 401) {
                        // 没有权限
                        // 清除全部缓存
                        // 跳转至首页
                        return reject(res.data)
                        // this.$message.warning('登录已失效！');
                    } else if (res.data.code === 404) {
                        // 路径不存在
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 500 || res.data.code === 2002) {
                        // 代码异常
                        // console.log('code=500', res.data)
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 2003) {
                        // 活动发布成功，等待后台审核
                        alert(res.data.msg)
                        return resolve(res.data)
                    } else if (res.data.code === 2004) {
                        // 当前时间距离活动开始时间小于两小时，所以你的活动费用需审核通过后退还
                        alert(res.data.msg)
                        return reject(res.data);
                    } else if (res.data.code === 2005) {
                        // 没有绑定邀请码
                        return reject(res.data)
                    } else if (res.data.code === 2006) {
                        // 活动不存在
                        return reject(res.data)
                    } else if (res.data.code === 2007) {
                        // 动态不存在
                        return reject(res.data)
                    } else if (res.data.code === 2008) {
                        // 评论不存在
                        return reject(res.data)
                    } else if (res.data.code === 2009) {
                        // 邀请码当日次数已用完
                        return reject(res.data)
                    } else {
                        return resolve(res.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    post: function (path = '', data = {}) {
        return new Promise((resolve, reject) => {
            axios.post(path, data)
                .then((res) => {
                    // console.log('接口数据 -- post', res)
                    if (res.data.code === 200) {
                        return resolve(res.data)
                    } else if (res.data.code === 400) {
                        // 参数不合法
                        alert(res.data.msg)
                        // this.$alert(res.data.msg, '提示', {
                        //     showCancelButton: true,
                        //     confirmButtonText: '确定',
                        //     beforeClose: (action, instance, done) => {
                        //         if (action === 'confirm') {
                        //             done();
                        //         } else {
                        //             done();
                        //         }
                        //     }
                        // });
                        return reject(res.data)
                    } else if (res.data.code === 401) {
                        // 没有权限
                        // 清除全部缓存
                        // 跳转至首页
                        // alert(res.data.msg)
                        // window.location.href = this.$global.baseUrl; 
                        return reject(res.data)
                    } else if (res.data.code === 404) {
                        // 路径不存在
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 500 || res.data.code === 2002) {
                        // 代码异常
                        // console.log('code=500', res.data)
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 2003) {
                        // 活动发布成功，等待后台审核
                        alert(res.data.msg)
                        return resolve(res.data)
                    } else if (res.data.code === 2004) {
                        // 当前时间距离活动开始时间小于两小时，所以你的活动费用需审核通过后退还
                        alert(res.data.msg)
                        return reject(res.data);
                    } else if (res.data.code === 2005) {
                        // 没有绑定邀请码
                        return reject(res.data)
                    } else if (res.data.code === 2006) {
                        // 活动不存在
                        return reject(res.data)
                    } else if (res.data.code === 2007) {
                        // 动态不存在
                        return reject(res.data)
                    } else if (res.data.code === 2008) {
                        // 评论不存在
                        return reject(res.data)
                    } else if (res.data.code === 2009) {
                        // 邀请码当日次数已用完
                        return reject(res.data)
                    } else {
                        return resolve(res.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}