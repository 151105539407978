<template>
	<div class="about">
		<div class="about-main">
			<!-- 顶部导航 -->
			<div class="nav-top">
				<!-- logo -->
				<div class="logo" @click="clickLogo">
					<div class="logo-box">
						<img class="logo-img" src="../assets/logo.png" fit="cover" alt="imlatte" />
					</div>
					<div class="name">
						<span class="en-name">Latte</span>
					</div>
				</div>
				<div class="call">
					<div class="call-text">联系我们:</div>
					<div class="call-icon">
						<div class="icon">
							<img src="../assets/phone.png" alt="" />
							<span>0755-33207750</span>
						</div>
						<div class="icon">
							<img src="../assets/email.png" alt="" />
							<span>lyuxing@bxwtech.com</span>
						</div>
						<span></span>
					</div>
				</div>
			</div>
			<div class="about-banner"><div class="banner-box"></div></div>
			<div class="about-introduce">
				<div class="introduce-tilte"><span>关于我们，你想知道</span></div>
				<div class="introduce-text"><p>在这里，你将拥有宽松、愉悦、平等的工作环境，你将收获友爱、尊重、 温暖的创业伙伴，更重要的是，你有可能实现自己最大的价值。</p></div>
				<div class="introduce-box">
					<div class="tabs-head">
						<!-- <div class="head-item" :class="tabsCurrent == item.id ? 'active' : ''" v-for="item in tabsName" :key="item.id" @click="showTabs(item.id)">
							{{ item.name }}
						</div> -->
					</div>
					<div class="tabs-box">
						<div class="tabs-box-item">
							<div class="tabs-box-top-title">
								<div class="left">职位</div>
								<div class="right">
									<span>类别</span>
									<span>工作地点</span>
									<span>发布时间</span>
								</div>
							</div>
							<el-collapse v-model="workData" accordion @change="handleChange" v-for="(item, i) in workList" :key="i">
								<div class="item-list-title">
									<div class="left">{{ item.name }}</div>
									<div class="right">
										<span>{{ item.type }}</span>
										<span>{{ item.address }}</span>
										<span>{{ item.time }}</span>
									</div>
								</div>
								<el-collapse-item :name="i + 1">
									<div class="list-title-end" v-for="(items, index) in item.workItem" :key="index">
										<div class="top-name">{{ items.title }}</div>
										<div class="bottom-text" v-for="(itemed, indexs) in items.titleList" :key="indexs">
											<span>{{ itemed.id + '、' }}</span>
											<span>{{ itemed.text }}</span>
										</div>
									</div>
									<div class="top-name">{{ item.endtext }}</div>
									<div class="top-name">{{ item.info }}</div>
								</el-collapse-item>
							</el-collapse>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import Footer from '../components/Footer.vue'
export default {
	components:{
		Footer
	},
	data() {
		return {
			showCall: false,
			showEmail: false,
			tabsName: [{ id: 1, name: '技术' }, { id: 2, name: '产品' }, { id: 3, name: '运营' }, { id: 4, name: '职能' }],
			tabsCurrent: 1,
			workData: [],
			workList: [
				{
					name: '产品经理',
					type: '技术类',
					address: '深圳',
					time: '2021-01-05',
					workItem: [
						{
							title: '岗位职责：',
							titleList: [
								{
									id: 1,
									text: '负责用户获取、注册转化率提升、用户留存、用户召回等核心产品指标的优化；'
								},
								{
									id: 2,
									text: '构建用户转化漏斗，寻求提升转化与留存的点，提出切实可行的产品方案，快速推动方案上线，根据效果不断迭代优化；'
								},
								{
									id: 3,
									text: '推动公司AB实验平台的搭建和优化；'
								},
								{
									id: 4,
									text: '关注行业内的增长策略，探索新的增长方向；'
								}
							]
						},
						{
							title: '岗位要求：',
							titleList: [
								{
									id: 1,
									text: '本科及以上学历，3至5年产品经理工作经验，有用户增长、研发或数据分析背景；'
								},
								{
									id: 2,
									text: '掌握常见的用户增长方法，例如付费推广，免费推广和外部合作，用户运营，产品优化等，能够从增长的视角来综合运用各类手段达成增长目标；'
								},
								{
									id: 3,
									text: '重视数据驱动和结果导向，掌握常见的数据分析工具和技巧，能够从数据出发发现增长的切入点并进行有效迭代；'
								},
								{
									id: 4,
									text: '优秀的沟通能力，能够利用产品价值来驱动产品、工程、设计团队；'
								},
								{
									id: 5,
									text: '具有很强的学习能力，能快速理解业务和数据；'
								},
								{
									id: 6,
									text: '加分项：社交APP深度用户、强烈的好奇心；'
								}
							]
						}
					],
					endtext: '应聘此职位，投递简历至：lyuxing@bxwtech.com',
					info: '简历投递格式请以“应聘职位+姓名”作为邮件标题。'
				},
				{
					name: 'UI设计',
					type: '技术类',
					address: '深圳',
					time: '2020-12-13',
					workItem: [
						{
							title: '岗位职责：',
							titleList: [
								{
									id: 1,
									text: '为公司新产品与新功能提供创意及设计方案；'
								},
								{
									id: 2,
									text: '持续关注设计趋势，分享设计经验，提高团队的设计能力；'
								},
								{
									id: 3,
									text: '参与前瞻性产品的创意体验设计；'
								},
								{
									id: 4,
									text: '在UI设计和用户体验方面进行业界前沿探索和创新'
								}
							]
						},
						{
							title: '岗位要求：',
							titleList: [
								{
									id: 1,
									text: '具有5年以上无线端设计经验；'
								},
								{
									id: 2,
									text: '拥有深厚的设计方法论与娴熟的设计表现能力及创新能力；'
								},
								{
									id: 3,
									text: '富有激情、关注细节、善于沟通，有良好团队合作能力'
								},
								{
									id: 4,
									text: '简历投递请附上具有代表性的相关设计作品。'
								}
							]
						}
					],
					endtext: '应聘此职位，投递简历至：lyuxing@bxwtech.com',
					info: '简历投递格式请以“应聘职位+姓名”作为邮件标题。'
				},
				{
					name: '安卓开发工程师',

					type: '技术类',

					address: '深圳',

					time: '2020-11-07',

					workItem: [
						{
							title: '岗位职责：',

							titleList: [
								{
									id: 1,

									text: '负责App客户端架构设计'
								},

								{
									id: 2,

									text: '负责App客户端核心模块的开发，实现性能优化；'
								},

								{
									id: 3,

									text: '负责技术预研，并对业务开发提供技术支持。'
								}
							]
						},

						{
							title: '岗位要求：',

							titleList: [
								{
									id: 1,

									text: '本科以上学历，至少五年Android开发经验'
								},

								{
									id: 2,

									text: '至少有一年的架构&优化工作，对Android性能与架构有丰富的经验'
								},

								{
									id: 3,

									text: '熟悉SQLite，擅长数据库调优'
								},

								{
									id: 4,

									text: '有过框架设计经验，懂得设计优秀的代码框架'
								},

								{
									id: 5,

									text: '熟悉Java语言、Android SDK和NDK，熟悉Android平台体系结构，阅读过Android系统源码'
								},

								{
									id: 6,

									text: '具有扎实的计算机、数据结构、算法等基础，熟悉常见设计模式'
								}
							]
						}
					],

					endtext: '应聘此职位，投递简历至：lyuxing@bxwtech.com',

					info: '简历投递格式请以“应聘职位+姓名”作为邮件标题。'
				}
			]
		};
	},
	methods: {
		// 点击logo
		clickLogo() {
			this.$router.push({path:'/'})
		},

		//点击选项卡
		showTabs(e) {
			this.tabsCurrent = e;
		},
		
		handleChange() {
			// console.log(val);
		}
	}
};
</script>

<style lang="scss">
.about {
	.about-main {
		.nav-top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 0 5%;
			box-shadow: 0 1px 1px 0 hsla(0, 0%, 60.8%, 0.5);
			.logo {
				cursor: pointer;
				display: flex;
				align-items: center;
				.logo-box{
					width: 38px;
					height: 38px;
					background: #003d6e;
					border-radius: 50%;
					margin-right: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all 0.15s;
				}
				.logo-img {
					width: 24px;
					height: 24px;
					transition: all 0.15s;
				}
				.name {
					width: 40px;
					.en-name {
						color: #222;
						font-size: 17px;
						font-weight: bold;
						transition: all 0.15s;
					}
				}
			}
			.logo:hover .logo-img{
				transform: scale(1.1);
			}
			.logo:hover .logo-box{
				transform: scale(1.1);
			}
			.logo:hover .en-name{
				color: #003d6e;
				font-size: 18px;
			}
			.call {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 15px;
				color: #222;
				.call-text {
					padding: 15px 10px 15px 20px;
					
				}
				.call-icon {
					display: flex;
					align-items: center;
					.icon {
						display: flex;
						align-items: center;
						margin-right: 15px;
						cursor: pointer;
						align-items: center;
						position: relative;
						img {
							width: 26px;
							height: 30px;
							margin-right: 5px;
						}
					}
					.icon:first-child {
						img {
							transform: rotate(45deg);
							width: 30px;
							height: 30px;
							margin-right: 0;
						}
					}
					.icon:hover span {
						color: #005faf;
					}
				}
			}
		}
		.about-banner {
			padding: 100px 0 50px;
			.banner-box {
				width: 60%;
				height: 500px;
				margin: 0 auto;
				background: url(../assets/about.jpg) no-repeat;
				background-size: cover;
			}
		}
		.about-introduce {
			margin: 50px 0;

			.introduce-tilte {
				font-size: 24px;
				color: #000;
				text-align: center;
				padding-bottom: 10px;
				span {
					position: relative;
					color: #222;
					font-size: 24px;
				}
				span::after,
				span::before {
					content: '';
					position: absolute;
					top: 45%;
					width: 80px;
					height: 2px;
					background-color: #333;
				}
				span::after {
					right: -100px;
				}
				span::before {
					left: -100px;
				}
			}
			.introduce-text {
				p {
					width: 700px;
					text-align: center;
					margin: 0 auto;
					color: #666;
				}
			}
			.introduce-box {
				margin-top: 50px;
				box-sizing: border-box;
				.tabs-head {
					display: flex;
					align-items: center;
					justify-content: center;
					.head-item {
						cursor: pointer;
						padding: 3px 15px;
						margin-right: 50px;
						border-radius: 12px;
						color: #000;
					}
					.head-item.active {
						background-color: #ffaf59;
						color: #fff;
					}
					.head-item:last-child {
						margin-right: 0;
					}
				}
				.tabs-box {
					padding-top: 50px;
					.tabs-box-item {
						width: 60%;
						margin: 0 auto;
						box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
						border-radius: 12px;
						overflow: hidden;
						padding: 20px 20px;
						.tabs-box-top-title {
							display: flex;
							justify-content: space-between;
							padding-bottom: 15px;
							align-items: center;
							.left {
								font-size: 18px;
							}
							.right {
								span {
									font-size: 18px;
									margin-right: 50px;
								}
								span:first-child {
									margin-right: 100px;
								}
							}
						}
						.item-box {
							.item-box-title {
								font-size: 15px;
							}
							.item-box-text {
								margin-top: 30px;
								font-size: 12px;
							}
						}
					}
					.item-list-title {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.left {
							position: absolute;
							top: 15px;
							left: 0;
							color: #727272;
						}
						.right {
							position: absolute;
							top: 15px;
							right: 50px;
							color: #727272;
							z-index: 0;
							width: 330px;
							display: flex;
							justify-content: space-between;
							span: {
								font-size: 18px;
								color: #727272;
							}
						}
					}
					.el-collapse-item__header{
						border-bottom: 0;
					}
					.el-collapse-item__wrap{
						border-bottom: 0;
					}
					.el-collapse-item__content {
						background-color: rgba(0, 0, 0, 0.1);
						padding: 10px 20px;
						border-radius: 3px;
						.list-title-end {
							color: #999;
							.top-name {
								font-size: 16px;
								margin-bottom: 20px;
							}
							.bottom-text {
								font-size: 14px;
								padding-left: 10px;
								margin-bottom: 5px;
							}
						}
						.top-name{
							color: #999;
							font-size: 16px;
							margin-bottom: 5px;
						}
					}
				}
			}
		}
		
	}
}
</style>
