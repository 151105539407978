import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: window.localStorage.getItem('imlatte_token'),
		userInfo: null,
		credentialData: null,
		signData: null,
		clubRoles: null,
		isFreshAblum: false,
		clubId: null,
		newsDetail: null,
	},
	mutations: {
		SET_TOKEN: (state, token) => { // 第一个参数为 state 用于变更状态 登录
			state.token = token;
			window.localStorage.setItem('imlatte_token', token)
		},
		CLEAR_STORAGE: (state) => {
			state.token = null
			state.userInfo = null
			window.localStorage.removeItem('imlatte_token')
		},
		SET_USER: (state, data) => {
			state.userInfo = data
		},
		SET_CREDENTIAL: (state, data) => {
			state.credentialData = data
		},
		SET_SIGN: (state, data) => {
			state.signData = data
		},
		SET_CLUBROLES: (state, data) => {
			state.clubRoles = data
		},
		SET_ABLUMSTATE: (state, data) => {
			state.isFreshAblum = data
		},
		SET_NEWS_DETAIL: ( state ,data) => {
			state.newsDetail = data
		}
	},
	actions: {},
	plugins: [createPersistedState()],
	modules: {}
})
