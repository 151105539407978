<template>
  <div class="content-box">
    
    <div class="unedited" v-if="!showList" >
      <div class="top-card">
        <div class="card-title">
          <div class="title-left">黑名单</div>
          <div class="title-right">
            <div class="member-number">{{ this.blackList.length }}人</div>
            <div class="more-roles" @click.stop="openChoice">
              <el-image
              class="images" 
              :src="require('@/assets/more-roles.png')"
              fit="cover">
              </el-image>
            </div>
          </div>
        </div>
        <div class="sign-out" :class="showOut?'show-out':''">
          <div class="edit-information" @click.stop="addAdministrator()"><span class="out-text">移入黑名单</span></div>
          <div class="delete-image" @click.stop="removeAdministrator()"><span class="out-text">移出黑名单</span></div>
        </div>
      </div>
      <div class="roles-text">
        <div class="total-intro">被拉入黑名单的用户将被移出俱乐部，且不能再次关注和申请加入当前俱乐部。</div>
      </div>
      <div v-if="!isRemoveedit">   
        <div class="member-card" v-loading="loadingMember" v-if="this.blackList.length!=0">
          <div class="fans-box">
            <div class="fans-detail" v-for="(item, index) in blackList" :key="index">
              <div class="fans-image">
                <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ item.nickname }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>   
        <div class="member-card" v-loading="loadingMember" v-if="this.blackList.length!=0">
          <div class="fans-box">
            <div class="fans-detail" v-for="(item, index) in blackList" :key="index" @click="choiceremove(item)"> 
              <div class="fans-image">
                <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ item.nickname }}</div>
              <div class="nochoices" >
                <div class="content">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/unchoicePhoto.png')"
                    fit="cover">
                  </el-image>                  
                </div>
                <div class="contents" v-if="item.checked">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/choicePhoto.png')"
                    fit="cover">
                  </el-image>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div class="buttons-box" >
          <div class="cancel">
            <div class="text" @click="cancelRemove">取消</div>
          </div>
          <div class="unadd" v-if="!removebutton">
            <div class="text">移出</div>
          </div>
          <div class="add" v-else>
            <div class="text" @click="isRemoveAdmin">移出</div>
          </div>
        </div> 
      </div>     
    </div>
    
    <!-- 添加黑名单列表 -->
    <div class="edited" v-else> 
      <div class="top-card">
        <div class="card-title">
          <div class="title-left">名单</div>
          <div class="title-right">
            <div class="member-number">{{ clubRoles===3 ? allmember  : outadminNum}}人</div>
          </div>
        </div>
      </div>
      <div class="manage-card" v-loading="loadingMember" v-if="this.manageList.length!=0 && this.clubRoles!=2" >
        <div class="fans">管理员（{{ this.manageList.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in manageList" :key="index" @click="choicemanage(item)">
            <div class="fans-image">
                <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
            <div class="nochoices" >
              <div class="content">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/unchoicePhoto.png')"
                  fit="cover">
                </el-image>                  
              </div>
              <div class="contents" v-if="item.checked">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/choicePhoto.png')"
                  fit="cover">
                </el-image>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="group-box" v-if="this.groupMember.length!=0">
        <div class="group-card" v-loading="loadingMember"  v-for="(item, index) in groupMember" :key="index">
          <div class="fans">{{ item.groupName }}（{{ item.groupMember.length }}人）</div>
          <div class="fans-box">
            <div class="fans-detail" v-for="(items, indexs) in item.groupMember" :key="indexs" @click="choicegroup(items)">
              <div class="fans-image">
                <img-head :avatarUrl="items.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ items.nickname }}</div>
              <div class="nochoices" >
                <div class="content">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/unchoicePhoto.png')"
                    fit="cover">
                  </el-image>                  
                </div>
                <div class="contents" v-if="items.checked">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/choicePhoto.png')"
                    fit="cover">
                  </el-image>
                </div>
              </div> 
            </div>
          </div>
        </div>      
      </div>
      <div class="member-card" v-loading="loadingMember" v-if="this.memberList.length!=0">
        <div class="fans">成员（{{ this.memberList.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in memberList" :key="index" @click="choicenogroup(item)">
            <div class="fans-image">
                <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
            <div class="nochoices" >
              <div class="content">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/unchoicePhoto.png')"
                  fit="cover">
                </el-image>                  
              </div>
              <div class="contents" v-if="item.checked">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/choicePhoto.png')"
                  fit="cover">
                </el-image>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="fans-card" v-loading="loadingMember" v-if="this.fansObj.list.length!=0">
        <div class="fans">粉丝（{{ this.fansObj.list.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in fansObj.list" :key="index" @click="choicefans(item)">
            <div class="fans-image">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
            <div class="nochoices" >
              <div class="content">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/unchoicePhoto.png')"
                  fit="cover">
                </el-image>                  
              </div>
              <div class="contents" v-if="item.checked">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/choicePhoto.png')"
                  fit="cover">
                </el-image>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="button-box">
        <div class="cancel">
          <div class="text" @click="cancelDelect">取消</div>
        </div>
        <div class="unadd" v-if="!buttonDisable">
          <div class="text" >添加</div>
        </div>
        <div class="add" v-else>
          <div class="text" @click="confirmadministrator">移入</div>
        </div>
      </div>   
    </div>
    
    <!-- 提示弹窗 -->
    <tip-popup :title="this.titles" :isOpen="isOpen" :confirmColor="confirmColor" @close="closePopup" @confirm="confirm"></tip-popup>

  </div>
</template>

<script>
import jpg from "@/assets/default-avatar.png"
import tipPopup from '../../components/tip-popup.vue'
import imgHead from '../../components/img-head.vue'
import {eventBus} from '../../main.js'

export default {
  data() {
    return {
      clubRoles: 2,                                   // 俱乐部权限
      loadingMember: false,                           // 是否显示加载
      imgUrl: jpg,                                    // 加载不出的图片
      showOut: false,                                 // 是否打开权限窗口
      memberList: [],                                 // 俱乐部管理员  
      titles: '',                                     // 弹窗提示标题
      isOpen: false,                                  // 是否打开提示弹窗
      confirmColor: '#EC4747',                        // 弹窗按钮颜色
      showList: false,                                // 添加黑名单窗口   
      manageList: [],                                 // 俱乐部管理员      
      ownList: [],                                    // 俱乐部创建者
      groupMember: [],                                // 俱乐部分组信息
      allmember: 0,                                   // 俱乐部除粉丝以外人员总数
      outadminNum: 0,                                 // 除管理员粉丝外的总数
      blackList: [],                                  // 俱乐部黑名单        
      fansObj: {                                      // 俱乐部粉丝列表
        list: [],
        totalCount: 0,
        pageIndex: 1,
        nomore: false,
      },
      removebutton: false,                            // 是否显示移出黑名单按钮
      buttonDisable: false,                           // 是否显示名单列表按钮 
      isRemoveedit: false,                            // 是否进入移除黑名单功能
      stopFlag: false,
    }
  },
  components:{
    tipPopup,
    imgHead
  },

  async mounted(){
    eventBus.$on('closeEditMode', () => {
      if(this.showList) {
        this.cancelDelect()
      }
      if(this.isRemoveedit) {
        this.cancelRemove()
      }
    })
    await this.searchFans()
    await this.searchMember()
    await this.getClubBlacklist()
    this.clubRoles = this.$store.state.clubRoles.role
    document.addEventListener('click', this.HiddenClick,false)
  },

  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,false)
  },

  methods:{
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      if(this.showOut){
        this.showOut = false
      }
    },

    // 打开图片权限窗口
    openChoice() {
      this.showOut = !this.showOut
    },

    // 查询俱乐部创建者、管理员、成员
    async searchMember() {
      try {
        let data = {
          clubId: this.$route.params.clubId,
        }
        let res = await this.$request.post("/clubMemberInfo/selectMemberGroupList", data)
        if (res.code === 200) {         
          this.memberList = res.data.notGroupMember
          this.manageList = res.data.managers
          this.ownList = res.data.create
          this.groupMember = res.data.groupMember
          this.allmember = res.data.memberCount + this.fansObj.list.length - 1
          this.outadminNum = res.data.memberCount + this.fansObj.list.length - res.data.managers.length  - 1
        }
      } catch (e) {
				//
			}
    },

    // 查询俱乐部粉丝
    async searchFans() {
      this.loadingMember = true
      let data = {
        clubId: this.$route.params.clubId,
        pageIndex: this.fansObj.pageIndex,
        pageSize: 80,
      };
      try {
        let res = await this.$request.post("/clubFansInfo/selectAllFans", data);
        this.fansObj.list = res.page.list
        if(res.page.totalPage == res.page.pageIndex) {
          this.fansObj.nomore = true
        }
        else {
          this.fansObj.nomore = false
          this.fansObj.pageIndex++
        }
      } catch (e) {
        //TODO handle the exception
      }
      this.loadingMember = false
    },

    // 获取俱乐部黑名单
    async getClubBlacklist(){
      try {
        let res = await this.$request.post('/clubBlackList/selectBlackList', {clubId: this.$route.params.clubId})
        let data = res.data
        for(let i = 0; i < data.length; i++) {
          data[i].checked = false
        }
        this.blackList = data
      }catch(e){     
      //   
      }
    },

    // 移除黑名单
    async removeBlacklist() {
      let data = []
      for(let item of this.blackList){
        if(item.checked){
          let single = {
            clubId: this.$route.params.clubId,
            userId: item.userId,
            id: item.id
          }
          data.push(single)
        }
      }
      if(data.length == 0) return
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        await this.$request.post('/clubBlackList/removeBlackListBatch', data)
        this.getClubBlacklist()
        this.$message.success({
          showClose: true,
          message: "已移出黑名单",
        })
        this.isRemoveedit = false
    }catch(e){
        //
      }
      loading.close()
    },


    // 移入黑名单
    async movetoBlacklist() {
      let data = []
      for(let items of this.manageList){
        if(items.checked){
          let single = {
            clubId: this.$route.params.clubId,
            userId: items.userId,
            id: items.id
          }
          data.push(single)
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            let single = {
              clubId: this.$route.params.clubId,
              userId: items.userId,
              id: items.id
            }
            data.push(single)
          }   
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          let single = {
            clubId: this.$route.params.clubId,
            userId: items.userId,
            id: items.id
          }
          data.push(single)
        }
      }
      for(let items of this.fansObj.list){
        if(items.checked){
          let single = {
            clubId: this.$route.params.clubId,
            userId: items.userId,
            id: items.id
          }
          data.push(single)
        }
      }
      if(data.length == 0) return
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        await this.$request.post('/clubBlackList/addBlackListBatch', data)
        this.$message.success({
          showClose: true,
          message: "已移入黑名单",
        })
        this.getClubBlacklist()
        this.showList = false
      }catch(e){
        //
      }
      loading.close()
    },

    // 添加黑名单功能
    async addAdministrator() {
      if(this.manageList.length==0 && this.groupMember.length==0 && this.memberList.length==0 && this.fansObj.list.length==0){
        this.$message.waring({
          showClose: true,
          message: "无法做此操作",
        })
        return        
      }
      this.searchFans()
      this.searchMember()
      this.showList = true
      this.showOut = false
    },

    // 移出黑名单功能
    removeAdministrator() {
      if(this.blackList.length==0){
        this.$message.warning({
          showClose: true,
          message: "无法做此操作",
        })
        return
      }
      this.isRemoveedit = true
      this.showOut = false
    },

    // 取消移入黑名单模式
    cancelRemove() {
      this.isRemoveedit = false
      for(let items of this.blackList){
        if(items.checked){
          items.checked = false
        }
      }
      this.removebutton = false
    },

    // 选中移除黑名单
    choiceremove(item) {
      item.checked = !item.checked
      for(let items of this.blackList){
        if(items.checked){
          this.removebutton = true
          return
        }else{
          this.removebutton = false
        }
      }
    },

    // 取消添加黑名单
    cancelDelect() {
      this.showList = false
    },

    // 名单-判断是否已有选中
    judgmentChoice() {
      for(let items of this.manageList){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            this.buttonDisable = true
            return 
          }else{
            this.buttonDisable = false
          }    
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      }
      for(let items of this.fansObj.list){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      } 
    },

    // 名单-多选管理员
    choicemanage(item) {
      item.checked = !item.checked
      this.judgmentChoice()
    },

    // 名单-多选group
    choicegroup(items) {
      items.checked = !items.checked
      this.judgmentChoice()
    },

    // 名单-多选nogroup
    choicenogroup(item) {
      item.checked = !item.checked
      this.judgmentChoice()
    },

    // 名单-多选粉丝
    choicefans(item) {
      item.checked = !item.checked
      this.judgmentChoice()
    },

    // 调起确认添加黑名单弹窗
    isRemoveAdmin() {
			this.isOpen = true
      this.titles = "确定要移出黑名单吗？" 
      this.confirmColor = "#1465CF" 
    },

    // 弹窗询问添加黑名单
    confirmadministrator() {
			this.isOpen = true
      this.titles = "确定要移入黑名单吗？" 
    },  

    // 关闭删除动态弹窗
		closePopup() {
      this.isOpen = false
      this.showOut = false
    },

    // 确认回调事件
    confirm() {
      if(this.titles=="确定要移出黑名单吗？"){
        this.removeBlacklist()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
      if(this.titles=="确定要移入黑名单吗？"){
        this.movetoBlacklist()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .content-box{
    width: 790px;
    min-height: 651px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    .unedited{
      .top-card{
        // border-bottom: 1px solid #dddddd;
        position: relative;
        margin-bottom: 20px;
        .card-title{
          display: flex;
          justify-content: space-between;
          .title-left {
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: left;
            color: #000000;
            line-height: 25px;
          }
          .title-right {
            display: flex;
            align-items: center;
            .member-number{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              margin-bottom: 5px;
              margin-right: 20px;
            }
            .more-roles{
              cursor: pointer;
              .images{
                width: 18px;
                height: 18px;
              }
            }
          }
        }
        .sign-out{
          cursor: pointer;
          width: 120px;
          height: 0;
          background: #fff;
          border-radius: 5px;
          position:absolute;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
          right: -52px;
          z-index: 99;
          //修改页面抖动
          top: 28px;
          box-sizing: border-box;
          transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .edit-information{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
              // color:#888888
            }
          }
          .edit-information:hover{
            color: #1465CF !important;
            // background-color: black;
          }
          .delete-image{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
              // color:#888888;
            }
          }
          // .top-out:hover .top-name{
          // 	font-weight: bold;
          // }
          .delete-image:hover{
            color: #1465CF !important;
          }
          .delete-album{
            color:#444444;
            .out-text{
              font-size: 14px;
              // color:#888888;
            }
          }
          // .top-out:hover .top-name{
          // 	font-weight: bold;
          // }
          .delete-album:hover{
            color: #1465CF !important;
          }
        }
        .show-out{
        height: 70px;
        // border: 1px solid #dddddd;
        box-shadow: 0px 2px 10px 0px undefined; 
        }
      }
      .roles-text{
        // border-bottom: 1px solid #dddddd;
        padding-bottom: 1px;
        position: relative;
        .total-intro{
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 22px;
          margin-bottom: 20px;     
        }
      }
      .roles-text:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #dddddd;
        transform: scaleY(0.5);
        transform-origin: center top;
        z-index: 2;
      }
      .member-card{
        margin-top: 20px;
        // border-bottom: 1px solid #dddddd;      
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          flex-wrap: wrap;
          cursor: pointer;
          .fans-detail{
            display: flex;
            flex-direction: column;
            position: relative;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            .fans-image{
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;         
            }
            .nochoices{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 99;
              // background: #fff;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;              
                // opacity: 0;
                // background: #fff;
                // border: 2px solid #fff;
                // border-radius: 50%;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18  px;              
                // opacity: 0;
                // background: #1465CF ;
                border-radius: 50%;
                z-index: 99;              
              }
            }
          }
        }

      }
      .buttons-box{
        position: absolute;
        bottom: 0;
        left: 310px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .cancel{
            width: 100px;
            height: 30px;
            background: #F7F8FA;
            border-radius: 5px;  
            // margin: 230px auto 10px;
            margin-right: 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #444444;
              line-height: 20px;
              padding: 5px 35px;
            }
          }  
        .add{
            width: 100px;
            height: 30px;
            background: #1465CF;
            border-radius: 5px;  
            // margin: 230px auto 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
          }
        .unadd{
            width: 100px;
            height: 30px;
            background: #A8A8A8;
            border-radius: 5px;  
            // margin: 230px auto 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
        }
      }  
    }
    .edited{
      // position: relative;
      .top-card{
        position: relative;
        .card-title{
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .title-left {
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: left;
            color: #000000;
            line-height: 25px;
          }
          .title-right {
            display: flex;
            align-items: center;
            .member-number{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              margin-bottom: 5px;
            }
            .more-roles{
              cursor: pointer;
              .images{
                width: 18px;
                height: 18px;
              }
            }
          }
        }
        .sign-out{
          cursor: pointer;
          width: 120px;
          height: 0;
          background: #fff;
          border-radius: 5px;
          position:absolute;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
          right: -52px;
          z-index: 99;
          //修改页面抖动
          top: 28px;
          box-sizing: border-box;
          transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .edit-information{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
              // color:#888888
            }
          }
          .edit-information:hover{
            color: #1465CF !important;
            // background-color: black;
          }
          .delete-image{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
              // color:#888888;
            }
          }
          // .top-out:hover .top-name{
          // 	font-weight: bold;
          // }
          .delete-image:hover{
            color: #1465CF !important;
          }
          .delete-album{
            color:#444444;
            .out-text{
              font-size: 14px;
              // color:#888888;
            }
          }
          // .top-out:hover .top-name{
          // 	font-weight: bold;
          // }
          .delete-album:hover{
            color: #1465CF !important;
          }
        }
        .show-out{
        height: 70px;
        // border: 1px solid #dddddd;
        box-shadow: 0px 2px 10px 0px undefined; 
        }
      }
      .manage-card{
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #dddddd;      
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          flex-wrap: wrap;
          .fans-detail{
            cursor: pointer;
            display: flex;
            flex-direction: column;
            position: relative;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            .fans-image{
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
              margin-left: -5px;         
            }
            .nochoices{
                position: absolute;
                left: 17px;
                top: 40px;
                width: 20px;
                height: 20px;
                z-index: 99;
                // background: #fff;
                border-radius: 50%;
                cursor: pointer;
                .content{
                  margin-top: 2.5px;
                  margin-left: 29px;
                  width: 18px;
                  height: 18px;              
                  // opacity: 0;
                  // background: #fff;
                  // border: 2px solid #fff;
                  // border-radius: 50%;
                  z-index: 99;
                }
                .contents{
                  margin-top: -19px;
                  margin-left: 28px;
                  width: 18px;
                  height: 18  px;              
                  // opacity: 0;
                  // background: #1465CF ;
                  border-radius: 50%;
                  z-index: 99;              
                }
              }
          }
        }
      }
      .group-box {
        .group-card{
          padding-top: 10px;
          border-top: 1px solid #dddddd;      
          .fans{
            margin-bottom: 20px;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            color: #444444;
            line-height: 22px;
          }
          .fans-box{
            display: flex;
            flex-wrap: wrap;
            .fans-detail{
              cursor: pointer;
              display: flex;
              flex-direction: column;
              position: relative;
              height: 85px;
              width: 60px;
              margin-right: 20px;
              margin-bottom: 20px;
              .fans-image{
                .images{
                  border-radius: 50%;
                  width: 60px;
                  height: 60px;
                }
              }
              .fans-name{
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: bold;
                text-align: center;
                color: #444444;
                line-height: 20px;
                width: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
                margin-left: -5px;         
              }
              .nochoices{
                position: absolute;
                left: 17px;
                top: 40px;
                width: 20px;
                height: 20px;
                z-index: 99;
                // background: #fff;
                border-radius: 50%;
                cursor: pointer;
                .content{
                  margin-top: 2.5px;
                  margin-left: 29px;
                  width: 18px;
                  height: 18px;              
                  // opacity: 0;
                  // background: #fff;
                  // border: 2px solid #fff;
                  // border-radius: 50%;
                  z-index: 99;
                }
                .contents{
                  margin-top: -19px;
                  margin-left: 28px;
                  width: 18px;
                  height: 18  px;              
                  // opacity: 0;
                  // background: #1465CF ;
                  border-radius: 50%;
                  z-index: 99;              
                }
              }
            }
          }
        }      
      }
      .member-card{
        padding-top: 10px;
        border-top: 1px solid #dddddd;         
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          flex-wrap: wrap;
          .fans-detail{
            cursor: pointer;
            display: flex;
            flex-direction: column;
            position: relative;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            .fans-image{
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
              margin-left: -5px;         
            }
            .nochoices{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 99;
              // background: #fff;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;              
                // opacity: 0;
                // background: #fff;
                // border: 2px solid #fff;
                // border-radius: 50%;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18  px;              
                // opacity: 0;
                // background: #1465CF ;
                border-radius: 50%;
                z-index: 99;              
              }
            }
          }
        }
      }
      .fans-card{
        padding-top: 10px;
        border-top: 1px solid #dddddd;        
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          flex-wrap: wrap;
          .fans-detail{
            cursor: pointer;
            display: flex;
            flex-direction: column;
            position: relative;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            .fans-image{
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
              margin-left: -5px;         
            }
            .nochoices{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 99;
              // background: #fff;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;              
                // opacity: 0;
                // background: #fff;
                // border: 2px solid #fff;
                // border-radius: 50%;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18  px;              
                // opacity: 0;
                // background: #1465CF ;
                border-radius: 50%;
                z-index: 99;              
              }
            }
          }
        }
      }
      .button-box{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        position: absolute;
        bottom: 0;
        left: 310px;
        .cancel{
            width: 100px;
            height: 30px;
            background: #F7F8FA;
            border-radius: 5px;  
            // margin: 230px auto 10px;
            margin-right: 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #444444;
              line-height: 20px;
              padding: 5px 35px;
            }
          }  
        .add{
            width: 100px;
            height: 30px;
            background: #EC4747;
            border-radius: 5px;  
            // margin: 230px auto 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
          }
        .unadd{
            width: 100px;
            height: 30px;
            background: #A8A8A8;
            border-radius: 5px;  
            // margin: 230px auto 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
          }
      }  
    }
  }
</style> 