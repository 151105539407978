<template>
  <div class="content-box">
    <div class="login" v-show="show" :class="show ? 'show' : ''" :style="[zoomStyle]">
      <div class="login-wrap">
        <div class="title-line">
          <h1 class="login-text">登录</h1>
          <icon-font class="close" name="close" color="#999999" size="16px" @click="close"></icon-font>
        </div>
        <div class="login-area">
          <div class="login-number">
            <div class="q-code">+86</div>
            <input type="text" v-model="phone" class="phone-number" style="
            font-size: 14px;color: #444444;
            line-height: 20px;" placeholder="请输入手机号"/>
          </div>
          <div class="login-code">
            <input type="text" v-model="verification" class="code" placeholder="获取验证码" style="
            font-size: 14px;color: #444444;
            line-height: 20px;"  @keyup.enter.prevent="submitForm" />
            <div class="get-code" @click="getVerification">
              {{ verificationText }}
            </div>
          </div>
        </div>
        <div class="submit-area">
          <button class="submit" type="button" @click="submitForm">登录</button>
        </div>
      </div>
    </div>
    <choice-club :rescreening="rescreening"  :show="showLogin" @close="closeLogin"></choice-club>
  </div>
</template>

<script>
import iconFont from "./IconFont.vue";
import choiceClub from "./choiceClub.vue";
export default {
  data() {
    return {
			showLogin: false,
      phone: "",
      verification: "",
      btnDisabled: false,
      verificationText: "获取验证码",
      zoomStyle: {
        transform: "",
      },
      scale: "scale(1.2, 1.2)",
      rescreening: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    openClub: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    iconFont,
    choiceClub
  },
  mounted(){
    this.$watch('openClub',res=>{
      if(res===true){
        this.rescreening = true
        this.showLogin = true
      }
    })
  },
  watch: {
    show(n) {
      if (n) {
        // 当展示遮罩的时候，设置scale为1，达到缩小(原来为1.2)的效果
        this.zoomStyle.transform = "scale(1, 1)";
      } else if (!n) {
        // 当隐藏遮罩的时候，设置scale为1.2，达到放大(因为显示遮罩时已重置为1)的效果
        this.zoomStyle.transform = this.scale;
      }
    },
  },
  computed: {
    maskStyle() {
      let style = {};
      style.backgroundColor = "rgba(0, 0, 0, 0.6)";
      if (this.show) style.zIndex = 99;
      else style.zIndex = -1;
      style.transition = `all ${300 / 1000}s ease-in-out`;
      return style;
    },
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit("close");
    },

    //获取验证码
    async getVerification() {
      if (this.btnDisabled) {
        return;
      } else {
        this.btnDisabled = true;
      }
      if (!this.phone) {
        this.$message.error("手机号不能为空");
        this.btnDisabled = false;
        return;
      } else if (!/^1[23456789]\d{9}$/.test(this.phone)) {
        this.$message.error("请输入正确的手机号码");
        this.btnDisabled = false;
        return;
      }
      try {
        await this.$login.post("/web/phoneNumberLogin/sendCode", {
					phoneNumber: this.phone,
        });
        this.$message({
          message: "验证码已发送，请注意手机短信",
          type: "success",
        });
        let time = 60;
        this.verificationText = `获取验证码(${time})`;
        let timer = setInterval(() => {
          time--;
          this.verificationText = `获取验证码(${time})`;
          if (time == 0) {
            clearInterval(timer);
            this.btnDisabled = false;
            this.verificationText = "获取验证码";
          }
        }, 1000);
      } catch (e) {
        this.btnDisabled = false;
        this.$message({
          message: e.msg,
          type: "warning",
        });
      }
    },

    //提交数据
    async submitForm() {
      if (!this.phone) {
        this.$message.error("手机号不能为空")
        return
      } else if (!/^1[23456789]\d{9}$/.test(this.phone)) {
        this.$message.error("请输入正确的手机号码")
        return
      } else if (!this.verification) {
        this.$message.error("请输入手机验证码")
				return
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      try {
        let res = await this.$login.post("/web/phoneNumberLogin/login", {
          phoneNumber: this.phone,
          code: this.verification,
        })
        if(res.code === 200){
          this.$store.commit("SET_TOKEN", res.data.token)
          await this.getUserInfo()

          navigator.sayswho= (function(){
              var ua= navigator.userAgent, tem, 
              M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
              if(/trident/i.test(M[1])){
                  tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                  return 'IE '+(tem[1] || '');
              }
              if(M[1]=== 'Chrome'){
                  tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                  if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
              }
              M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
              if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
              return M.join(' ');
          })();

          let data = {
            deviceModel: navigator.sayswho,
            osVersion: navigator.appVersion,
            terminalType: 3
          }
          // 记录设备信息
          await this.$request.post("/userInfo/initUseDeviceInfoOther",data)
          this.$router.push({ name: 'ClubRecommendation'})
          this.$emit('close')
          }
          if(res.code === 500){
            this.$message.error("你没有俱乐部管理权限")
            return
          }				
        } catch (err) {
          this.$store.commit('CLEAR_STORAGE')
        }
        loading.close()
    },

    // 获取用户信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        try {
          this.$request.post("/userInfo/personalInfo").then((res) => {
            this.currentUserId = res.data.id
            this.$store.commit("SET_USER", res.data)
            return resolve()
          })
        } catch (error) {
          return reject()
        }
      })
    },

    closeLogin(){
			this.showLogin = false
			this.current = 0
			this.$emit("close")
		},
	
		openLogin() {
			this.showLogin = true
    },    
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: transform 0.3s;
  z-index: 999;
  .login-wrap {
    width: 480px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 17px;
    box-sizing: border-box;
  }
  .title-line {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .login-text{
      width: 36px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      line-height: 25px;
      padding-top: 3px;
    }
    .close {
      position: absolute;
      top: 2px;
      right: 2px;
      cursor: pointer;
    }
  }
  .login-area {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    .login-number {
      width: 380px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #999;
      border-radius: 21px;
      margin-bottom: 15px;
      padding: 0 12px;
      box-sizing: border-box;
      .q-code {
        padding-right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #999;
        color: #444444;
        font-size: 15px;
      }
      .phone-number {
        flex: 1;
        margin-left: 10px;
        font-size: 15px;
      }
    }
    .login-code {
      width: 380px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #999;
      border-radius: 21px;
      margin-bottom: 25px;
      padding: 0 12px;
      box-sizing: border-box;
      .code {
        flex: 1;
        font-size: 15px;
      }
      .get-code {
        color: #005faf;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
  .submit-area {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    .submit {
      width: 200px;
      height: 40px;
      background: #1465CF;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 20px;
      transition: all 0.2s;
    }
    button:active {
      opacity: 0.5;
    }
  }
}

.show {
  opacity: 1;
}

.zoom {
  transform: scale(1.2, 1.2);
}

input::-webkit-input-placeholder{  // 针对 谷歌 内核
  color: #A8A8A8;
}
input:-moz-placeholder{   // 火狐
  color: #A8A8A8;

}
input:-ms-input-placeholder{  // 微软ie
  color: #A8A8A8;
}
</style>