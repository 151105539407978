/**
 * 自定义获取上传文件凭证类
 * @param {*} type                Number     请求类型 -- 1.用户头像；2.发布动态；3.发起活动；4.新建俱乐部头像；5.俱乐部相册；6.活动群组头像；7.俱乐部群组头像；8.动态评论；9.活动评论；10.吐槽（不同的请求类型需要给不同的主键）
 * @param {*} clubId              Number     请求俱乐部主键
 * @param {*} newsId              Number     请求动态主键
 * @param {*} activityId     	  Number     请求活动主键
 * @param {*} activityGroupId     Number     活动群组主键
 * @param {*} clubGroupId         Number     俱乐部群组主键
 */
import $request from './request.js'
import store from '../store/index.js'

const credential = (type, clubId = 0, newsId = 0, activityId = 0) => {
	return new Promise((resolve, reject) => {
		$request.get(`/upload/credential?type=${type}&clubId=${clubId}&newsId=${newsId}&activityId=${activityId}`).then((res)=>{
			// console.log('获取临时密钥--res', res)
			let credentialData = res.data
			let signData = {
				TmpSecretId: credentialData.credentials.tmpSecretId,
				TmpSecretKey: credentialData.credentials.tmpSecretKey,
				XCosSecurityToken: credentialData.credentials.sessionToken,
				StartTime: credentialData.startTime,
				ExpiredTime: credentialData.expiredTime
			}
			// 将签名和临时密钥存入vuex中
			store.commit('SET_CREDENTIAL', credentialData)
			store.commit('SET_SIGN', signData)
			
			return resolve()
			
		}).catch((err)=>{
			// console.log('err', err)
			return reject(err)
		})
	})
}

export default credential