<template>
	<div class="main">
		<!-- 顶部导航 -->
		<div class="nav-top">
			<!-- logo -->
			<div class="logo" @click="clickLogo">
				<div class="logo-box">
					<img class="logo-img" src="../assets/logo.png" fit="cover" alt="imlatte" />
				</div>
				<div class="name">
					<span class="en-name">Latte</span>
				</div>
			</div>
		</div>
		<div class="agreement infinite-list-wrapper" style="overflow:auto">
			<div class="my-style">
				<p class="top-tlite"><span>隐私政策</span></p>
				<p class="box-text">
					<span >深圳市毕宿五网络科技有限公司</span>
					<span >（以下简称“</span>
					<span >公司</span>
					<span >”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，</span>
					<span >公司</span>
					<span >
						致力于恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时
					</span>
					<span >公司</span>
					<span >承诺，</span>
					<span >公司</span>
					<span >将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，</span>
					<span >公司</span>
					<span >制定本《隐私政策》（下称“本政策 /本隐私政策”）并提醒您：</span>
				</p>
				<p class="box-text">
					<span >1)</span>
					
					<span >本政策适用于</span>
					<span >公司旗下的所有</span>
					<span >产品或服务。</span>
				</p>
				<p class="box-text">
					<span >2)</span>
					
					<span >
						需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于已另行独立设置隐私权政策的产品或服务。
					</span>
				</p>
				<p class="box-text">
					<span >在使用</span>
					<span >公司</span>
					<span >
						各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用
					</span>
					<span >公司</span>
					<span >各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过</span>
					<span >公司</span>
					<span >提供的各种联系方式与</span>
					<span >公司</span>
					<span >联系。</span>
				</p>
				<p class="title">
					<span style="font-family:宋体-简; font-size:12pt; font-weight:bold">一、</span>
					<span style="font-family:宋体-简; font-size:12pt; font-weight:bold">公司</span>
					<span style="font-family:宋体-简; font-size:12pt; font-weight:bold">如何收集和使用您的个人信息</span>
				</p>
				<p class="box-text">
					<span >
						个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括但不限于姓名、性别、出生日期、个人生物识别信息、住址、图片、通信联系方式、账号密码（含第三方产品）、财产信息、行踪轨迹等。
					</span>
				</p>
				<p class="box-text">
					<span >
						个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，较易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括但不限于身份证件号码、图片、个人生物识别信息、行踪轨迹等。
					</span>
					<!-- <span >公司</span>
					<span >将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。</span> -->
				</p>
				<!-- <p class="box-text">
					<span >个人信息以及个人敏感信息包含的内容均出自于GB/T35273《个人信息安全规范》。</span>
				</p> -->
				<p class="box-text"><span >您理解并同意：</span></p>
				<p class="box-text">
					<span >1</span>
					
					<span >公司</span>
					<span >致力于打造多样的产品和服务以满足您的需求。因</span>
					<span >公司</span>
					<span >
						向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；
					</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >公司</span>
					<span >可能将通过某些功能所收集的信息用于</span>
					<span >公司</span>
					<span >的其他服务。例如，</span>
					<span >公司</span>
					<span >可能将你在使用</span>
					<span >公司</span>
					<span >某一功能或服务时</span>
					<span >公司</span>
					<span >
						收集的信息，在另一功能或服务中用于向你提供特定内容，包括展示广告、对你阅读过的内容进行信息安全类提示、基于特征标签进行间接人群画像并提供更加精准和个性化的服务和内容等。如
					</span>
					<span >公司</span>
					<span >使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，</span>
					<span >公司</span>
					<span >将在使用您的个人信息前，再次向您告知并征得您的明示同意。</span>
				</p>
				<p class="box-text">
					<span >3</span>
					
					<span >为给您带来更好的产品和服务体验，</span>
					<span >公司</span>
					<span >在持续努力改进</span>
					<span >公司</span>
					<span >的技术，随之</span>
					<span >公司</span>
					<span >可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，</span>
					<span >公司</span>
					<span >
						将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过
					</span>
					<span >公司</span>
					<span >提供的各种联系方式与</span>
					<span >公司</span>
					<span >联系，</span>
					<span >公司</span>
					<span >会尽快为您作出解答。</span>
				</p>
				<p class="box-text">
					<span >公司</span>
					<span >
						所提供产品或服务的功能分别收集的个人信息类型，以及收集、使用个人信息的规则（例如收集和使用个人信息的目的、方式）如下：
					</span>
				</p>
				<p class="box-text">
					<span >1</span>
					
					<span >当您注册</span>
					<span >公司</span>
					<span >服务时，</span>
					<span >公司</span>
					<span >会收集您的昵称、头像、性别、出生年月日、手机号码，拟使用的用户名和密码用于创建</span>
					<span >公司</span>
					<span >账户。如您同意和授权</span>
					<span >公司</span>
					<span >将您的账户信息</span>
					<span >&#xa0;</span>
					<span >（头像、昵称及其他页面提示的信息）授权给第三方以使您可以便捷地实现第三方账户的注册或登录的，</span>
					<span >公司</span>
					<span >会将您同意和授权的账户信息共享给第三方。此外，</span>
					<span >&#xa0;</span>
					<span >公司</span>
					<span >
						可能会根据您的授权从第三方处获取您在第三方平台的注册账号相关信息（头像、昵称以及您授权的其他信息），并与您的
					</span>
					<span >公司</span>
					<span >账户进行绑定，使您可通过第三方账户直接登录、使用</span>
					<span >公司</span>
					<span >的产品及/或服务。</span>
					<span >公司</span>
					<span >将在您授权同意的范围内使用您的相关信息。</span>
					<span >&#xa0;</span>
					<span >
						在注册与使用服务过程中，如果您提供以下信息补全个人资料：生日、家乡、星座、职业、学校、月收入、身高、您本人的真实头像、个人签名、地点足迹，将有助于
					</span>
					<span >公司</span>
					<span >给您提供更好的服务和体验，</span>
					<span >公司</span>
					<span >
						可以为您提供与以上信息相关的个性化服务。但如果您不提供这些信息，将会仅使您无法使用或参与与此相关的个性化服务，不会影响使用
					</span>
					<span >公司</span>
					<span >产品或服务的基本功能。您提供的上述信息将在您使用本服务期间持续授权</span>
					<span >公司</span>
					<span >使用。在您主动注销账号时，</span>
					<span >公司</span>
					<span >将根据适用法律法规的要求尽快使其匿名或删除您的个人信息。</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >当您注册并开始使用</span>
					<span >公司</span>
					<span >各项服务时，为保障您正常使用</span>
					<span >公司</span>
					<span >的服务，维护</span>
					<span >公司</span>
					<span >服务的正常运行，改进及优化</span>
					<span >公司</span>
					<span >的服务体验以及保障您的帐号安全，</span>
					<span >公司</span>
					<span >会收集关于您</span>
					<span >使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：设备信息：</span>
					<span >公司</span>
					<span >
						会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设置参数、设备配置、设备标识、设备网络信息、设备环境、软件列表等软硬件特征信息）。服务日志信息：当您使用
					</span>
					<span >公司</span>
					<span >的网站或客户端提供的产品或服务时，</span>
					<span >公司</span>
					<span >会自动收集您对</span>
					<span >公司</span>
					<span >
						服务的详细使用情况，作为有关服务日志保存。包括您的浏览、点击查看和搜索查询内容、关注分享信息、发布信息，以及IP地址、浏览器的类型、运营商信息、使用的语言、访问日期和时间。
					</span>
					<span >&#xa0;</span>
					<span >请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果</span>
					<span >公司</span>
					<span >
						将这类信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类信息将被视为个人信息，除取得您授权或法律法规另有规定外，
					</span>
					<span >公司</span>
					<span >会将该类个人信息做匿名化、去标识化处理。为向您提供更便捷、更符合您个性化需求的信息展示及推送服务，</span>
					<span >公司</span>
					<span >
						会根据您的设备信息和服务日志信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和可能的商业广告,了解个性化广告设置。
					</span>
				</p>
				<p class="box-text">
					<span >3</span>
					
					<span >
						当您使用动态、聊天室及其他信息发布功能公开发布信息，包括发布图文/视频内容、发表评价及问答内容时，您需要相应的开启相册、相机、麦克风权限来拍摄照片或视频或语音交互。请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发布动态时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用
					</span>
					<span >公司</span>
					<span >的服务时共享甚至公开分享相关信息。您上传的动态照片、短视频、评论、点赞等信息会存储在</span>
					<span >公司</span>
					<span >的服务器中，因为存储是实现这一功能所必需的。</span>
					<span >公司</span>
					<span >会以加密的方式存储，您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求，</span>
					<span >公司</span>
					<span >不会对外提供上述动态信息，或者将其用于该功能以外的其他用途。</span>
				</p>
				<p class="box-text">
					<span >4</span>
					
					<span >当您浏览查看附近的人、附近群组、附近动态、附近</span>
					<span >活动</span>
					<span >时，</span>
					<span >公司</span>
					<span >
						会在获得您的自主选择同意开启位置权限后，记录您的地理位置信息。该信息属于敏感信息，拒绝提供该信息会使您无法使用上述功能，但不影响您正常使用
					</span>
					<span >公司</span>
					<span >的其他功能。此外，您也可以随时关闭相关功能。如果您不是</span>
					<span >公司</span>
					<span >的注册用户但已经开启了位置权限，确认授权</span>
					<span >公司</span>
					<span >可以提取、公开及使用您的</span>
					<span >&#xa0;</span>
					<span >地理位置信息后，您可以浏览附近的人和附近的动态。</span>
				</p>
				<p class="box-text">
					<span >5</span>
					
					<span >当您选择与您感兴趣的人建立关注关系、通过</span>
					<span >公司</span>
					<span >提供的功能组件向其他第三方分享信息时，</span>
					<span >公司</span>
					<span >会收集包括您的关注关系、分享历史在内的服务日志信息用于实现上述功能及其他</span>
					<span >公司</span>
					<span >明确告知的目的。</span>
				</p>
				<p class="box-text">
					<span >6</span>
					
					<span >当您与</span>
					<span >公司</span>
					<span >联系或提出争议纠纷处理申请时，为了保障您的账户及系统安全，</span>
					<span >公司</span>
					<span >需要您提供必要的个人信息以核验您的用户身份。</span>
					<span >公司</span>
					<span >
						可能会保存您的通信/通话记录和内容（包括账号信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信
					</span>
					<span >
						息），以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。为了提供服务及改进服务质量的合理需要，
					</span>
					<span >公司</span>
					<span >还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向</span>
					<span >公司</span>
					<span >发送的问卷答复信息。</span>
				</p>
				<p class="box-text">
					<span >7</span>
					
					<span >提高您使用</span>
					<span >公司</span>
					<span >及</span>
					<span >公司</span>
					<span >关联</span>
					<span >公司</span>
					<span >、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或公司相关协议规则的情况，</span>
					<span >公司</span>
					<span >可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及公司关联公司、合作伙伴取得您授权或依据法律共享给</span>

					<span >公司</span>
					<span>的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span>
	
				</p>
				<!-- <p class="box-text">
					<span >8</span>
					
					<span >1为提高您使用</span>
					<span >公司</span>
					<span >及</span>
					<span >公司</span>
					<span >
						关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或
					</span>
					<span >公司</span>
					<span >相关协议规则的情况，</span>
					<span >公司</span>
					<span >可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及</span>
					<span >公司</span>
					<span >关联公司、合作伙伴取得您授权或依据法律共享给</span>
					<span >公司</span>
					<span >
						的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
					</span>
				</p> -->
				<p class="box-text">
					<span >8</span>
					
					<span >您可通过</span>
					<span >公司</span>
					<span >账号在</span>
					<span >公司</span>
					<span >提供的链接入口使用</span>
					<span >公司</span>
					<span >关联公司提供的产品或服务，为便于</span>
					<span >公司</span>
					<span >基于关联账号共同向您提供一站式服务并便于您统一进行管理，</span>
					<span >公司</span>
					<span >在</span>
					<span >公司</span>
					<span >集中展示您的信息或推荐您感兴趣的信息。</span>
				</p>
				<p class="box-text">
					<span >9</span>
					<span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
					<span >您理解并同意，</span>
					<span >公司</span>
					<span >
						提供的服务可能需要您在您的设备中开启您的位置信息
						（地理位置）、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录的访问权限，以实现这些权限所涉及信息的收集和使用。
					</span>
					<span >公司</span>
					<span >
						会在您使用相关服务时弹窗提示您是否要开启相应权限。您可自行决定这些权限随时的开启或关闭。
					</span>
					<span >&#xa0;</span>
					<span >请您注意，您开启任一权限即代表您授权</span>
					<span >公司</span>
					<span >可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，</span>
					<span >公司</span>
					<span >
						将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
					</span>
				</p>
				<p class="box-text">
					<span >10</span>
					<span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
					<span >若你提供的信息中含有其他用户的个人信息，在向</span>
					<span >公司</span>
					<span >提供这些个人信息之前，您需确保您已经取得合法的授权。</span>
				</p>
				<p class="box-text">
					<span >11</span>
					<span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
					<span >若</span>
					<span >公司</span>
					<span >将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者</span>
					<span >公司</span>
					<span >主动从第三方处获取您的个人信息，均会事先获得您的同意。</span>
				</p>
				<p class="box-text">
					<span >12</span>
					<span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
					<span >若</span>
					<span >公司</span>
					<span >从第三方处间接获取您的信息的，</span>
					<span >公司</span>
					<span >
						会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给
					</span>
					<span >公司</span>
					<span >使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，</span>
					<span >公司</span>
					<span >会明确要求对方承担相应法律责任；同时，</span>
					<span >公司</span>
					<span >
						的专业安全团队对个人信息会进行安全防护（包括敏感信息分级分类、敏感信息使用的访问控制、敏感信息加密存储等）。
					</span>
					<span >公司</span>
					<span >会使用不低于</span>
					<span >公司</span>
					<span >对自身</span>
					<span >用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。</span>
				</p>
				<p class="title">
					<span >二、</span>
					<span style="font-family:宋体-简; font-size:12pt; font-weight:bold">征得授权同意的例外</span>
				</p>
				<p class="box-text">
					<span >您充分理解并同意，</span>
					<span >公司</span>
					<span >在以下情况下收集、使用您的个人信息无需您的授权同意，且</span>
					<span >公司</span>
					<span >可能不会同意您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：</span>
				</p>
				<p class="box-text">
					<span >1</span>
					
					<span >与</span>
					<span >公司</span>
					<span >履行法律法规规定的义务相关的；</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >与国家安全、国防安全有关的；</span>
				</p>
				<p class="box-text">
					<span >3</span>
					
					<span >与公共安全、公共卫生、重大公共利益有关的；</span>
				</p>
				<p class="box-text">
					<span >4</span>
					
					<span >与犯罪侦查、起诉、审判和判决执行等有关的；</span>
				</p>
				<p class="box-text">
					<span >5</span>
					
					<span >出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span>
				</p>
				<p class="box-text">
					<span >6</span>
					
					<span >所收集的个人信息是您自行向社会公众公开的；</span>
				</p>
				<p class="box-text">
					<span >7</span>
					
					<span >从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
				</p>
				<p class="box-text">
					<span >8</span>
					
					<span >根据您的要求签订合同所必需的；</span>
				</p>
				<p class="box-text">
					<span >9</span>
					
					<span >用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span>
				</p>
				<p class="box-text">
					<span >10</span>
					<span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
					<span >为合法的新闻报道所必需的；</span>
				</p>
				<p class="box-text">
					<span >11</span>
					<span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
					<span >
						学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
					</span>
				</p>
				<p class="box-text">
					<span >12</span>
					<span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
					<span >法律法规规定的其他情形。</span>
				</p>
				<p class="box-text">
					<span >请知悉，根据适用的法律，若</span>
					<span >公司</span>
					<span >对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或</span>
					<span >公司</span>
					<span >可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善</span>
					<span >公司</span>
					<span >的内容和布局，为商业决策提供产品或服务支撑，以及改进</span>
					<span >公司</span>
					<span >
						的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
					</span>
				</p>
				<p class="box-text">
					<span >如</span>
					<span >公司</span>
					<span >停止运营</span>
					<span >公司</span>
					<span >产品或服务，</span>
					<span >公司</span>
					<span >
						将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
					</span>
				</p>
				<p class="title">
					<span >三</span>
					<span >、</span>
					<span >公司</span>
					<span >如何使用 Cookie 和同类技术</span>
				</p>
				<p class="box-text"><span >（一）Cookie</span></p>
				<p class="box-text">
					<span >为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，</span>
					<span >公司</span>
					<span >
						会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于
						Cookie，网站能够存储您的偏好等数据。您可在浏览器中进行相应的数据清除操作。如您进行清除，您可能无法使用由
					</span>
					<span >公司</span>
					<span >提供的、依赖于</span>
					<span >Cookie的服务或相应功能。</span>
				</p>
				<p class="box-text"><span >（二）Cookie同类技术</span></p>
				<p class="box-text">
					<span >除 Cookie 外，公司还会在网站上使用其他同类技术。例如，公司</span>
					<span >向您发送的站内通知可能含有链接至</span>
					<span >公司</span>
					<span >网站内容的地址链接，如果您点击该链接，</span>
					<span >公司</span>
					<span >则会跟踪此次点击，帮助</span>
					<span >公司</span>
					<span >了解您的产品或服务偏好以便于</span>
					<span >公司</span>
					<span >主动改善客户服务体验。如果您不希望自己的活动以这种方式被追踪，则可以随时从</span>
					<span >公司</span>
					<span >的寄信名单中退订。</span>
				</p>
				<p class="box-text">
					<span >三、</span>
					<span >公司</span>
					<span >如何共享、转让、公开披露您的个人信息</span>
				</p>
				<p class="box-text">
					<span >公司</span>
					<span >已知晓对外共享、转让、公开披露个人信息所承担的相应法律责任，</span>
					<span >公司</span>
					<span >仅会在本隐私政策约定的范围内共享、转让、公开披露您的个人信息：</span>
				</p>
				<p class="box-text"><span >（一）共享</span></p>
				<p class="box-text">
					<span >公司</span>
					<span >不会与</span>
					<span >公司</span>
					<span >服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</span>
				</p>
				<p class="box-text">
					<span >1</span>
					
					<span >在获取明确同意的情况下共享：获得您的明确同意后，</span>
					<span >公司</span>
					<span >会与其他方共享您的个人信息。</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >在法定情形下的共享：</span>
					<span >公司</span>
					<span >可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</span>
				</p>
				<p class="box-text">
					<span >3</span>
					
					<span >在</span>
					<span >公司</span>
					<span >集团内共享：为便于</span>
					<span >公司</span>
					<span >集团共同向您提供服务，推荐您可能感兴趣的信息，识别账号异常或保护</span>
					<span >公司</span>
					<span >集团公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会在</span>
					<span >公司</span>
					<span >集团内共享。</span>
					<span >公司</span>
					<span >只会共享必要的个人信息（如为便于您使用</span>
					<span >公司</span>
					<span >账号使用</span>
					<span >公司</span>
					<span >集团内的产品或服务，</span>
					<span >公司</span>
					<span >会在</span>
					<span >公司</span>
					<span >集团内共享您必要的账户信息）且会受本隐私权政策中所声明目的的约束，如果</span>
					<span >公司</span>
					<span >共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</span>
				</p>
				<p class="box-text">
					<span >4</span>
					
					<span >与授权合作伙伴共享：</span>
					<span >公司</span>
					<span >可能委托授权合作伙伴为您提供某些服务或代表</span>
					<span >公司</span>
					<span >履行职能，</span>
					<span >公司</span>
					<span >
						仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。
					</span>
				</p>
				<p class="box-text">
					<span >5</span>
					
					<span >目前，</span>
					<span >公司</span>
					<span >的授权合作伙伴包括以下类型：</span>
				</p>
				<p class="box-text">
					<span >5.1</span>
					
					<span >广告、分析服务类的授权合作伙伴。除非得到您的许可，否则</span>
					<span >公司</span>
					<span >不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。</span>
					<span >公司</span>
					<span >会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者</span>
					<span >公司</span>
					<span >
						将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行
					</span>
					<span >公司</span>
					<span >委托的广告服务或决策建议。</span>
				</p>
				<p class="box-text">
					<span >5.2</span>
					
					<span >供应商、服务提供商和其他合作伙伴。</span>
					<span >公司</span>
					<span >将信息发送给支持</span>
					<span >公司</span>
					<span >业务的供应</span>
					<span >商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析</span>
					<span >公司</span>
					<span >服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</span>
				</p>
				<p class="box-text">
					<span >6</span>
					
					<span >为了使您能够使用下述服务及功能，</span>
					<span >公司</span>
					<span >的应用中会接入第三方SDK：《第三方SDK目录》</span>
				</p>
				<p class="box-text">
					<span >公司</span>
					<span >
						会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照
					</span>
					<span >公司</span>
					<span >的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。</span>
				</p>
				<p class="box-text"><span >（二）转让</span></p>
				<p class="box-text">
					<span >公司</span>
					<span >不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
				</p>
				<p class="box-text">
					<span >1</span>
					
					<span >在获取明确同意的情况下转让：获得您的明确同意后，</span>
					<span >公司</span>
					<span >会向其他方转让您的个人信息；</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，</span>
					<span >公司</span>
					<span >会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则</span>
					<span >公司</span>
					<span >将要求该公司、组织和个人重新向您征求授权同意。</span>
				</p>
				<p class="box-text"><span >（三）公开披露</span></p>
				<p class="box-text">
					<span >公司</span>
					<span >仅会在以下情况下，公开披露您的个人信息：</span>
				</p>
				<p class="box-text">
					<span >1</span>
					
					<span >获得您明确同意或基于您的主动选择，</span>
					<span >公司</span>
					<span >可能会公开披露您的个人信息；</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >如果</span>
					<span >公司</span>
					<span >确定您出现违反法律法规或严重违反</span>
					<span >公司</span>
					<span >相关协议规则的情况，或为保护</span>
					<span >公司</span>
					<span >及其关联公司用户或公众的人身财产安全免遭侵害，</span>
					<span >公司</span>
					<span >可能依据法律法规或</span>
					<span >公司</span>
					<span >相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及</span>
					<span >公司</span>
					<span >已对您采取的措施。</span>
				</p>
				<p class="box-text">
					<span >（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</span>
				</p>
				<p class="box-text">
					<span >以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span>
				</p>
				<p class="box-text">
					<span >1</span>
					
					<span >与</span>
					<span >公司</span>
					<span >履行法律法规规定的义务相关的；</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >与国家安全、国防安全有关的；</span>
				</p>
				<p class="box-text">
					<span >3</span>
					
					<span >与公共安全、公共卫生、重大公共利益有关的；</span>
				</p>
				<p class="box-text">
					<span >4</span>
					
					<span >与犯罪侦查、起诉、审判和判决执行等有关的；</span>
				</p>
				<p class="box-text">
					<span >5</span>
					
					<span >出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
				</p>
				<p class="box-text">
					<span >6</span>
					
					<span >您自行向社会公众公开的个人信息；</span>
				</p>
				<p class="box-text">
					<span >7</span>
					
					<span >从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
				</p>
				<p class="box-text">
					<span >8</span>
					
					<span >根据法律规定，</span>
					<span >&#xa0;</span>
					<span >若</span>
					<span >公司</span>
					<span >对个人信息采取技术措施和其他必要措施进行处理，使得数据</span>
					<span >
						接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
					</span>
				</p>
				<p class="title">
					<span >四、</span>
					<span >公司</span>
					<span >如何保护您的个人信息</span>
				</p>
				<p class="box-text">
					<span >1</span>
					
					<span >公司</span>
					<span >
						努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。
					</span>
					<span >公司</span>
					<span >将在合理的安全水平内使用各种安全保护措施以保障信息的安全。</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >公司</span>
					<span >
						有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。
					</span>
					<span >公司</span>
					<span >建立专门的管理制度、流程和组织以保障个人信息的安全。</span>
				</p>
				<p class="box-text">
					<span >3</span>
					
					<span >公司</span>
					<span >会采取合理可行的措施，尽力避免收集无关的个人信息。</span>
				</p>
				<p class="box-text">
					<span >4</span>
					
					<span >互联网并非绝对安全的环境，</span>
					<span >公司</span>
					<span >强烈建议您不要使用非</span>
					<span >公司</span>
					<span >推荐的通信方式发送个人信息。您可以通过</span>
					<span >公司</span>
					<span >的服务建立联系和相互分享。当您通过</span>
					<span >公司</span>
					<span >
						的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
					</span>
				</p>
				<p class="box-text">
					<span >5</span>
					
					<span >如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络</span>
					<span >公司</span>
					<span >客服，以便</span>
					<span >公司</span>
					<span >根据您的申请采取相应措施。</span>
				</p>
				<p class="box-text">
					<span >6</span>
					
					<span >请注意，您在使用</span>
					<span >公司</span>
					<span >
						服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发表动态或者在群聊天、圈子等公众场合选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用
					</span>
					<span >公司</span>
					<span >的服务时共享甚至公开分享相关信息。</span>
				</p>
				<p class="box-text">
					<span >7</span>
					
					<span >请使用复杂密码，协助</span>
					<span >公司</span>
					<span >保证您的账号安全。</span>
					<span >公司</span>
					<span >将尽力保障您发送给</span>
					<span >公司</span>
					<span >的任何信息的安全性。</span>
				</p>
				<p class="box-text">
					<span >8</span>
					
					<span >同时，</span>
					<span >公司</span>
					<span >还将按照监管部门要求，上报个人信息安全事件的处置情况。</span>
				</p>
				<p class="title">
					<span >五</span>
					<span >、</span>
					<span >公司</span>
					<span >如何存储您的个人信息</span>
				</p>
				<p class="box-text">
					<span >1</span>
					<span >公司</span>
					<span >在中华人民共和国境内运营中收集和产生的您的个人信息，存储在中国境内。</span>
				</p>
				<p class="box-text">
					<span >2</span>
					<span >您可以通过编辑个人资料来查询、更改、删除、注销您的帐户上的个人资料、注册信息及传送内容等，</span>
					<span >但需注意，</span>
					<span >删除有关信息的同时也会删除用户储存在系统中的文字和图片。</span>
					<span >用户需承担该风险。</span>
				</p>
				<p class="box-text">
					<span >3</span>
					<span >您可以通过设置页面来进行账号注销服务，用户确认注销账号是不可恢复的操作，用户应自行备份与您账号相关的信息和数据，用户确认操作之前与您相关的所有服务均已进行妥善处理。用户确认并同意注销账号后并不代表您账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，用户的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，公司有限自行终止用户的账号注销并无需另行得到用户的同意。</span>

				</p>
				<p class="box-text">
					<span >4</span>
					<span >一般而言，公司自您使用服务时获取到您个人信息之日起至您注销账户后6个月内会存储并保留您的个人信息。</span>
		
				</p>
				<p class="box-text">
					<span >5</span>
					<span >在您的个人信息超出保留期间后，公司会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span>
		
				</p>
				<p class="box-text">
					<span >6</span>
					<span >当公司的产品或服务发生停止运营的情形时，公司将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。</span>
		
				</p>
				<p class="title">
					<span >六</span>
					<span >、本隐私权政策如何更新</span>
				</p>
				<p class="box-text">
					<span >公司的隐私权政策可能变更。</span>
				</p>
				<p class="box-text">
					<span >未经您明确同意，</span>
					<span >公司</span>
					<span >不会限制您按照本隐私权政策所应享有的权利。对于重大变更，</span>
					<span >公司</span>
					<span >会提供显著的通知（包括</span>
					<span >公司</span>
					<span >会通过</span>
					<span >公司公示的方式进行通知甚至向您提供弹窗提示）。</span>
				</p>
				<p class="box-text"><span >本政策所指的重大变更包括但不限于：</span></p>
				<p class="box-text">
					<span >1</span>
					
					<span >公司</span>
					<span >的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
				</p>
				<p class="box-text">
					<span >2</span>
					
					<span >公司</span>
					<span >在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</span>
				</p>
				<p class="box-text">
					<span >3</span>
					
					<span >个人信息共享、转让或公开披露的主要对象发生变化；</span>
				</p>
				<p class="box-text">
					<span >4</span>
					
					<span >您参与个人信息处理方面的权利及其行使方式发生重大变化；</span>
				</p>
				<p class="box-text">
					<span >5</span>
					
					<span >公司</span>
					<span >负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
				</p>
				<p class="box-text">
					<span >6</span>
					
					<span >个人信息安全影响评估报告表明存在高风险时。</span>
				</p>
			</div>
		</div>
		<div class="bottom">
			<div class="btn" @click="$router.back(-1)">返回上一页</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			//点击logo
			clickLogo() {
				this.$router.push({path:'/'})
			},
		}
	}
</script>

<style lang="scss" scoped>
.nav-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 5%;
	box-shadow: 0 1px 1px 0 hsla(0, 0%, 60.8%, 0.5);
	height: 50px;
	.logo {
		cursor: pointer;
		display: flex;
		align-items: center;
		.logo-box{
			width: 38px;
			height: 38px;
			background: #003d6e;
			border-radius: 50%;
			margin-right: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.15s;
		}
		.logo-img {
			width: 24px;
			height: 24px;
			transition: all 0.15s;
		}
		.name {
			width: 40px;
			.en-name {
				color: #222;
				font-size: 17px;
				font-weight: bold;
				transition: all 0.15s;
			}
		}
	}
	.logo:hover .logo-img{
		transform: scale(1.1);
	}
	.logo:hover .logo-box{
		transform: scale(1.1);
	}
	.logo:hover .en-name{
		color: #003d6e;
		font-size: 18px;
	}
}
.agreement {
	width: 60%;
	margin: 60px auto 10px;
	padding:20px;
	border-radius: 6px;
	background-color: rgba(0,0,0,.1);
	height: 600px;
	.my-style{
		.top-tlite{
			text-align: center;
			padding:30px 0;
			span{
				color: #222;
				font-size: 12pt;
				font-weight: bold;
			}
		}
		.title{
			margin:6px 0;
			span{
				color:#2e2e2e; 
				font-size:12pt; 
				font-weight:bold
			}
		}
		.box-text{
			padding:2px 0;
			padding-left:1em;
			span:first-child{
				padding-right: 5px;
			}
			span{
				color:#2e2e2e;
				 font-size:10.5pt;
			}
		}
	}
}
.bottom{
	width: 60%;
	padding:20px 0 20px 40px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
	.btn{
		padding:5px 10px;
		box-shadow: 0 0 3px rgba(0,0,0,.2);
		border-radius: 2px;
		cursor: pointer;
		color:#005faf;
	}
}
</style>
