<template>
  <div class="search-list" v-if="showLocationSearchList">
    <div class="searchs" @onkeyup="searchAddress()" style="cursor: pointer;" v-if="this.showSearch">
      <input class="searchs-input" v-model="tosearchAddress" placeholder="请输入地址"  @focus.stop="stopTurn()"/>
    </div>
    <div class="search-item" v-for="item in hereList" :key="item.id" @click="chooseLocation(item)">
      <div class="location">
        <span class="location-text">{{ item.title }}</span>
      </div>
      <div class="address">{{ item.address }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      initMap: {
        key: 'VDMBZ-LGZ6S-DA7OA-62DA2-ZFJU7-MABZV',
        output: 'jsonp'
      },
      hereList: [],                                                // 显示地址列表	
      choiceAddress: '',
      tosearchAddress: '',
    }
  },

  mounted(){
    if(this.address.length == 0){
      this.getLocation()
    }else{
      this.searchAddress()
    }
    this.$watch('address',()=>{
      this.searchAddress()
    })
    this.$watch('tosearchAddress',()=>{
      this.searchAddress()
    })
    this.$watch('enterEvents',()=>{
      this.searchAddress()
    })
  },

  props: {
    showLocationSearchList: {                             // 是否显示组件
      type: Boolean,
      default: false,
    },
    enterEvents: {                                        // enter事件
      type: Boolean,
      default: false,
    },
    address: {                                            // 显示地址
      type: String,                                   
      default: () => []
    },
    showSearch: {
      type: Boolean,
      default: false,                                     // 是否显示搜索input组件
    },
  },

  methods: {
    stopTurn(){
      this.$emit("donotClose")
    },

    // 获取当前默认地址
    getLocation() {
      let url = "https://apis.map.qq.com/ws/location/v1/ip" // 这个就是地理位置信息的接口
      this.$jsonp(url, this.initMap)
      .then((res) => {
        if(res.result) {
          this.getHereLocation(res.result.location.lat, res.result.location.lng)
        }
      })
      .catch()
    },

    // 获取附近地址
    getHereLocation(lat, lng) {
      let url = `https://apis.map.qq.com/ws/place/v1/here?boundary=nearby(${lat},${lng},1000)&policy=${1}`
      this.$jsonp(url, this.initMap)
      .then((res) => {
        this.hereList = res.data
      })
      .catch()
    },

    // 搜索所有地址
    searchAddress() {    
      if (!this.tosearchAddress && !this.address) {
        return
      }
      let dataAddress 
      if(this.tosearchAddress && this.address){
        dataAddress = this.tosearchAddress
      }else{
        dataAddress = this.address ? this.address : this.tosearchAddress
      }
      var url = `https://apis.map.qq.com/ws/place/v1/suggestion?keyword=${dataAddress}` //这个就是地理位置信息的接口
      this.$jsonp(url, this.initMap)
        .then(res => {
          this.hereList = res.data
        })
        .catch()
    },

    // 点击选择地点
    chooseLocation(data){
      this.choiceAddress = data.address
      let datas = {
        myShowAddres: data.title,
        addressInput: data.title,
        showLocationSearchList:  false,
        latitude: data.location.lat,
        longitude: data.location.lng,
        province: data.province ? data.province : data.ad_info.province,
        city: data.city ? data.city : data.ad_info.city,
        area: data.district ? data.district : data.ad_info.district,
        address: `${data.address}|${data.title}`,
      }
      this.$emit("chooseAddress", datas)
      this.$emit("show")
    },
  },
}
</script>

<style lang="scss" scoped>
  .search-list{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 269px;
    height: 269px;
    background: #FFFFFF;
    box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    margin-top: 8px;
    z-index: 99;
    .searchs{
      margin-left: 18px;
      margin-bottom: 5px;
      margin-top: 15px;
      .searchs-input{
        width: 220px;
        height: 29px;
        background: #f5f5f5;
        border-radius: 3px;
        padding-left: 6px;
      }
    }
    .search-item{
      padding: 10px 15px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.2s;
      .location{
        display: flex;
        .location-text{
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 5px;
        }
      }
      .address{
        color: #888888;
        line-height: 17px;
        font-size: 12px;
        padding-left: 6px;
        box-sizing: border-box;
        margin-top: 5px;
      }
    }
    .search-item:hover{
      background: rgba(0, 94, 174, 0.1);
      .location-text{
        color: #1465CF;    
      }
      .address{
        color: #1465CF;    
      }
    }
  }
</style>