<template>
	<div class="card">
		<div class="img-card" v-if="type === 1">
			<el-image class="ok-img" :src="$cosUrl + (item.cover?item.cover : item.url)" v-if="item.fileStatus===1" fit="cover" :style="{borderRadius: Radius}" @error="error">
				<div slot="error" class="image-slot">
					<img class="ok-img-err" :src="errorImg" alt="">
				</div>
			</el-image>
			<div class="err-img-box" v-else>
				<img class="err-img" :src="errorImg" alt="">
			</div>
		</div>
		<div class="img-card" else>
			<el-image class="ok-img" :src="$cosUrl + url" fit="cover" :style="{borderRadius: Radius }">
				<div slot="error" class="image-slot">
					<img class="ok-img-err" :src="errorImg" alt="">
				</div>
			</el-image>
		</div>
	</div>


</template>

<script>
	export default{
		data(){
			return{
				errorImg: require('@/assets/error-img.png'),
				bigArr: null
			}
		},
		props:{
			item: {
				type:Object,
				default:null,
				
			},
			newImgArr: {
				type:Array,
				default:null
			},
			url: {
				type:String,
				default:null
			},
			type: {
				type: Number,
				default:1,
			},
			Radius: {
				type: String,
				default:null,
			}
		},
		mounted(){
			// if(this.newImgArr) {
			// 	let arrList = []
			// 	this.newImgArr.forEach(item=>{
			// 		arrList.push(this.$cosUrl+item.newUrl)
			// 	})
			// 	this.bigArr = arrList
			// }
			
		},
		methods:{
			error(){
				if(!this.item.cover) return
				this.$emit("onError", true)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.card{
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.img-card{
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		display: flex;
		align-items: center;
		justify-content: center;
		.ok-img{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.image-slot{
				width: 100%;
				height: 100%;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				justify-content: center;
				.ok-img-err{
					width: 150px;
					height: 170px;
					
				}
			}
			
		}
		.err-img-box{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f8f8f8;
			.err-img{
				width: 150px;
				height: 170px;
			}
		}
		
	}
	
</style>
