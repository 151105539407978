<template>
	<div class="content-box">
		<div class="centent" :class="showMargin ? 'margin' : ''">
			<div class="nav-box">
				<div class="nav-left" @click="goLink(0)">
					<div class="logo"><img class="logo-img" src="../assets/logo_latter.png" alt="" /></div>
				</div>
				<div class="nav-right">
					<div class="nav-list">
						<span class="list-home"  @click="goLink(0)">
							<el-image
								class="list-avatar"
								fit="cover"
								:src=" showLink === 0 ? require('@/assets/navbar/homes.png') : require('@/assets/navbar/unhomes.png')"
								placeholder="abc"
							></el-image>
							<span class="nav-item" :class="showLink === 0 ? 'active' : ''">主页</span>
						</span>
						<span class="list-find" @click="goLink(1)">
							<el-image
								class="list-avatar"
								fit="cover"
								:src=" showLink === 1 ? require('@/assets/navbar/choose.png') : require('@/assets/navbar/unchoose.png')"
							></el-image>
							<span class="nav-item" :class="showLink === 1 ? 'active' : ''">发现</span>
						</span>
						<span class="list-article" @click="goLink(2)">
							<el-image
								class="list-avatar"
								fit="cover"
								:src=" showLink === 2 ? require('@/assets/navbar/article.png') : require('@/assets/navbar/unarticle.png')"
							></el-image>					
							<span class="nav-item" :class="showLink === 2 ? 'active' : ''">文章</span>					
						</span>
						<span class="list-club" @click="goLink(3)">
								<el-image
									class="list-avatar"
									fit="cover"
									:src=" showLink === 3 ? require('@/assets/navbar/club.png') : require('@/assets/navbar/unclub.png')"
								></el-image>					
								<span class="nav-item" :class="showLink === 3 ? 'active' : ''" >俱乐部</span>					
						</span>
					</div>
					<div class="nav-user" v-if="login && !noshowAvatar" @click.stop="openUser">
							<div class="user-img">
								<img-head v-if="userAvatar" :avatarUrl="userAvatar" :Radius="50" :size="50"></img-head>
							</div>
							<p class="user-name">{{ usernick }}</p>
							<div class="sign-outs" :class="showOut?'show-outs':''">
								<div class="switchingclubs" @click.stop="goQuit"><span class="out-text">退出账户</span></div>
							</div>
					</div>
				</div>
			</div>
		</div>
    <login-popup :openClub="openClub" :show="showLogin" @close="closeLogin"></login-popup>
	</div>
</template>

<script>

	import { eventBus } from '../main.js'
	import imgHead from './img-head.vue'
	import LoginPopup from './Login.vue'
	export default {
		data() {
			return {
				showOut:false,
				showLogin: false,
				usernick: '',			// 用户昵称
				userAvatar: '',			// 用户头像
				isLogin: false,
				openClub: false,
				login: false,
				noshowAvatar: false,
				isOpenNewPages: false,
				
			};
		},
		
		props:{
			isIndex: {
				type: Boolean,
				default: false
			},
			showLink: {
				type: Number,
				default: 0
			},
			showAvatar: {
				type: Boolean,
				default: true
			},
			showMargin: {
				type: Boolean,
				default: true
			},
		},
		
		watch:{
			'$store.state.userInfo':function() {
				this.usernick = this.$store.state.userInfo?this.$store.state.userInfo.nickname:''
			},
			'$store.state.token':function() {
				if(this.$store.state.token){
					this.noshowAvatar = false
				}			
			},
		},		

		beforeMount() {
			if(!window.localStorage.getItem('imlatte_token')){
				this.noshowAvatar = true
			}else{
				this.noshowAvatar = false
			}
			if (this.$store.state.userInfo) {
				this.login = true
				let data = this.$store.state.userInfo;
				this.usernick = data.nickname
				this.userAvatar = data.avatarUrl
			}
			else {
				if(this.isIndex) return
				this.$request.post('/userInfo/personalInfo').then(res => {
					if (res.code === 200) {
						let data = res.data
						this.$store.commit('SET_USER', data);
						this.usernick = data.nickname
						this.userAvatar = data.avatarUrl
					}
				}).catch(()=>{
					// 
				});
			}
		},
		
		components:{
			imgHead,
			LoginPopup,
		},

		mounted() {
      document.addEventListener('click', this.HiddenClick,false)			
		},

		async created(){
			// eventBus.$on("roleChanges",(num) => {				
			// 	this.goQuit();
			// 	this.$message.warning("您没有管理员权限,无法访问")
			// })
			eventBus.$on("haveConfirm",(num) => {			
				if(num==0){
					this.isOpenNewPages = false
				}
			})
			eventBus.$on("dataChanges",(num) => {			
				if(num==0){
					this.isOpenNewPages = true
				}else{
					this.isOpenNewPages = false
				}
			})
			if (window.localStorage.getItem('imlatte_token')) {
				try{
					await this.$request.post(`/userInfo/personalInfo`)
					this.isLogin = true
				} catch(e) {
					// token失效
					this.isLogin = false
				}
			} else {
				this.isLogin = false
			}
		},

		beforeDestroy(){
			document.removeEventListener('click', this.HiddenClick,false)
		},
		
		methods: {
			// 点击页面事件 隐藏需要隐藏的区域
			HiddenClick () {
				if(this.showOut){
					this.showOut = false
				}
			},

			// 导航跳转
			goLink(e) {
			if(this.isOpenNewPages){
				this.$confirm('离开页面无法保存数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
        }).then(() => {
					this.isOpenNewPages = false

					if(e===0){
						this.src = 0 
						this.$router.push({name: 'Index'})
					}else if(e===1){
						this.src = 1
						this.$router.push({name: 'Moment'})
					}else if(e===2){
						this.src = 2
						this.$router.push({name: 'Article'})
					}
					else if(e===3){
						this.src = 3
						if (window.localStorage.getItem('imlatte_token')){
							// let clubId = window.localStorage.getItem('clubId')
							// this.$router.push({name: 'ClubDetail', params: {clubId: clubId}})
							this.$router.push({ name: 'ClubRecommendation'})
						}else{
							this.openLogin()
						}
					}
        }).catch(() => {
					this.isOpenNewPages = true
					this.$message.warning({
						showClose: true,
						type: 'info',
						message: '已取消跳转'
					});
        });
			}else{
				if(e===0){
					this.src = 0 
					this.$router.push({name: 'Index'})
				}else if(e===1){
					this.src = 1
					this.$router.push({name: 'Moment'})
				}else if(e===2){
					this.src = 2
					this.$router.push({name: 'Article'})
				}
				else if(e===3){
					this.src = 3
					if (window.localStorage.getItem('imlatte_token')){
						// let clubId = window.localStorage.getItem('clubId')
						// this.$router.push({name: 'ClubDetail', params: {clubId: clubId}})
						this.$router.push({ name: 'ClubRecommendation'})
					}else{
						this.openLogin()
					}
				}
			}
			},

			// 打开退出框
			async openUser() {
				this.showOut = !this.showOut
			},

			// 点击退出
			goQuit(){
				this.isOpenNewPages = false
				this.login = false
				this.showOut = false
				this.$store.commit('CLEAR_STORAGE')
				window.localStorage.clear();
				// this.refresh()
				this.$router.push({name: 'Moment'})
				this.noshowAvatar = true
			},
			
			//刷新页面
			refresh() {
				if (this.$store.state.userInfo) {
					let data = this.$store.state.userInfo;
					this.usernick = data.nickname
					this.userAvatar = data.avatarUrl
				}
				else {
					if(this.isIndex) return
					this.$request.post('/userInfo/personalInfo').then(res => {
						if (res.code === 200) {
							let data = res.data
							this.$store.commit('SET_USER', data);
							this.usernick = data.nickname
							this.userAvatar = data.avatarUrl
						}
					}).catch(()=>{
						// 
					});
				}
			},

			// 跳转个人主页
			goUserInfo(){
				this.$router.push({name: 'User'})
			},

			closeLogin(){
				this.showLogin = false
				this.current = 0
				this.login = true
				this.openClub = false
				this.showOut = false
				this.refresh()
			},
	
			openLogin() {
				this.showLogin = true
				this.refresh()
			},
		}
	};
</script>

<style lang="scss" scoped>
.margin {
	margin-bottom: 20px;
}
.centent {
	width: 100%;
	height: 76px;
	background: #FFFFFF;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	.nav-box{
		width: 1022px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		.nav-left {
			display: flex;
			align-items: center;
			.logo {
				width: 178px;
				height: 50px;
				// margin-right: 20px;
				flex-shrink: 0;
				cursor: pointer;
				.logo-img {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
		}
		.nav-right {
			display: flex;
			flex:1;
			align-items: center;
			position: relative;
			justify-content: flex-end;
			padding-right: 50px;
			box-sizing: border-box;
			.nav-list {
				box-sizing: border-box;
				display: flex;
				align-items: center;
				height: 50px;
				.list-find,
				.list-club,
				.list-article,
				.list-home{
					display: flex;
					justify-content: center;
					align-content: center;
					cursor: pointer;
					.list-avatar{
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
					.nav-item {
						height: 22px;
						font-size: 16px;
						font-weight: 400;
						color: #888888;
						line-height: 22px;
						margin-right: 50px;
						position: relative;
						transition: all 0.5s;
					}
				}
				.active {
					color: #013D6E !important;
				}
			}
			.nav-user {
				position: absolute;
				right: -184px;
				top: -13px;
				width: 184px;
				height: 76px;
				display: flex;
				align-items: center;
				cursor: pointer;
				margin-left: 117px;
				.user-img {
					width: 50px;
					height: 50px;
					margin-right: 10px;
				}
				.user-name {
					font-size: 16px;
					font-weight: bold;
					color: #444444;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.sign-out{
					width: 100px;
					height: 0;
					background: #fff;
					border-radius: 5px;
					position:absolute;
					// box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
					right: 50px;
					z-index: 99;
					//修改页面抖动
					top: 65px;
					box-sizing: border-box;
					transition: all 0.20s cubic-bezier(0.49, -0.3, 0.68, 1.23);
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.bottom-to{
						padding-left: 14px;
						padding-bottom: 10px;
						border-bottom: 1px solid #dddddd;
						color:#888888;
						.out-text{
							font-size: 14px;
							// color:#888888
						}
					}
					.bottom-to:hover{
						color: #1465CF !important;
						// background-color: black;
					}
					.bottom-out{
						padding-left: 14px;
						padding-top: 5px;
						color:#888888;
						.out-text{
							font-size: 14px;
							// color:#888888;
						}
					}
					// .top-out:hover .top-name{
					// 	font-weight: bold;
					// }
					.bottom-out:hover{
						color: #1465CF !important;
					}
				}
				.sign-outs{
					width: 100px;
					height: 0;
					background: #fff;
					border-radius: 5px;
					position:absolute;
					// box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
					right: 50px;
					z-index: 99;
					//修改页面抖动
					top: 65px;
					box-sizing: border-box;
					transition: all 0.20s cubic-bezier(0.49, -0.3, 0.68, 1.23);
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.switchingclubs{
						padding-left: 25px;
						// padding-top: 5px;
						color:#888888;
						.out-text{
							font-size: 14px;
							// color:#888888;
						}
					}
					// .top-out:hover .top-name{
					// 	font-weight: bold;
					// }
					.switchingclubs:hover{
						color: #1465CF !important;
					}
				}
				.show-out{
					height: 80px;
					box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
				}
				.show-outs{
					height: 40px;
					box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
				}
			}
			.nav-login{
				display: flex;
				.login {
					font-size: 16px;
					font-weight: bold;
					color: #1465CF;
					cursor: pointer;
					//修改抖动
					// margin-left: 66px;
				}
			}
			
		}
	}
}

</style>
