<template>
  <div class="content-box">
    <!-- body -->
    <div v-if="!freeMovegroup" v-cloak>
      <div v-if="!emptyMember">
        <div class="top-card">
          <div class="card-title">
            <div class="title-left">成员管理</div>
            <div class="title-right">
              <div class="member-number">{{ this.allmember }}人</div>
              <div class="more-roles" @click.stop="openChoice">
                <el-image class="images" :src="require('@/assets/more-roles.png')" fit="cover"></el-image>
              </div>
            </div>
          </div>
          <div class="sign-out" :class="showOut?'show-out':''">
            <div class="edit-information" @click.stop="newgroup"><span class="out-text">新建分组</span></div>
            <div class="delete-image" @click.stop="movegroup"><span class="out-text">移动分组</span></div>
          </div>
        </div>
        <el-scrollbar style="height: 460px; width: 100%">
          <div class="group-box" >
            <div class="group-card" v-loading="loadingMember"  v-for="(item, index) in groupMember" :key="index">
              <div class="groupname-icon">
                <div class="fans">{{ item.groupName }}（{{ item.groupMember.length }}人）</div>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover">
                  <div class="message-popover" >
                    <div class="message-popover-item" @click="modifyName(item)">修改组名</div>
                    <div class="message-popover-item" @click="deleteName(item)">删除分组</div>
                  </div>
                  <div class="choiceMore" slot="reference">
                    <icon-font class="icon" name="moreline" size="22px" color="#005faf"></icon-font>
                  </div>
                </el-popover>
              </div>
              <div class="fans-box">
                <div class="fans-detail" v-for="(items, indexs) in item.groupMember" :key="indexs" @click="clickgroup(items)">
                  <div class="fans-image">
                    <img-head :avatarUrl="items.avatarUrl" :Radius="50" :size="60"></img-head>	
                  </div>
                  <div class="fans-name">{{ items.nickname }}</div>
                  <div class="nochoice" >
                    <div class="content">
                      <el-image class="more-roles" :src="require('@/assets/unchoicePhoto.png')" fit="cover"></el-image>
                    </div>
                    <div class="contents" v-if="items.checked">
                      <el-image class="more-roles" :src="require('@/assets/choicePhoto.png')" fit="cover"></el-image>
                    </div>
                  </div> 
                </div>
              </div>
            </div>      
          </div>
          <div class="member-card" v-loading="loadingMember" v-if="this.memberList.length!=0">
            <div class="groupname-icon">
              <div class="fans">成员（{{ this.memberList.length }}人）</div>
            </div>
            <div class="fans-box">
              <div class="fans-detail" v-for="(item, index) in memberList" :key="index" @click="clicknogroup(item)">
                <div class="fans-image">
                  <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
                </div>
                <div class="fans-name">{{ item.nickname }}</div>
                <div class="nochoice" >
                  <div class="content">
                    <el-image class="more-roles" :src="require('@/assets/unchoicePhoto.png')" fit="cover"></el-image>                  
                  </div>
                  <div class="contents" v-if="item.checked">
                    <el-image class="more-roles" :src="require('@/assets/choicePhoto.png')" fit="cover"></el-image>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </el-scrollbar>	       
        <div class="button-box">
          <div class="unadd" v-if="!addbuttonDisable">
            <div class="text">移除</div>
          </div>
          <div class="cancel" v-else>
            <div class="text" @click="removegroup">移除</div>
          </div>
          <div class="unadd" v-if="!addbuttonDisable">
            <div class="text">分组</div>
          </div>
          <div class="add" v-else>
            <div class="text" @click="grouping">分组</div>
          </div>
        </div> 
      </div>
      <div v-else>
        <div class="top-card">
          <div class="card-title">
            <div class="title-left">成员管理</div>
            <div class="title-right">
              <div class="more-roles" @click.stop="openChoice">
                <el-image
                class="images" 
                :src="require('@/assets/more-roles.png')"
                fit="cover">
                </el-image>
              </div>
            </div>
          </div>
          <div class="sign-out" :class="showOut?'show-out':''">
            <div class="edit-information" @click.stop="newgroup"><span class="out-text">新建分组</span></div>
            <div class="delete-image" @click.stop="movegroup"><span class="out-text">移动分组</span></div>
          </div>
        </div>
        <div class="empty-card">
          <el-image
          class="el-image-empty" 
          :src="require('@/assets/empty.png')"
          fit="cover">
          </el-image>
          <div class="empty-text">暂无成员</div>
        </div>
      </div>
    </div>

    <!-- 分组移动 -->
    <div v-else> 
      <div class="top-card">
        <div class="card-title">
          <div class="title-left">成员管理</div>
        </div>
      </div>
      <draggable v-model="groupMember"  chosenClass="chosen" forceFallback="true" group="people" animation="0" @start="onStart" @end="onEnd">
        <transition-group>
          <div class="item" v-for="element in groupMember" :key="element.groupId">{{element.groupName}}（{{ element.groupMember.length }}人）<div class="more-list"><icon-font name="more-list" color="#1465CF"></icon-font></div></div>
        </transition-group>
      </draggable>
      <div class="button-boxs">
        <div class="cancel">
          <div class="cancel-text" @click="cancelMobileGrouping()">取消</div>
        </div>
        <div class="confirm">
          <div class="confirm-text" @click="confirmMobileGrouping()">确定</div>
        </div>	
      </div>
    </div>

    <!-- 新建分组弹窗 -->
    <div class="create-group" v-if="createNewGroup">
      <span class="box-view-top">
        <div class="title">
          <span class="title-text">{{ modifymygroup ? "修改分组" : "新建分组"}}</span>
          <icon-font name="close" color="#444444" size="16px"  @click="closeadminpopup" style="cursor: pointer;margin-right: 15px;margin-top: -20px"></icon-font>
        </div>
        <div class="subgroup-name">
          <el-input type="text" class="subgroup" v-focus  ref="autofocusNewgroup" autofocus style="padding-left:5px"  :value="createGroup" @input="introlenMin"></el-input>
          <span class="text-statistics">
          {{ this.textstatistics }}/10
          </span>
        </div>
        <div class="button-boxs">
          <div class="confirm">
            <div class="confirm-text" @click="confirmcreate()">确定</div>
          </div>	
        </div>			
      </span>
    </div>

    <!-- 移动分组弹窗 -->
    <div class="move-group" v-if="moveNewGroup">
      <span class="box-view-top"  @click="canceldark()">
        <div class="title">
          <span class="title-text">选择分组</span>
          <icon-font name="close" color="#444444" size="16px"  @click="closeMovepopup" style="cursor: pointer;margin-right: 15px;margin-top: -20px"></icon-font>
        </div>
        <div class="subgroup-names" v-if="showCreateGroup">
          <el-input type="text" class="subgroup"  v-focus autofocus="true" @keydown.native="keydown($event)"  maxlength="10" @click.stop.native placeholder="输入分组名字"  v-model="moveCreateGroup" @input="moveIntrolenMin(moveCreateGroup)"></el-input>
          <span class="text-statistics">
          {{ this.movetextstatistics }}/10
          </span>
          <div class="create-button changeCursor" @click.stop v-if="this.moveCreateGroup.trim().length === 0">
            <div class="create-text">
              新建
            </div>
          </div>
          <div class="create-buttons" v-else @click.stop="confirmcreate">
            <div class="create-texts">
              新建
            </div>
          </div>
        </div>
        <div class="create-newgroup" v-else @click.stop="showCreate">
					<icon-font name="add" size="11px" color="#A8A8A8" style="margin: 0 3px 0 5px"></icon-font>
          <div class="group-text">新建分组</div>
        </div>
        <div v-if="!closeMyGroup">
          <div class="default-cover-list" >
            <div class="roles-list">
              <div class="item-line" v-for="(item, index) in groupMember" :key="index" @click="getSelectIds(item)" >
                <div class="left-name">{{item.groupName}}（{{ item.groupMember.length }}人）</div>
                <div class="right"  v-if="item.checked">
                  <icon-font name="mychoice" color="#1465CF" size="16px" ></icon-font>
                </div>
                <div class="right" v-else>
                  <icon-font name="weixuanzhongyuanquan" color="#444444" size="16px" ></icon-font>
                </div>
              </div>
            </div>
          </div>
          <div class="button-boxs">
            <div class="confirm">
              <div class="confirm-text" @click="confirmgrouping()">确定</div>
            </div>	
          </div>
        </div>
        <div v-else>
          <div class="default-cover-list changeCursor" >
            <div class="roles-list ">
              <div class="item-line changeCursor" v-for="(item, index) in groupMember" :key="index" >
                <div class="left-name" style="color: #A8A8A8">{{item.groupName}}（{{ item.groupMember.length }}人）</div>
                <div class="right"  v-if="item.checked">
                  <icon-font name="mychoice" color="#A8A8A8" size="16px" ></icon-font>
                </div>
                <div class="right" v-else>
                  <icon-font name="weixuanzhongyuanquan" color="#444444" size="16px" ></icon-font>
                </div>
              </div>
            </div>
          </div>
          <div class="button-boxs">
            <div class="confirm changeCursor" style="background:#A8A8A8">
              <div class="confirm-text">确定</div>
            </div>
          </div>
        </div>
      </span>
    </div>

    <!-- 提示弹窗 -->
    <tip-popup :title="this.titles" :isOpen="isOpen" @close="closePopup" @confirm="confirm"></tip-popup>
    
  </div>

</template>

<script>
//导入draggable组件
import draggable from 'vuedraggable'

import tipPopup from '../../components/tip-popup.vue'
import iconFont from '../../components/IconFont.vue'
import imgHead from '../../components/img-head.vue'
import {eventBus} from '../../main.js'

export default {
  data() {
    return {
      showOut: false,                                // 显示更多操作窗口
      loadingMember: false,                          // 是否显示加载
      memberList: [],                                // 俱乐部成员
      groupMember: [],                               // 俱乐部分组信息
      allmember: 0,                                  // 俱乐部除粉丝以外人员总数
      titles: '',                                    // 弹窗提示标题
      isOpen: false,                                 // 是否打开提示弹窗
      addbuttonDisable: false,                       // 按钮高亮
      createNewGroup: false,                         // 是否显示新建分组弹窗
      createGroup: '',                               // 新建分组
      textstatistics: 0,                             // 新建分组文字统计
      movetextstatistics: 0,                         // 移动分组文字统计
      groupItem: [],                                 // 分组暂存信息
      modifymygroup: false,                          // 修改分组名
      moveNewGroup: false,                           // 移动分组弹窗显示
      moveCreateGroup: '',                           // 移动分组里的新建分组model
      showCreateGroup: false,                        // 显示移动中新建分组input
      closeMyGroup: false,                           // 是否新建分组按钮导致周围致灰
      mychoiceGroup: 0,                              // 我选中的移动分组
      freeMovegroup: false,                          // 是否开启移动分组模式
      recordGroup: [],                               // 记录移动前的数组以便回复
      drag:false,                                    // drag插件
      emptyMember: false,                            // 是否没人
      stopFlag: false,                               // 是否取消置灰
      oldMemberData: null,                           // 存放选中的人员消息
      oldGroupData: null,                            // 存放选中的人员消息
      newGroupLen: 0,                                // 新建分组长度
    }
  },
  components:{
    tipPopup,
    iconFont,
    draggable,
    imgHead
  },

  async mounted() {
    eventBus.$on('closeEditMode', () => {
      this.cancelMobileGrouping()
    })
    await this.searchMember();
    document.addEventListener('click', this.HiddenClick,false)
  },

  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,false)
  },

  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    }
  },

  methods:{
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      if(this.showOut){
        this.showOut = false
      }
    },

    // 打开图片权限窗口
    openChoice() {
      this.showOut = !this.showOut
    },

    // 查询俱乐部创建者、管理员、成员
    async searchMember() {
      try {
        let res = await this.$request.get(`/clubMemberInfo/selectClubUserByGroup?clubId=${this.$route.params.clubId}&type=7`)
        if (res.code === 200) {     
          let i = 0
          let j = 0
          for(let item of res.data.groupMember){
            item.checked = false
            j++
            for(let items of item.groupMember){
              if(items.id!=0){
                i++
              }
            }
          }
          this.memberList = res.data.notGroupMember
          this.groupMember = res.data.groupMember
          this.allmember = i + this.memberList.length
          if(this.allmember == 0 && j == 0){
            this.emptyMember = true
          }
        }
      } catch (e) {
				//
			}
    },
    
    //去除左侧空格
    LTrim(str) { 
      return str.replace(/^\s*/g, "")
    },

		// 限制分组名称字数
		introlenMin(e){
			let cursor = e.length								// 获取光标位置
			let regSpace = /\s{3,}|[\r\n]/g			// 多个空格或换行
			let trimStrL = this.LTrim(e)				// 去掉左边空格
			let trimStrC = ''										// 替换空格后数据
			let realLength = 0 									// 字节长度
			let byteItemLength = 0
			if(cursor !== -1) {
				let strL = trimStrL.slice(0,cursor)
				cursor = strL.replace(regSpace, '  ').length
			}
			trimStrC = Array.from(trimStrL.replace(regSpace,'  '))
			if(trimStrC.length == 0) {
				this.newGroupLen = 0
			}
			for(let i =0 ; i<trimStrC.length; i++){
				byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
				realLength += byteItemLength
				if(realLength >= 1 && realLength <= 3){
					this.newGroupLen = 1
				} else {
					if(Math.ceil(parseFloat(realLength)/3) > 10) {
						trimStrC = trimStrC.splice(0,i)
						this.$message.warning({
              showClose: true,
							message: '组名最多不能超过10字哦～',
						})
						this.newGroupLen = 10
					} else {
						this.newGroupLen = Math.ceil(parseFloat(realLength)/3)
					}
				}
      }
      this.createGroup = trimStrC.join("")
      this.textstatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.createGroup)).split("").map(val => val.charCodeAt()).length)/3)
			return {
				value:trimStrC.join(""),
			}
    },

    // 禁止输入空格
    keydown(e){
      if(e.keyCode == 32){
          e.returnValue = false
      }
    },

    // 用户分组最小限制字符
    moveIntrolenMin(text) {
      // 添加一个标识 flag ，满足条件后将flag 改变，并return中断判断
      let flag = true
      text.trim()
      this.movetextstatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(text)).split("").map(val => val.charCodeAt()).length)/3)
      if (flag && text.length >= 10) {
        this.$message.success({
          showClose: true,
          message: "分组名称最多不能超过10字哦~",
        })
        flag = false;
        this.movetextstatistics = 10
      } else {
        return
      }
    },

    // 选择要移动的分组
    getSelectIds(item){
      if(this.mychoiceGroup == item.groupId){
        item.checked = !item.checked
        return
      }
      for(let items of this.groupMember){
        if(items.checked){
          items.checked = false
        }
      }
      item.checked = !item.checked
      this.mychoiceGroup = item.groupId
    },

    // 顶部新建分组
    newgroup() {
			// this.isOpen = true
      // this.titles = "您将失去俱乐部创建者身份,是否确定转让？"
      this.createNewGroup = true
      this.showOut = false
    },

    // 顶部移动分组
    movegroup() {
      if(this.groupMember.length==0){
        this.$message.success({
          showClose: true,
          message: "暂无分组信息,请先新建分组",
        })
        return
      }
      this.freeMovegroup = true
      this.showOut = false
      this.recordGroup = this.groupMember
    },

    // 底部移除分组
    async removegroup() {
			this.isOpen = true
      this.titles = "确定要移除吗？"
    },

    // 弹窗确认移除分组
    async confirmRemovegroup() {
      try{
        let memberId = []
        for(let item of this.memberList){
          if(item.checked){
            memberId.push({clubId: this.$route.params.clubId, userId: item.userId})
          }
        }
        for(let item of this.groupMember){
          for(let items of item.groupMember){
            if(items.checked){
              memberId.push({clubId: this.$route.params.clubId, userId: items.userId})
            }
          }
        }
        let res = await this.$request.post("/clubMemberInfo/kickMembers",memberId)
        if(res.code===200){
          this.$message.success({
            showClose: true,
            message: "成员已移除",
          })
          this.createNewGroup = false
          this.searchMember();
        }
      }catch(e){
        //
      }
    },

    // 底部分组
    grouping() {
      let ids = []
      for(let item of this.memberList){
        if(item.checked){
          this.moveNewGroup = true
          return
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            ids.push(item.groupId)
          }
        }
      }
      ids.sort()
      if(ids.length == 1 || ids[0]==ids[ids.length-1]){
        for(let item of this.groupMember){
          if(item.groupId == ids[0]){
            item.checked = true
          }
        }
        this.moveNewGroup = true
      }else{
        this.moveNewGroup = true
        return
      }
    },

    // 弹窗确认底部分组
    async confirmgrouping() {
      let memberId = []
      let groupIds = 0
      for(let item of this.memberList){
        if(item.checked){
          memberId.push(item.id)
        }
      }
      for(let item of this.groupMember){
        if(item.checked){
          groupIds = item.groupId
        }
        for(let items of item.groupMember){
          if(items.checked){
            memberId.push(items.id)
          }
        }
      }
      if(this.oldMemberData !=null && this.oldGroupData !=null){
        for(let item of this.oldMemberData){
          if(item.checked){
            memberId.push(item.id)
          }
        }
        for(let item of this.oldGroupData){
          for(let items of item.groupMember){
            if(items.checked){
              memberId.push(items.id)
            }
          }
        }
      }
      let data = {
        clubId: this.$route.params.clubId,
        groupId: groupIds ? groupIds : 0,
        memberIdList: memberId,
      }
      if(data.groupId==0){
        delete data.groupId
      }
      let res = await this.$request.post("/clubMemberGroup/setClubGroup",data)
      if(res.code===200){
        this.$message.success({
          showClose: true,
          message: "成功将成员移至该组",
        })
        this.moveNewGroup = false
        this.searchMember();
        this.addbuttonDisable = false
        this.mychoiceGroup = 0
        this.oldGroupData = null
        this.oldMemberData = null
      }
    },

    // 修改分组
    async modifyName(item){
      this.modifymygroup = true
      this.createNewGroup = true
      this.createGroup = item.groupName
      this.groupItem = item

      // 自行判断字符长度
      this.createGroup.trim()
      this.textstatistics = this.createGroup.length
    },

    // 取消置灰
    canceldark() {
      this.showCreateGroup = false
      this.closeMyGroup = false
      this.moveCreateGroup = ''
      this.createGroup = ''
      this.textstatistics = 0
      this.movetextstatistics = 0
    },

    // 弹窗确认删除分组
    async deleteName(item){
			this.isOpen = true
      this.titles = "确定要删除该分组吗？"
      this.groupItem = item
    },

    // 删除改分组
    async confirmdeleteName(){
      try{
        let data = {
          clubId: this.$route.params.clubId,
          groupIdList: [this.groupItem.groupId],
        }
        let res = await this.$request.post("/clubMemberGroup/deleteClubGroup",data)
        if(res.code===200){
          this.$message.success({
            showClose: true,
            message: "已删除该分组",
          })
          this.searchMember();
          this.groupItem = ''
        }
      }catch(e){
        //
      }
    },    

    // 选中group人员
    clickgroup(items) {
      items.checked = !items.checked
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            this.addbuttonDisable = true
            return 
          }else{
            this.addbuttonDisable = false
          }    
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          this.addbuttonDisable = true
          return
        }else{
          this.addbuttonDisable = false
        }
      }
    },  
    
    // 选中nogroup人员
    clicknogroup(item) {
      item.checked = !item.checked
      for(let items of this.memberList){
        if(items.checked){
          this.addbuttonDisable = true
          return
        }else{
          this.addbuttonDisable = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            this.addbuttonDisable = true
            return 
          }else{
            this.addbuttonDisable = false
          }    
        }
      }
    },    

    // 关闭删除tipop弹窗
		closePopup() {
      this.isOpen = false
      this.showOut = false
    },

    // 关闭新建分组弹窗
    closeadminpopup() {
      this.createNewGroup = false   
      this.modifymygroup = false
      this.createGroup = ''
      this.textstatistics = 0   
    },

    // 关闭移动分组弹窗
    closeMovepopup() {
      this.moveNewGroup = false   
      this.moveCreateGroup = ''
      this.movetextstatistics = 0 
      this.showCreateGroup = false                         // 关闭移动分组里的新建分组
      this.closeMyGroup = false                            // 致灰按钮关闭
      for(let item of this.groupMember){
        if(item.checked){
          item.checked = false
        }
      }  
    },

    // 显示移动分组里的新建分组
    showCreate() {
      this.showCreateGroup = true
      this.closeMyGroup = true
    },

    // 确认新建分组
    async confirmcreate() {
      if(this.modifymygroup){
        let data = {
          clubId: this.$route.params.clubId,
          list: [
            {
              clubId: this.$route.params.clubId,
              groupName: this.createGroup,
              id: this.groupItem.groupId,
              sort: this.groupItem.groupSort
            }
          ],
        }
        if(this.createGroup == this.groupItem.groupName){
          this.createNewGroup = false
          this.groupItem = ''
          this.createGroup = ''
          this.modifymygroup = false
          return
        }
        let res = await this.$request.post("/clubMemberGroup/editClubGroup",data)
        if(res.code===200){
          this.$message.success({
            showClose: true,
            message: "已修改分组",
          })
          this.createNewGroup = false
          this.modifymygroup = false
          this.searchMember();
          this.groupItem = ''
          this.createGroup = ''
        }
      }else{
        try{
          let data = {
            clubId: this.$route.params.clubId,
            groupName: this.createGroup ? this.createGroup : this.moveCreateGroup,
          }
          let res = await this.$request.post("/clubMemberGroup/createClubGroup",data)
          if(res.code===200){
            this.$message.success({
              showClose: true,
              message: "已新建分组",
            })
            this.oldMemberData = this.memberList
            this.oldGroupData = this.groupMember
            await this.searchMember()
            this.moveCreateGroup = ''                 // 移动分组v-model值
            this.createGroup = ''                     // 新建分组v-model值
            this.textstatistics = 0                   // 新建分组统计数字
            this.movetextstatistics = 0               // 移动分组统计数字
            this.addbuttonDisable = false             // 按钮高亮
            this.closeMyGroup = false        
            this.showCreateGroup = false              // 显示新建分组 
            this.createNewGroup = false               // 关闭新建分组弹窗
            this.$nextTick(()=>{
              this.groupMember[0].checked = true
            })
          }
        }catch(e){
          //
        }
      }
    },

    // 确认回调事件
    confirm() {
      if(this.titles=="确定要移除吗？"){
        this.confirmRemovegroup()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
      if(this.titles=="确定要删除该分组吗？"){
        this.confirmdeleteName()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
    },

    // 取消移动分组
    cancelMobileGrouping() {
      this.freeMovegroup = false
      this.groupMember = this.recordGroup
    },

    // 确认移动分组
    async confirmMobileGrouping() {
      let list = []
      for(let i=0;i<this.groupMember.length;i++){
        this.groupMember[i].groupSort = i+1
      }
      for(let item of this.groupMember){
        list.push({ clubId: this.$route.params.clubId, groupName:item.groupName, id:item.groupId, sort:item.groupSort })
      }
      let data = {
        clubId: this.$route.params.clubId,
        list: list
      }
      let res = await this.$request.post("/clubMemberGroup/editClubGroup",data)
      if(res.code === 200) {
        this.$message.success({
          showClose: true,
          message: "移动成功",
        })
        this.searchMember()
        this.freeMovegroup = false
      }      
    },

    //开始拖拽事件
    onStart(){
      this.drag=true;
    },

    //拖拽结束事件
    onEnd() {
      this.drag=false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .content-box {
    width: 790px;
    min-height: 614px;
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px 0 60px 0;
    position: relative;
    .top-card{
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
      .card-title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .title-left {
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          color: #000000;
        }
        .title-right {
          display: flex;
          align-items: center;
          .member-number{
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: #444444;
            margin-bottom: 5px;
            margin-right: 20px;
          }
          .more-roles{
            cursor: pointer;
            .images{
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .sign-out{
        cursor: pointer;
        width: 120px;
        height: 0;
        background: #fff;
        border-radius: 5px;
        position:absolute;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
        right: -31px;
        z-index: 99;
        //修改页面抖动
        top: 28px;
        box-sizing: border-box;
        transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .edit-information{
          margin-bottom: 5px;
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .edit-information:hover{
          color: #1465CF !important;
          // background-color: black;
        }
        .delete-image{
          margin-bottom: 5px;
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .delete-image:hover{
          color: #1465CF !important;
        }
        .delete-album{
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .delete-album:hover{
          color: #1465CF !important;
        }
      }
      .show-out{
        height: 70px;
        box-shadow: 0px 2px 10px 0px undefined; 
      }
    }
    .group-box {
      padding-left: 20px;
      padding-right: 20px;
      .group-card{
        padding-top: 10px;
        position: relative;
        .groupname-icon{    
          display: flex;
          justify-content: space-between;  
          .fans{
            margin-bottom: 10px;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            color: #444444;
            line-height: 22px;
          }
          .choiceMore{
            cursor: pointer;
          }
        }
        .fans-box{
          display: flex;
          .fans-detail{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            position: relative;
            cursor: pointer;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            .fans-image{
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;         
            }
            .nochoice{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 99;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18px;              
                background: #1465CF ;
                border-radius: 50%;
                z-index: 99;              
              }
            }
          }
        }
      }
      .group-card:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #dddddd;
        transform: scaleY(0.5);
        transform-origin: center top;
        z-index: 2;
      }
    }
    .member-card{
      padding-left: 20px;
      padding-right: 20px;
      .groupname-icon{    
        display: flex;
        padding-top: 10px;
        justify-content: space-between;
        position: relative;
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .choiceMore{
          cursor: pointer;
        }
      }
      .groupname-icon:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #dddddd;
        transform: scaleY(0.5);
        transform-origin: center top;
        z-index: 2;
      }
      .fans-box{
        display: flex;
        .fans-detail{
          display: flex;
          flex-direction: column;
          position: relative;
          cursor: pointer;
          height: 85px;
          width: 60px;
          margin-right: 20px;
          margin-bottom: 20px;
          .fans-image{
            .images{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .fans-name{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;         
          }
          .nochoice{
            position: absolute;
            left: 17px;
            top: 40px;
            width: 20px;
            height: 20px;
            z-index: 99;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 18px;
              height: 18px;
              background: #1465CF;
              border-radius: 50%;
              z-index: 99;
            }
          }
        }
      }
    }
    .button-box{
      position: absolute;
      bottom: 0;
      left: 310px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .cancel{
          width: 100px;
          height: 30px;
          background:#EC4747;
          border-radius: 5px;  
          margin-right: 10px;
          cursor: pointer;
          .text{
            font-size: 14px;
            font-weight: 400;
            color:  #FFFFFF;
            line-height: 20px;
            padding: 5px 35px;
          }
        }
      .add{
          width: 100px;
          height: 30px;
          background:#1465CF;
          border-radius: 5px;
          cursor: pointer;
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            padding: 5px 35px;
          }
        }
      .unadd{
          width: 100px;
          height: 30px;
          background: #A8A8A8;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            padding: 5px 35px;
          }
        }
    }
    .button-boxs{
      position: absolute;
      bottom: 0;
      left: 300px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
     .cancel{
          width: 100px;
          height: 30px;
          border-radius: 5px;  
          border: 1px solid #dddddd;
          margin-right: 10px;
          cursor: pointer;
          .cancel-text{
            font-size: 14px;
            font-weight: 400;
            color:  #444444;
            line-height: 20px;
            padding: 5px 35px;
          }
        }
      .confirm{
        width: 100px;
        height: 30px;
        background:#1465CF;
        border-radius: 5px;
        cursor: pointer;
        .confirm-text{
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          padding: 4px 35px;
        }
      }
    }
    .empty-card{
      text-align: center;
      margin-top: 225px;
      .el-image-empty{
        height: 40px;
        width: 40px;
      }
      .empty-text{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 25px;
      }
    }
    .changeCursor{
      cursor: default !important;
    }

    // 移动分组样式
    .move-group{
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, .2);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .box-view-top{
        box-sizing: border-box;
        width: 480px;
        min-height: 180px;
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .title{
          margin-top: 20px;
          display: flex;
          .title-text{
            width: 100%;
            margin-left: 204px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #000000;
            line-height: 25px;;
          }
          .close{
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
        .create-newgroup{
          display: flex;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;
          width: 360px;
          border-radius: 5px;
          padding: 8px 3px;
          margin-left: 60px;
          margin-top: 25px;
          position: relative;
          .group-text {
            font-size: 12px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #A8A8A8;
            line-height: 17px;
          }
          .group-text::before{
            content: "";
            position: absolute;
            top: -50%;
            bottom: -50%;
            left: -50%;
            right: -50%;
            width: 200%;
            height: 200%;
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
            border: solid 1px #dddddd;
            box-sizing:border-box;
            border-radius: 5px;
          }
        }
        .subgroup-names {
          display: flex;
          align-items: center;
          margin-top: 25px;
          margin-left: 59px;
          .subgroup {
            width: 296px;
            height: 34px;
            border-radius: 5px;
            position: relative;
          }
          .text-statistics{
            position: absolute;
            top: 80px;
            right: 140px;
            font-size: 12px;
            font-family: PingFang, PingFang-Regular;
            font-weight: 400;
            text-align: center;
            color: #a8a8a8;
            line-height: 17px;
          }
          .create-button {
            margin-left: 10px;
            border-radius: 5px;
            border: 1px solid #A8A8A8;
            padding: 7px 13px;
            cursor: pointer;
            .create-text {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #A8A8A8;
              line-height: 20px;
            }
          }
          .create-buttons {
            margin-left: 10px;
            border-radius: 5px;
            border: 1px solid #1465CF;
            padding: 7px 13px;
            cursor: pointer;
            .create-texts {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #1465CF;
              line-height: 20px;
            }
          }
        }
        .default-cover-list{
          display: flex;
          flex-wrap: wrap;
          margin: 20px 60px 65px 60px;
          .roles-list{
            .item-line{
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid #DDDDDD;
              width: 360px;
              .left-name{ 
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 20px;
              }
            }
            .item-line:last-child{
              border-bottom: none;
            }
          }
        }
        .button-boxs{
          position: absolute;
          bottom: 0;
          left: 200px;
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          .confirm{
            width: 100px;
            height: 30px;
            background:#1465CF;
            border-radius: 5px;
            cursor: pointer;
            .confirm-text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 4px 35px;
            }
          }
        }
      }
    }

    // 新建分组样式
    .create-group{
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, .2);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .box-view-top{
        box-sizing: border-box;
        width: 480px;
        height: 180px;
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .title{
          margin-top: 20px;
          display: flex;
          .title-text{
            width: 100%;
            margin-left: 204px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #000000;
            line-height: 25px;;
          }
          .close{
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
        .subgroup-name {
          margin-top: 25px;
          margin-left: 86px;
          .subgroup {
            width: 309px;
            height: 34px;
            border-radius: 5px;
          }
          .text-statistics{
            position: absolute;
            top: 80px;
            right: 95px;
            font-size: 12px;
            font-family: PingFang, PingFang-Regular;
            font-weight: 400;
            text-align: center;
            color: #a8a8a8;
            line-height: 17px;
          }
        }
        .button-boxs{
          position: absolute;
          bottom: 0;
          left: 200px;
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          .confirm{
            width: 100px;
            height: 30px;
            background:#1465CF;
            border-radius: 5px;
            cursor: pointer;
            .confirm-text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 4px 35px;
            }
          }
        }
      }
    }
  }
</style> 

<style lang="scss">
.el-popover {
    position: absolute;
    background: #FFF;
    min-width: 120px;
    border: 1px solid #EBEEF5;
    padding: 12px;
    z-index: 2000;
    color: #606266;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0rgb(0 0 0 / 10%);
    word-break: break-all;
}

input::-webkit-input-placeholder{  // 针对 谷歌 内核
  color: #A8A8A8;
}
input:-moz-placeholder{   // 火狐
  color: #A8A8A8;
}
input:-ms-input-placeholder{  // 微软ie
  color: #A8A8A8;
}
</style>

<style lang="scss" scoped>
  /*被拖拽对象的样式*/
  .item {
    padding: 10px 10px 10px 20px;
    cursor: move;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #444444;
    line-height: 22px;
    position: relative;
    .more-list{
      display: inline-block;
      float: right;
    }
  }
  .item:hover {
      background-color: #EDF5FF;
      cursor: move;
  }
  .item:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.05rem;
    background-color: #dddddd;
    transform: scaleY(0.5);
    transform-origin: center top;
    z-index: 2;
}
  /*选中样式*/
  .chosen {
    background-color: #EDF5FF;
    opacity: 0;
    color: #444444;
  }

  [v-cloak] {
  display: none;
  }
</style>