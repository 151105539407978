import { render, staticRenderFns } from "./choiceClub.vue?vue&type=template&id=103f6b28&scoped=true&"
import script from "./choiceClub.vue?vue&type=script&lang=js&"
export * from "./choiceClub.vue?vue&type=script&lang=js&"
import style0 from "./choiceClub.vue?vue&type=style&index=0&id=103f6b28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "103f6b28",
  null
  
)

export default component.exports