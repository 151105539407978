/**
 * 自定义上传图片
 * @param {*} type   {Number}       请求类型 -- 1.用户头像；2.发布动态；3.发起活动；4.新建俱乐部头像；5.俱乐部相册6.活动群组头像；7.俱乐部群组头像；8.动态评论；9.活动评论；10.吐槽（不同的请求类型需要给不同的主键）
 * @param {*} file   {Object}       要上传的文件对象
 */
// import $request from './request.js'
import store from '../store/index.js'
const COS = require('cos-js-sdk-v5')

const uploadImg = (type, file) => {
	return new Promise((resolve, reject) => {
		let credentialData = store.state.credentialData
		let signData = store.state.signData
		// console.log('上传文件 --- 临时凭证', credentialData)
		// console.log('上传文件 --- 签名', signData)
		// console.log('上传文件 --- 文件对象', file)
		let camSafeUrlEncode = function (str) {
			return encodeURIComponent(str)
			.replace(/!/g, '%21')
			.replace(/'/g, '%27')
			.replace(/\(/g, '%28')
			.replace(/\)/g, '%29')
			.replace(/\*/g, '%2A');
		};
		// 随机5位字母或数字
		let random = function () {
			let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
			let result = '';
			for (let i = 5; i > 0; --i) 
				result += str[Math.floor(Math.random() * str.length)];
			return result;
		}
		// 随机20位字母或数字+当前时间戳
		let random2 = function () {
			let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
			let result = '';
			for (let i = 20; i > 0; --i) 
				result += str[Math.floor(Math.random() * str.length)];
				result += new Date().getTime()
			return result;
		}
		let filePath = file.name
		let directoryPrefix = credentialData.directoryPrefix;
		let Key = '';
		if(type === 1 || type === 3 || type === 4 || type === 101){
			Key = directoryPrefix.substr(0, directoryPrefix.lastIndexOf("*")) + random() + '.jpg';
		}else{
			Key = directoryPrefix.substr(0, directoryPrefix.lastIndexOf("*")) + random2() + filePath.substr(filePath.lastIndexOf('.'));
		}
		
		// 创建cos SDK实例
		let cos = new COS({
			getAuthorization: function (options, callback) {
				callback(signData)
			}
		})
		
		// 上传文件
		cos.putObject({
			Bucket: credentialData.bucket,
			Region: credentialData.region,
			Key: Key,
			StorageClass: 'STANDARD',
			Body: file,	// 上传文件对象
			onProgress: function progress(progressData) {
				// 上传进度的回调
				// console.log('上传进度的回调', progressData)
			}
		},function(err, data){
			// 处理上传成功和失败
			if(data && data.Location){
				let url = camSafeUrlEncode(Key).replace(/%2F/g, '/');
				// console.log('截取后的url', url)
				if (/^2\d\d$/.test('' + data.statusCode)) {
					// 根据type的类型触发相应的逻辑，可以自行编写type等于其他值情况下的逻辑
					if(type === 1){
						// 修改用户头像请求
						// try{
						// 	let result = await $request.request('/userInfo/changeAvatar', 'POST', {avatarUrl: url})
						// 	if(result.code === 200){
						// 		uni.setStorageSync('updatePage', true)
						// 		getApp().globalData.userInfo.avatarUrl = url
						// 		uni.navigateBack()
						// 		return resolve(result)
						// 	}
						// }catch(e){
						// 	//TODO handle the exception
						// }
					}
					else if(type === 101){
						// 修改用户头像请求 -- 新用户自动上传头像
						// try{
						// 	let result = await $request.request('/userInfo/firstChangeAvatar', 'POST', {avatarUrl: url})
						// 	if(result.code === 200){
						// 		return resolve(url)
						// 	}
						// }catch(e){
						// 	//TODO handle the exception
						// }
					}
					else if(type === 2){
						return resolve(url)
					}
					else if(type === 3){
						return resolve(url)
					}
					else if(type === 4){
						return resolve(url)
					}
					else if(type === 5){
						return resolve(url)
					}
					else if(type === 10){
						return resolve(url)
					}
					else if(type === 100){
						return resolve(url)
					}
				}
			}
			if(err){
				reject()
			}
		})
	})
}

export default uploadImg