<template>
  <div class="content-box">
    <div class="back-button"  @click="goback">
      <icon-font class="icon" name="arrow-left" size="13px" color="#1465CF"></icon-font>
      <div class="button-text">返回</div>
    </div>
    <div class="main-content">
        <dynamic-card :item="newsDetail" :showAttention="true" ></dynamic-card>
    </div>
    <div class="divied-line">推荐帖子</div>
    <div class="recommended-posts" v-if="recommndList.length>0">
      <div v-for="item in recommndList" :key="item.viewId">
        <dynamic-card :item="item" :showAttention="true" v-if="item.newsId"></dynamic-card>
      </div>
    </div>
    <div class="checked-more" @click.stop="isshow = !isshow">
      <div class="more-text">
        查看更多
      </div>
    </div>
    <download-app :flag="isshow" @close="closeQRcode"></download-app>
  </div>
</template>

<script>
import iconFont from '../../components/IconFont.vue'
import dynamicCard from "../../components/dynamic-card.vue"
import downloadApp from '../../components/download-app.vue'
export default {
  data(){
    return{
      pageIndex: 1,
      pageSize: 10,
      list: [],
      nomore: false,
      isLoading: false,
      isshow: false,
      Loading: false,
      recommndList: [],
      newsDetail: null,
    }
  },
  components:{
    iconFont,
    dynamicCard,
    downloadApp
  },
	created(){
		this.newsDetail = this.$store.state.newsDetail
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0		
	},
  mounted(){
    this.getFollow()
    this.$nextTick(()=>{
      this.getRecommond()
    })
    document.addEventListener('click', this.HiddenClick,true)
  },
  
  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,true)
  },
  methods:{
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      this.isshow = false
    },
    // 获取关注内容
    async getFollow(){
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.isLoading = true
      this.Loading = true
			try{
        let res = await this.$request.get(`/home/getWebHomeDiscoveryMain`)
				if (res.code === 200) {
          this.list = res.data
        }
			}
      catch(e){
				//
      }
      this.isLoading = false
      loading.close()
    },
    // 获取推荐内容
    async getRecommond(){
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.isLoading = true
      this.Loading = true
			try{
        let res = await this.$request.get(`/home/getWebHomeDiscoveryOther`)
				if (res.code === 200) {
          this.recommndList = res.data
        }
			}
      catch(e){
				//
			}
      this.isLoading = false
      loading.close()
    },
    //回退
    goback(){
      this.$router.go(-1);
    },
    //关闭下载二维码
		closeQRcode() {
			this.isshow = false
    },
  },

}
</script>

<style lang="scss" scoped>
  .content-box{
    width: 790px;
    .back-button{
      display: flex;
      flex-direction: row;
      justify-content: start;
      cursor: pointer;
      margin-bottom: 15px;
      .button-text{
        margin-left: 3px;
        margin-bottom: 2px;
        font-size: 16px;
        font-weight: 500;
        color: #1465CF;
        line-height: 22px;
      }
    }
    .divied-line{
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 22px;
      border-left: 5px solid #013D6E;
      padding-left: 5px;
      margin: 20px 0;
    }
    .checked-more{
      .more-text{
        margin-top: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        color: #1465CF;
        line-height: 22px;
      }
    }
  }
</style>