<template>
  <el-scrollbar>
    <div class="index-page">
			<!-- 导航栏 -->
			<div class="index-page-navbar">
				<navbar-card :showLink="0" :showAvatar="false" :showMargin="false" :isIndex="true" @login="openLogin"></navbar-card>
			</div>
			
			<div class="index-head">
				<img class="head-bg" :src="require('@/assets/index_head_bg.png')" alt="LattePlus">
				<div class="head-inner">
					<transition appear>
						<div class="head-inner-left">
							<img class="head-picture" :src="require('@/assets/index_head_picture.png')" alt="LattePlus">
						</div>
					</transition>
					<div class="head-inner-right">
						<transition appear>
							<img class="latte-text" :src="require('@/assets/index_head_text.png')" alt="">
						</transition>
						<div class="lp-download">
							<div class="lq-download-item" slot="reference">
								<transition appear>
									<img class="download-img" :src="require('@/assets/index_ios.png')" alt="" @mouseenter="showIosCode = true" @mouseleave="showIosCode = false">
								</transition>
								<div class="download-view" v-if="showIosCode">
									<div class="sanjiao"></div>
									<img class="download-code" :src="require('@/assets/ios_download_qrcode.png')" alt="">
								</div>
							</div>
							<div class="lq-download-item" slot="reference">
								<transition appear>
									<img class="download-img" :src="require('@/assets/index_android.png')" alt="" @mouseenter="showAndroidDownload" @mouseleave="showAndroidCode = false">
								</transition>
								<div class="download-view" v-if="showAndroidCode">
									<div class="sanjiao"></div>
									<el-image class="download-code" :src="$cosUrl + '/default/APK/APK_CODE.jpg?time='+timestamp">
										<!-- <div slot="error" class="image-slot">
											<img class="download-code" :src="require('@/assets/android_download_qrcode.png')" alt="">
										</div>  -->
									</el-image>
									<!-- <img class="download-code" :src="require('@/assets/android_download_qrcode.png')" alt=""> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="index-body">
				<img class="body-bg" :src="require('@/assets/index_bottom_bg.png')" alt="LattePlus">
				<div class="body-line">
					<transition appear>
						<div class="body-item">
							<div class="item-text item-right-space" >
								<p class="text">更多精彩</p>
								<div class="border-line"></div>
							</div>
							<el-image class="body-img" :src="require('@/assets/index_body_1.png')" alt="" />
						</div>
					</transition>
					<transition appear>
						<div class="body-item">
							<el-image class="body-img" :src="require('@/assets/index_body_2.png')" alt="" />
							<div class="item-text item-left-space">
								<p class="text">加入TA们</p>
								<div class="border-line" style="margin-left: 0;"></div>
							</div>
						</div>
					</transition>
				</div>
				<div class="body-line">
					<transition appear>
						<div class="body-item">
							<div class="item-text item-right-space">
								<p class="text">打个招呼</p>
								<div class="border-line"></div>
							</div>
							<el-image class="body-img" :src="require('@/assets/index_body_3.png')" alt="" />
						</div>
					</transition>
					<transition appear>
						<div class="body-item">
							<el-image class="body-img" :src="require('@/assets/index_body_4.png')" alt="" />
							<div class="item-text item-left-space">
								<p class="text">玩伴集结</p>
								<div class="border-line" style="margin-left: 0;"></div>
							</div>
						</div>
					</transition>
				</div>
			</div>

      <!-- 页脚 -->
      <div class="index-footer">
        <Footer></Footer>
      </div>

      <login-popup :show="showLogin" @close="closeLogin"></login-popup>
    </div>
  </el-scrollbar>
</template>

<script>
import LoginPopup from '../components/Login.vue'
import navbarCard from '../components/navbar-card.vue'
import Footer from '../components/Footer.vue'
export default {
	name: 'Index',
	components: {
		LoginPopup,
		navbarCard,
		Footer,
	},
	
	data() {
		return {
			current: 0,
			showLogin: false,
			showIosCode: false,
			showAndroidCode: false,
			userInfo: null,					// 用户信息
			timestamp: 0,
		}
	},

	beforeCreate() {
		document.querySelector('body').setAttribute('style', 'min-height:100%;margin:0;padding:0;background:  #fff;')
	},

	async created(){
		const token = window.localStorage.getItem('imlatte_token')
		if (token != null) {
			try{
				let res = await this.$request.post(`/userInfo/personalInfo`)
				this.$store.commit("SET_USER", res.data)
				this.userInfo = res.data
			} catch(e) {
				this.$store.commit('CLEAR_STORAGE')
			}
		} else {
			//
		}
	},
	
	methods: {
		closeLogin(){
			this.showLogin = false
			this.current = 0
		},
	
		openLogin() {
			this.showLogin = true
		},

		showAndroidDownload() {
			this.showAndroidCode = true
			let nowTimestamp = new Date().getTime()
			if((nowTimestamp - this.timestamp) > 60000)
			this.timestamp = nowTimestamp
		}
	}
	
}
</script>

<style lang="scss" scoped>
.v-enter,
.v-leave-to {
	opacity: 0;
	transform: translateY(20px);
}

.v-enter-active,
.v-leave-active {
	transition: all 1s ease;
}
.index-page {
	width: 100vw;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	position: relative;

	.index-page-navbar {
		position: sticky;
		top: 0;
		z-index: 10;
	}

	.index-head {
		width: 100%;
		height: 610px;
		position: relative;
		display: flex;
		justify-content: center;
		.head-bg {
			width: 1920px;
			height: 610px;
			position: absolute;
			top: 0;
			left: calc(50% - 960px);
			z-index: -1;
		}
		.head-inner {
			display: flex;
			justify-content: center;
			margin-top: 90px;
			.head-inner-left {
				.head-picture {
					width: 330px;
					height: 544px;
				}
			}
			.head-inner-right {
				margin-top: 110px;
				margin-left: 40px;
				.latte-text {
					width: 300px;
					height: 157px;
					margin-left: -20px;
				}
				.lp-download {
					display: flex;
					margin-top: 60px;
					.lq-download-item {
						margin-right: 10px;
						cursor: pointer;
						.download-img {
							width: 146px;
							height: 43px;
						}
					}
				}
				.download-view {
					width: 146px;
					height: 146px;
					border-radius: 10px;
					background: #fff;
					margin-top: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
					.sanjiao{
						position: absolute;
						top: -8px;
						left: 60px;
						border-left: 10px solid #fff;
						border-top: 10px solid #fff;
						border-bottom: 10px solid transparent;
						border-right: 10px solid transparent;
						z-index: 2;
						transform: rotate(45deg);
					}
					.download-code {
						width: 117px;
						height: 117px;
						display: block;
						z-index: 1;
					}
				}
				
			}
		}
	}

	.index-body {
		padding-top: 100px;
		position: relative;
		.body-bg {
			width: 1920px;
			height: 824px;
			position: absolute;
			bottom: 0;
			left: calc(50% - 960px);
			z-index: -1;
		}
		.body-line {
			display: flex;
			align-items: center;
			.body-item {
				flex-shrink: 0;
				width: 600px;
				display: flex;
				align-items: center;
				margin-bottom: 120px;
				.body-img {
					width: 332px;
					height: 600px;
					display: block;
				}
				.item-text {
					flex-shrink: 0;
					display: flex;
					flex-direction: column;
					.text {
						font-size: 50px;
						font-weight: bold;
						color: #444444;
					}
					.border-line {
						width: 1em;
						font-size: 50px;
						height: 5px;
						background: linear-gradient(316deg, #0270A7 0%, #013D6E 100%);
						border-radius: 5px;
						margin-top: 6px;
						margin-left: 3em;
					}
				}
				.item-left-space {
					margin-left: 30px;
				}
				.item-right-space {
					margin-right: 30px;
				}
			}
		}
	}
	.index-footer {
		width: 100%;
	}
}
</style>