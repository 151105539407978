<template>
  <div class="data-table">
    <div class="header">历史活动</div>
    <div class="select-area">
      <div class="select-item">
        <div class="label">活动开始时间</div>
        <div class="time">
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            range-separator="-"
            start-placeholder="起始"
            end-placeholder="结束"
            format="yyyy/MM/dd HH:mm"
            prefix-icon="el-icon-date"
            :clearable="false"
            :editable="false"
            :picker-options="pickerOptions"
            @change="changeDate">
          </el-date-picker>
        </div>
      </div>
      <div class="select-item">
        <div class="label">活动状态</div>
        <el-dropdown class="select" @command="handleStatus">
          <div class="select-title">
            <div class="select-value">{{ formatStatus(activityStatus) }}</div>
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-dropdown-menu slot="dropdown" style="width: 75px; text-align: center; margin-right: 0px">
            <el-dropdown-item command="0">全部</el-dropdown-item>
            <el-dropdown-item command="1">报名中</el-dropdown-item>
            <el-dropdown-item command="2">报名截止</el-dropdown-item>
            <el-dropdown-item command="3">进行中</el-dropdown-item>
            <el-dropdown-item command="4">已结束</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="select-item">
        <div class="label">费用类型</div>
        <el-dropdown class="select" @command="handleType">
          <div class="select-title">
            <div class="select-value">{{ formatType(feeType) }}</div>
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-dropdown-menu slot="dropdown" style="width: 75px; text-align: center; margin-right: 0px">
            <el-dropdown-item command="0">全部</el-dropdown-item>
            <el-dropdown-item command="4">AA收费</el-dropdown-item>
            <el-dropdown-item command="2">固定收费</el-dropdown-item>
            <el-dropdown-item command="1">免费</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="select-btn" @click="getHistoryActivity">查询</div>
    </div>
    
    <div class="main"  v-loading="loading">
      <div class="empty" v-if="empty">暂未发起活动</div>
      <el-table
        v-if="tableData.length > 0"
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="headerCellStyle">
        <el-table-column
          fixed
          prop="activityName"
          label="活动主题"
          align="center"
          width="100">
          <template slot="header">
            <div class="table-header">活动主题</div>
          </template>
          <template slot-scope="scope">
            <div class="table-text">{{ scope.row.activityName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="activityTime"
          label="活动时间"
          align="center"
          width="148">
          <template slot="header">
            <div class="table-header">活动时间</div>
          </template>
          <template slot-scope="scope">
            <div class="table-text">
              <div>{{ scope.row.startTime }}</div>
              <div style="width: 7px; height: 1px; background-color: #333; margin: 0 auto"></div>
              <div>{{ scope.row.endTime }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="活动地点"
          align="center"
          width="148">
          <template slot="header">
            <div class="table-header">活动地点</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.address.split('|')[1] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createNickname"
          label="活动发起者"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">活动发起者</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="enrollPeople"
          label="报名人数(含候补人数)"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">
              <div>报名人数</div>
              <div>(含候补人数)</div>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.enrollPeople }}人</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="activityStatus"
          label="活动状态"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">活动状态</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ formatStatus(scope.row.activityStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="participationPeople"
          label="实际参与人数"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">实际参与人数</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.participationPeople }}人</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="feeType"
          label="费用类型"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">费用类型</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ formatType(scope.row.feeType) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          label="人均付费"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">人均付费</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.feeType === 1 ? '-' :scope.row.amount.toFixed(2) + '元' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="totalAmount"
          label="系统应转发起者"
          align="center"
          width="130">
          <template slot="header">
            <div class="table-header">
              系统应转发起者
              <el-tooltip class="item" effect="dark" content="活动结束后1个工作日支付至活动发起者的个人账户" placement="top">
                <i class="el-icon-warning-outline" style="color: #0584F9; font-size: 14px"/>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.totalAmount === null ? '-' : scope.row.transferred.toFixed(2) + '元' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="transferred"
          label="系统已转发起者"
          align="center"
          width="130">
          <template slot="header">
            <div class="table-header">
              系统已转发起者
              <el-tooltip class="item" effect="dark" content="因微信支付单日上限10000元，可能进行多笔支付" placement="top">
                <i class="el-icon-warning-outline" style="color: #0584F9; font-size: 14px"/>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.transferred === null ? '-' : scope.row.transferred.toFixed(2) + '元' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="peopleType"
          label="活动范围"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">活动范围</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.peopleType === 1 ? '所有人' : scope.row.peopleType === 2 ? ' 成员及粉丝' : '仅成员'}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        activityStatus: 0,
        feeType: 0,
        value1: '',
        startTime: '',
        endTime: '',
        tableData: [],
        empty: false,
        dateRange: [],            // 筛选的日期范围
        headerCellStyle: {
          height: '48px',
          backgroundColor: '#F3F5F8',
          padding: '4px 0',
        },
        pickerOptions: {
          disabledDate(time) {
            let timestamp = time.getTime()
            let nowTimestamp = Date.now()
            // 禁用距今一年前的日期
            if (timestamp > nowTimestamp || (nowTimestamp - timestamp) > (7879680000 * 4)) {
              return true
            }
          }
        },
      }
    },

    mounted() {
      this.dateRange = [
        new Date(new Date().getTime() - (86400000 * 30)),
        new Date()
      ]
      this.getHistoryActivity()
    },

    methods: {
      // 更改日期
      changeDate(value) {
        if(value) {
          let sDate = new Date(value[0])
          let eDate = new Date(value[1])
          this.startTime = `${sDate.getFullYear()}-${this.fullNumber(sDate.getMonth()+1)}-${this.fullNumber(sDate.getDate())} ${this.fullNumber(sDate.getHours())}:${this.fullNumber(sDate.getMinutes())}:00`
          this.endTime = `${eDate.getFullYear()}-${this.fullNumber(eDate.getMonth()+1)}-${this.fullNumber(eDate.getDate())} ${this.fullNumber(eDate.getHours())}:${this.fullNumber(eDate.getMinutes())}:00`
        } else {
          this.startTime = this.endTime = ''
        }
      },

      fullNumber(str) {
        if(parseInt(str) > 9) {
          return str
        } else {
          return `0${str}`
        }
      },

      handleStatus(value) {
        this.activityStatus = parseInt(value)
      },

      handleType(value) {
        this.feeType = parseInt(value)
      },

      formatStatus(num) {
        let str = ''
        switch(num) {
          case 0:
            str = '全部'
            break
          case 1:
            str = '报名中'
            break
          case 2:
            str = '报名截止'
            break
          case 3:
            str = '进行中'
            break
          case 4:
            str = '已结束'
            break
        }
        return str
      },

      formatType(num) {
        let str = ''
        switch(num) {
          case 0:
            str = '全部'
            break
          case 1:
            str = '免费'
            break
          case 2:
            str = '固定收费'
            break
          case 4:
            str = 'AA收费'
            break
        }
        return str
      },

      // 获取历史活动数据
      async getHistoryActivity() {
        if(this.loading) {
          return
        } else {
          this.loading = true
        }
        try {
          let res
          if(this.startTime && this.endTime) {
            res = await this.$request.get(`/clubData/getHistoryActivity?clubId=${this.$route.params.clubId}&activityStatus=${this.activityStatus}&feeType=${this.feeType}&activityStartTime=${this.startTime}&activityEndTime=${this.endTime}`)
          } else {
            res = await this.$request.get(`/clubData/getHistoryActivity?clubId=${this.$route.params.clubId}&activityStatus=${this.activityStatus}&feeType=${this.feeType}`)
          }
          this.tableData = res.data
          if(res.data.length > 0) {
            this.empty = false
          } else {
            this.empty = true
          }
          this.loading = false
        } catch (error) {
          // console.log('报错', error)
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss">
  .data-table {
    width: 790px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-bottom: 20px;
    box-sizing: border-box;
    .header {
      width: 100%;
      height: 54px;
      font-size: 18px;
      font-weight: bold;
      color: #444;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #ddd;
    }
    .select-area {
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .select-item {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .label {
          color: #333;
          font-size: 12px;
          margin-right: 10px;
        }
        .time {
          width: 254px;
          height: 34px;
          border-radius: 5px;
          box-sizing: border-box;
        }
        .select {
          width: 75px;
          height: 34px;
          border-radius: 5px;
          border: 0.5px solid #ddd;
          padding-right: 5px;
          box-sizing: border-box;
          font-size: 12px;
          color: #333;
          cursor: pointer;
          .select-title {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .select-value {
              flex: 1;
              text-align: center;
            }
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
      .select-btn {
        width: 68px;
        height: 34px;
        background-color: #1465CF;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .main {
      width: calc(100% - 40px);
      min-height: 172px;;
      margin: 0 20px;
      box-sizing: border-box;
    }
  }
  .empty {
    width: 100%;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #888;
  }
  .table-header {
    font-size: 12px;
    color: #333;
  }
  .table-text {
    color: #333;
    font-size: 12px;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }
  .el-range-editor.el-input__inner {
    padding: 2px 4px;
    font-size: 12px;
    color: #333;
  }
  .el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: 3px;
    color: #333;
    float: left;
    line-height: 32px;
    margin-bottom: 6px;
  }
  .el-date-editor .el-range-input {
    color: #333;
    font-size: 12px;
  }
</style>