<template>
	<el-image class="heard-img"
	 :src="formatUrl(avatarUrl)"
	 fit="cover" 
	 :style="{borderRadius: Radius + '%', width: size + 'px', height: size + 'px'}">
		<div slot="error" class="image-slot">
			<img class="ok-img-err" :src="errorImg">
		</div>
	</el-image>
</template>

<script>
	export default {
		data () {
			return {
				errorImg: require('../assets/default-avatar.png')
			}
		},
		props: {
			avatarUrl: {
				type: String,
				default:null
			},
			size: {
				type: Number,
				default: 50
			},
			Radius: {
				type: Number,
				default:0
			},
		},
		methods:{
			formatUrl(avatarUrl){
				let baseUrl = this.$cosUrl
				if(avatarUrl.indexOf('://') == -1){
					return baseUrl + avatarUrl
				}else{
					return avatarUrl
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.heard-img {
		// width: 50px;
		// height: 50px;
		box-sizing: border-box;
		.image-slot{
			width: 100%;
			height: 100%;
			background-color: #f8f8f8;
			display: flex;
			align-items: center;
			justify-content: center;
			.ok-img-err{
				width: 100%;
				height: 100%;
				
			}
		}
	}
</style>
