import Vue from 'vue'
import Router from 'vue-router'
import Index from '../views/Index.vue'
import About from '../views/About.vue'
import Home from '../views/Home.vue'
import Moment from '../views/Moment.vue'
import Agreement from '../views/Agreement.vue'
import Privacy from '../views/Privacy.vue'
import ClubRecommendation from '../views/ClubRecommendation.vue'
import ClubDetail from '../views/club/club-detail.vue'
import ClubTheme from '../views/club/club-theme.vue'
import ClubReleaseEvent from '../views/club/club_release_event.vue'
import ClubReleaseNew from '../views/club/club_release_new.vue'
import ClubMoment from '../views/club/club-moment.vue'
import ClubActivity from '../views/club/club-activity.vue'
import ClubData from '../views/club/club-data.vue'
import ClubActiveData from '../views/club/club-active-data.vue'
import ClubActivityData from '../views/club/club-activity-data.vue'
import DetailDynamic from '../views/discover/detailDynamic.vue'
import Article from '../views/Article.vue'

// 俱乐部成员
import AllMember from '../views/clubMember/allMember.vue'
import Administrator from '../views/clubMember/administrator.vue'
import Blacklist from '../views/clubMember/blacklist.vue'
import ManageMember from '../views/clubMember/manageMember.vue'


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
    },
    {
        path: '/About',
        name: 'About',
        component: About
    },
    {
        path: '/Agreement',
        name: 'Agreement',
        component: Agreement
    },
    {
        path: '/Privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        redirect: '/home/moment',
        children: [
            {
                path: 'moment',
                name: 'Moment',
                component: Moment,
            },
            {
                path: 'article',
                name: 'Article',
                component: Article
            },
            {
                path: 'detailDynamic',
                name: 'DetailDynamic',
                component: DetailDynamic,
            },
            {
                path: 'clubdetail/:clubId',
                name: 'ClubDetail',
                component: ClubDetail,
                redirect: '/home/clubdetail/:clubId/clubmoment',
                meta: {
                    login: true
                },
                children: [
                    {
                        path: 'clubmoment',
                        name: 'ClubMoment',
                        component: ClubMoment,
                    },
                    {
                        path: 'clubReleaseEvent',
                        name: 'ClubReleaseEvent',
                        component: ClubReleaseEvent,
                        meta: {
                            login: true
                        }
                    },
                    {
                        path: 'clubReleaseNew',
                        name: 'ClubReleaseNew',
                        component: ClubReleaseNew,
                        meta: {
                            login: true
                        }
                    },
                    {
                        path: 'allMember',
                        name: 'AllMember',
                        component: AllMember,
                    },
                    {
                        path: 'administrator',
                        name: 'Administrator',
                        component: Administrator,
                        meta: {
                            login: true
                        }
                    },
                    {
                        path: 'blacklist',
                        name: 'Blacklist',
                        component: Blacklist,
                        meta: {
                            login: true
                        }
                    },
                    {
                        path: 'manageMember',
                        name: 'ManageMember',
                        component: ManageMember,
                        meta: {
                            login: true
                        }
                    },
                    {
                        path: 'clubactivity',
                        name: 'ClubActivity',
                        component: ClubActivity,
                        meta: {
                            login: true
                        }
                    },
                    {
                        path: 'clubtheme',
                        name: 'ClubTheme',
                        component: ClubTheme,
                    },
                    {
                        path: 'clubdata',
                        name: 'ClubData',
                        component: ClubData,
                    },
                    {
                        path: 'clubactivedata',
                        name: 'ClubActiveData',
                        component: ClubActiveData,
                    },
                    {
                        path: 'clubactivitydata',
                        name: 'ClubActivityData',
                        component: ClubActivityData,
                    },
                ]
            },
            {
                path: 'clubRecommendation',
                name: 'ClubRecommendation',
                component: ClubRecommendation,
            },
        ]
    },
]

const router = new Router({
    routes,
    mode: 'history'
})


router.beforeEach((to, from, next) => {
    if (to.meta.login) {
        if (!window.localStorage.getItem('imlatte_token')) {
            next({
                path: '/'
            })
        }
        next()
    } else {
        next()
    }
})

export default router