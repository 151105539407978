
const timeFormat = function (time) {
    // 时间格式化 2019-09-08
    let year = time.getFullYear();
    let month = time.getMonth() + 1 > 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
    let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
    let h = time.getHours() >= 10 ? time.getHours() : "0" + time.getHours();              //获取当前小时数(0-23)
    let m = time.getMinutes() >= 10 ? time.getMinutes() : "0" + time.getMinutes();         //获取当前分钟数(0-59)
    return h + ":" + m;

}
export default timeFormat