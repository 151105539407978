<template>
  <div class="login" v-show="show" :class="show ? 'show' : ''" :style="[zoomStyle]">
    <div class="login-wraps">
      <div class="title-line">
        <h1 class="login-text">请选择你要管理的俱乐部</h1>
        <icon-font class="close" name="close" color="#999999" size="16px" v-if="rescreening"  @click="close"></icon-font>
      </div>
      <div class="login-area" v-loading="loading">
        <div class="login-box" v-for="(item,index) in list" :key="index" :class="item.id == choiceactivityType ? 'current' : ''" @click="choiceType(item)">
          <div class="area-left">
            <el-image
            class="el-image-more" 
            :src="$cosUrl + item.url"
            style="width: 70px;height: 70px;border-radius: 10px"
            fit="cover"
            ></el-image>
          </div>
          <div class="area-right">
            <div class="clubName">
              {{ item.clubName }}
            </div>
            <div class="clubLocal">
              {{ item.address.substr(0,6) }}
            </div>
            <div class="clubRoles">
              你是此俱乐部的：{{ item.clubMemberRole===3 ? "创建者/管理员" : ""}}{{item.clubMemberRole===2 ? "管理员" : ""}}
            </div>
          </div>
        </div>
      </div>
      <div class="submit-area">
        <button class="submit" type="button" @click="submitForm">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import iconFont from "./IconFont.vue";
export default {
  data() {
    return {
      loading: false,
      choiceactivityType: window.localStorage.getItem('clubId'),
      list: [],
      btnDisabled: false,
      zoomStyle: {
        transform: "",
      },
      scale: "scale(1.2, 1.2)",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rescreening: {
      type: Boolean,
      default: false,
    },
    // openButton: {
    //   type: Boolean,
    //   default: false,      
    // }
  },
  components: {
    iconFont,
  },
  watch: {
    show(n) {
      if (n) {
        // 当展示遮罩的时候，设置scale为1，达到缩小(原来为1.2)的效果
        this.zoomStyle.transform = "scale(1, 1)";
        this.getClubInfo()
      } else if (!n) {
        // 当隐藏遮罩的时候，设置scale为1.2，达到放大(因为显示遮罩时已重置为1)的效果
        this.zoomStyle.transform = this.scale;
      }
    },
  },
  mounted(){
    // this.getClubInfo()
    this.$watch('rescreening',res=>{
      if(res===true){
        this.getClubInfo()
      }
    })
    },
  computed: {
    maskStyle() {
      let style = {};
      style.backgroundColor = "rgba(0, 0, 0, 0.6)";
      if (this.show) style.zIndex = 99;
      else style.zIndex = -1;
      style.transition = `all ${300 / 1000}s ease-in-out`;
      return style;
    },
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit("close");
    },
    async submitForm(){
      if(this.choiceactivityType==''){
        this.$message.warning("请先选择俱乐部")
        return
      }
			if(window.localStorage.getItem('clubId') == this.choiceactivityType) {
				this.$emit("close");
				return
			}
      let data = {
        commonId: this.choiceactivityType
      }
      await this.$request.post("/latteRoleInfoRelation/chooseClub",data)
			window.localStorage.setItem('clubId', this.choiceactivityType)
      this.$router.push({name: 'ClubDetail', params: {clubId: this.choiceactivityType}})
      this.$router.go(0);
      this.$emit("close");
    },
    // 选择俱乐部
    choiceType(data) {
      this.choiceactivityType = data.id
    },
    // 获取管理俱乐部信息
    async getClubInfo() {
        this.loading = true
        try {
          if(!this.rescreening){
            let res = await this.$request.get("/latteRoleInfoRelation/clubListCanBeManaged")
            this.list = res.data
            this.loading = false
          }else{
            let res = await this.$request.get(`/latteRoleInfoRelation/clubListCanBeManaged?type=1`)
            this.list = res.data
            this.loading = false
          }
        } catch (error) {
          //
        }
        this.loading = false
    },	

  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: transform 0.3s;
  .login-wraps {
    width: 480px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 17px;
    box-sizing: border-box;
  }
  .title-line {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .login-text{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 25px;
      padding-top: 3px;
    }
    .close {
      position: absolute;
      top: 2px;
      right: 2px;
      cursor: pointer;
    }
  }
  .login-area {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
    .login-box{
      width: 330px;
      height: 90px;
      background: #F5F5F5;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      // justify-content: space-around;
      box-sizing: border-box;
      margin-bottom: 10px;
      cursor: pointer;
      .area-left{
        margin-right: 15px;
      }
      .area-right{
        .clubName{
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #726a6a;
          line-height: 20px;
          margin-bottom: 5px;
        }
        .clubLocal{
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #444444;
          line-height: 17px;
          margin-bottom: 5px;
        }
        .clubRoles{
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #444444;
          line-height: 17px;
        }
      }
    }
  }
  .submit-area {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .submit {
      width: 100px;
      height: 30px;
      background: #1465CF;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 20px;
      transition: all 0.2s;
    }
    button:active {
      opacity: 0.5;
    }
  }
}
.show {
  opacity: 1;
}

.current {
  background-color: #EDF5FF !important;
}

.zoom {
  transform: scale(1.2, 1.2);
}
</style>