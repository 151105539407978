<template>
	<div class="box-card"  v-if="flag">
		<span class="box-view-top" @click.stop>
			<div class="title">
				<el-image
				class="close" 
				:src="require('@/assets/Qrcode.png')"
				style="width:180px;height:180px;"
				fit="cover"
				@click="close"
        ></el-image>
			</div>
			<div class="introduction">
				<div class="introduction-text">扫码下载LattePlus APP</div>
			</div>
			<div class="confirms" @click="close">
				<div class="confirms-text">关闭</div>
			</div>		
		</span>
	</div>
</template>

<script>
export default {
	props:{
		flag:{
			type: Boolean,
			default: false
		},
	},

	methods:{
		//关闭投诉框
		close(){
			this.$emit('close')
		},
	}
}
</script>

<style lang="scss" scoped>
	.box-card{
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .3);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.box-view-top{
			width: 330px;
			height: 330px;
			background: #FFFFFF;
			border-radius: 10px;
			overflow: hidden;
			position: relative;
			// margin-bottom: 58px;
			// box-sizing: border-box;
			.title{
				margin-top: 15px;
				margin-left: -115px;
				box-sizing: border-box;
				.close{
					width: 15px;
					height: 15px;
					// margin-left: 195px;
					// margin-top: 25px;
					cursor: pointer;
				}
			}
			.introduction{
				// box-sizing: border-box;
				font-size: 16px;
				font-weight: 400;
				color: #000000;
				line-height: 22px;
				display: block;
				text-align: center;
				margin-right: 40px;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			.confirms{
				display: flex;
				align-content: center;
				justify-content: center;
				cursor: pointer;				
				margin-top: 10px;
				border-top: 1px solid #dddddd;
				.confirms-text{
					width: 32px;
					height: 22px;
					font-size: 16px;
					font-weight: 400;
					color: #1465CF;
					line-height: 22px;
					padding-top: 12px;
				}
			}
		}
	}
	.box-click {
		background: #EDF5FF;
		color: #1465CF !important;
	}
</style>
