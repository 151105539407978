<template>
  <div class="club-moment-page">
    <div style="width: 790px; height: 287px; background: #FFFFFF; border-radius: 10px; margin-bottom: 15px;">
      <club-info :detail="clubDetail" :myRole="myRole" :clubRoles="clubRoles" @refresh="getClubDetail"></club-info>
    </div>
    <club-nav :current="1"></club-nav>
    <div v-for="item in list" :key="item.viewId">
      <dynamic-card :item="item" v-if="item.newsId" :isClubNews="true" :canEditNews="roles ? roles.canEditNews : false"></dynamic-card>
    </div>
    
    <div class="empty" v-if="isEmpty">
      <div class="empty-title">暂无动态</div>
    </div>
  </div>
</template>

<script>
import clubInfo from '../../components/club/club-info.vue'
import clubNav from '../../components/club/club-nav.vue'
import dynamicCard from '../../components/dynamic-card.vue'
export default {
  data() {
    return {
      list: [],
      pageIndex: 1,
      pageSize: 10,
      nomore: false,
      isEmpty: false,
      isLoading: false,
      clubDetail: null,
      clubRoles: [],
      myRole: 0,
    }
  },

  components: {
    clubNav,
    dynamicCard,
    clubInfo
  },

  computed:{
    //这里需要把store 动态的数据放到computed里面才会同步更新 视图
    roles(){
      return this.$store.state.clubRoles
    }
  },

  created() {
    this.getMyClubRole()
    this.getClubDetail()
    this.getClubHomeNews()
    window.addEventListener("scroll",this.scroll); 
  },

  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },

  methods: {
    refresh() {
      this.pageIndex = 1,
      this.pageSize = 10,
      this.nomore = false,
      this.getClubHomeNews(true)
    },

    // 获取俱乐部主页动态信息
    async getClubHomeNews(isRefresh = false) {
      this.isLoading = true
      try {
        let res = await this.$request.get(`/home/clubHomeNews?clubId=${this.$route.params.clubId}&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`)
        if(res.page.totalCount == 0) {
          this.isEmpty = true
        }
        else {
          this.isEmpty = false
          let data = res.page.list
          // 循环遍历，绑定唯一id
          for(let item of data){
            item.viewId = `follow${item.newsId}`
          }
          if(isRefresh) {
            this.list = data
          } else {
            this.list = [...this.list, ...data]
          }
          if(res.page.totalPage == res.page.pageIndex) {
            this.nomore = true
          }
          else {
            this.nomore = false
            this.pageIndex++
          }
        }
      } catch (error) {
        // console.log('err-------', error)
      }
      this.isLoading = false
    },

    // 获取俱乐部信息
    async getClubDetail() {
      try {
        let res = await this.$request.get(`/clubInfo/selectByClubId/${this.$route.params.clubId}`)
        this.clubDetail = res.data
      }
      catch (e) {
        //
      }
    },

    // 获取用户在俱乐部的权限
    async getMyClubRole() {
      try {
        let res = await this.$request.get(`/clubMemberInfo/getMyRole/${this.$route.params.clubId}`)
        let data = {
          clubId: this.$route.params.clubId,
          role: 0,
          canEditNews: false,
          canEditActivity: false,
          canEditMember: false,
          canEditTheme: false,
          canEditData: false,
          canEditClub: false
        }
        if(res.data.role) {
          this.myRole = res.data.role
          this.clubRoles = res.data.roles
          data.role = res.data.role
          for(let i = 0; i < res.data.roles.length; i++) {
            if(res.data.roles[i].roleId == 2) {
              // 俱乐部动态管理
              data.canEditNews = true
            }
            else if(res.data.roles[i].roleId == 3) {
              // 俱乐部活动管理
              data.canEditActivity = true
            }
            else if(res.data.roles[i].roleId == 4) {
              // 俱乐部成员管理
              data.canEditMember = true
            }
            else if(res.data.roles[i].roleId == 5) {
              // 俱乐部相册管理
              data.canEditTheme = true
            }
            else if(res.data.roles[i].roleId == 6) {
              // 编辑俱乐部信息
              data.canEditClub = true

            }
            else if(res.data.roles[i].roleId == 7) {
              // 查看俱乐部数据
              data.canEditData = true
            }
          }
        }
        this.$store.commit('SET_CLUBROLES', data)
      } catch (error) {
        //
      }
    },
    //监听页面滚动
    scroll() {
      // 距离底部50px时加载一次
      let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 50
      if (bottomOfWindow && !this.isLoading && !this.nomore && !this.isEmpty) {
        this.getClubHomeNews()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.empty {
  width: 790px;
  height: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  .empty-title {
    color: #444;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>