<template>
	<div class="index-footer">
		<div class="footer-left">
			<img class="logo" src="../assets/imlatte-logo.png" alt="LattePlusLogo">
			<div class="left-text">
				<p class="c-name">深圳市毕宿五网络科技有限公司</p>
				<p><a class="beian" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2020129504号</a></p>
			</div>
		</div>
		<div class="footer-right">
			<p class="right-text">
				<span class="text show" @click="goAgreement">用户协议</span>
				<span class="text show" @click="Privacy">隐私政策</span>
				<span class="text" @click="goAbout">联系我们</span>
			</p>
			<p class="right-beian">
				<img src="../assets/gongan.png" alt="beianLogo">
				<a class="record" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006723">粤公网安备44030502006723号</a>
			</p>
		</div>
	</div>
</template>

<script>
	export default{
		name:'Footer',
		data(){
			return{
				
			}
		},
		methods:{
			goAgreement(){
				this.$router.push('/Agreement');
			},
			Privacy(){
				this.$router.push('/Privacy');
			},
			//跳转About
			goAbout() {
				this.$router.push('/About');
			},
		}
	}
</script>

<style lang="scss" scoped>
	.index-footer {
		width: 100%;
		height: 120px;
		background: #333333;
		font-size: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		.footer-left{
			display: flex;
			margin-right: 50px;
			.logo{
				width: 16px;
				height: 16px;
				margin-right: 10px;
				margin-top: 3px;
			}
			.left-text{
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				.c-name{
					color: #fff;
					font-size: 14px;
					font-weight: bold;
				}
				.beian{
					color: #fff !important;
					font-size: 12px;
				}
			}
		}
		.footer-right{
			height: 48px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			.right-text{
				color: #fff;
				font-size: 12px;
			}
			.text{
				cursor: pointer;
				transition: all 0.2s;
				padding-right: 10px;
				position: relative;
			}
			.show:before {
				content: '';
				position: absolute;
				top: 3px;
				right: 5px;
				width: 1px;
				height: 12px;
				background-color: #fff;
			}
			.right-beian{
				color: #fff;
				font-size: 12px;
				display: flex;
				align-items: center;
				.record{
					color: #fff;
					margin-left: 5px;
				}
			}
			.record:hover,
			.text:hover {
				color: #fff;
			}
		}
	}
</style>