<template>
    <div class="content">
        <div class="club-top">
            <div class="club-top-title">
                <div>俱乐部</div>
            </div>
            <div class="club-top-label">
                <span class="top-label" v-if="!isMyNull" @click="goto(-1)" :class="this.current===-1 ? 'active' : ''">我的俱乐部</span>
                <span class="top-label" @click="goto(1)" :class="this.current===1 ? 'active' : ''">已加入</span>
                <span class="top-label" @click="goto(0)" :class="this.current===0 ? 'active' : ''">已关注</span>
            </div>
            <div class="concerned" v-if="current===-1 && !isMyNull" v-loading="loading">
                <div class="club-top-content" >
                    <div class="top-contents" v-for="(item,index) in myClubObj.list" :key="index" @click="gotoRouter(item.id)">
                        <div class="contents-bg">
                            <el-image class="club-img" fit="cover" :src="$cosUrl + item.url"></el-image>
                        </div>
                        <div class="contents-detail">
                            <div class="contents-avatar">
                                <el-image class="club-avatar" :src="$cosUrl + item.url" style="width: 50px;height: 50px;border-radius: 10px;"></el-image>
                            </div>
                            <div class="contents-details">
                                <div class="contents-avatar">{{ item.clubName }}</div>
                                <span class="contents-member">成员 {{item.memberCount}} </span>
                                <span class="contents-fans">粉丝 {{item.fansCount}}</span>
                            </div>                        
                        </div>
                        <div class="img-label">{{ item.labelNames }}</div>
                    </div>
                </div>
                <div class="default-page" v-if="isMyNull">
                    <div class="def-avatar">
                    <el-image
                        fit="cover"
                        :src="require('@/assets/def.png')"
                    ></el-image>                        
                    </div>
                    <div class="default-text">还没有关注任何俱乐部哦~</div>
                </div>
            </div>
            <div class="concerned" v-if="current===0" v-loading="loading">
                <div class="club-top-content" v-if="!isFollowNull">
                    <div class="top-contents" v-for="(item,index) in myFollowObj.list" :key="index" @click="gotoRouter(item.id)">
                        <div class="contents-bg">
                            <el-image class="club-img" fit="cover" :src="$cosUrl + item.url"></el-image>
                        </div>
                        <div class="contents-detail">
                            <div class="contents-avatar">
                                <el-image class="club-avatar" :src="$cosUrl + item.url" style="width: 50px;height: 50px;border-radius: 10px;"></el-image>
                            </div>
                            <div class="contents-details">
                                <div class="contents-avatar">{{ item.clubName }}</div>
                                <span class="contents-member">成员 {{item.memberCount}} </span>
                                <span class="contents-fans">粉丝 {{item.fansCount}}</span>
                            </div>                        
                        </div>
                        <div class="img-label">{{ item.labelNames }}</div>
                    </div>
                </div>
                <div class="default-page" v-if="isFollowNull">
                    <div class="def-avatar">
                    <el-image
                        fit="cover"
                        :src="require('@/assets/def.png')"
                    ></el-image>                        
                    </div>
                    <div class="default-text">还没有关注任何俱乐部哦~</div>
                </div>
            </div>
            <div class="concerned" v-if="current===1" v-loading="loading">
                <div class="club-top-content" v-if="!isJoinNull">
                    <div class="top-contents" v-for="(item,index) in joinClub.list" :key="index" @click="gotoRouter(item.id)">
                        <div class="contents-bg">
                            <el-image class="club-img" fit="cover" :src="$cosUrl + item.url"></el-image>
                        </div>
                        <div class="contents-detail">
                            <div class="contents-avatar">
                                <el-image class="club-avatar" :src="$cosUrl + item.url" style="border-radius: 10px;"></el-image>
                            </div>
                            <div class="contents-details">
                                <div class="contents-avatar">{{ item.clubName }}</div>
                                <span class="contents-member">成员 {{item.memberCount}} </span>
                                <span class="contents-fans">粉丝 {{item.fansCount}}</span>
                            </div>                        
                        </div>
                        <div class="img-label">{{ item.labelNames }}</div>
                    </div>
                </div>
                <div class="default-page" v-if="isJoinNull">
                    <div class="def-avatar">
                    <el-image
                        fit="cover"
                        :src="require('@/assets/def.png')"
                    ></el-image>                        
                    </div>
                    <div class="default-text">还没有加入任何俱乐部哦~</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return{
                myClubObj: {					// 我管理的俱乐部
					pageIndex: 1,
					nomore: false,				
					list: []
                },
                myFollowObj: {					// 我关注的俱乐部
					pageIndex: 1,
					nomore: false,				
					list: []
                },
                joinClub:{                      // 我加入的俱乐部
					pageIndex: 1,
					nomore: false,				
					list: []
                },
                currentClubObj:{
					pageIndex: 1,
					nomore: false,				// 俱乐部信息
					list: []
                },                                                    
                totalList1:[],
                totalList2:[],
                totalList3:[],
                hotLabel:[],
                sonLabel:[
                    // '六人制橄榄球',
                    // '三人制橄榄球'
                ],
                current: -1,                    // 默认页面
                isFollowNull: false,            // 已关注俱乐部数据是否为空    
                isJoinNull: false,              // 已加入俱乐部数据是否为空
                isMyNull: false,              // 我的俱乐部数据是否为空
                isMyClubNull: false,            // 已加入俱乐部数据是否为空   
                isOurNull: false,               // 我的俱乐部数据是否为空 
                latteId: null, 
                isNull: false, 
                loading: false,                 // 是否默认加载 
                loadings: false,                // 是否默认加载 
                searchClub: '',   
                selectList: [],                 // 查询结果 
                selectAll: false,               // 部分展示   
                currentId: null,                // 当前全部标签
                currentIds: 2,                  // 当前热门标签  
                allStatus: true,                // 判断查询全部还是具体标签  
            }
        },
        mounted(){
            this.getMyClub()
            this.getAttentionClub()
            this.findHotLabel()
            this.clubInitialization()
            this.joinedClub()
        },
        methods:{
            async getAttentionClub(){
                this.loading = true
                try{
                    let res = await this.$request.get(`/clubInfo/selectMyClub?type=2&pageIndex=${this.myFollowObj.pageIndex}&pageSize=1000`)
                    if( res.code === 200 && res.page.totalCount != 0){
                        this.myFollowObj.list = res.page.list
                        this.totalList1 = res.page
                    }else{
                        this.isFollowNull = true
                    }
                }catch(e) {
					//
                }
                this.loading = false
            },

            async getMyClub(){
                this.loading = true
                try{
                    let res = await this.$request.get(`/clubInfo/selectMyClub?type=3&pageIndex=${this.myFollowObj.pageIndex}&pageSize=1000`)
                    if( res.code === 200){
                    if(res.page.list.length ==0){
                        this.current = 1
                        this.isMyNull = true
                    }
                    this.myClubObj.list = res.page.list
                    this.totalList1 = res.page
                    }else{
                        this.isMyClubNull = true
                    }
                }catch(e) {
					//
                }
                this.loading = false
            },

            //俱乐部分页
            async handleCurrentChange1(val){
                this.myFollowObj.pageIndex = val
                this.getAttentionClub()
            },

            //加入俱乐部分页
            async handleCurrentChange3(val){
                this.joinClub.pageIndex = val
                this.joinedClub()
            },

            //发现俱乐部分页
            async handleCurrentChange2(val){
                this.currentClubObj.pageIndex = val
                this.getAllClub()
                if(this.allStatus){
                    this.getAllClub()
                }else{
                    this.openAll()
                }
            },

            //查找热门标签
            async findHotLabel() {
                try {
                    let res = await this.$request.get('/labelInfo/labelInfoList?type=ACTIVITY_LABEL_SCORE')
                    this.hotLabel = res.data[0].labelInfoResponseVoList
                    this.sonLabel = res.data[0].labelInfoResponseVoList[0].labelInfoResponseVoList
                    this.currentIds = this.currentId = this.hotLabel[0].id
                } catch(e) {
									//
                }
            },

            //查找子标签
            findSonLabel(index,id) {
                this.sonLabel = this.hotLabel[index].labelInfoResponseVoList
                this.currentIds = id
                this.getAllClub(id)
            },

            //根据路由跳转
            goto(e){
                if (e===-1) {
                    this.current = -1
                }
                if (e===0) {
                    this.current = 0
                }
                else if (e===1) {
                    this.current = 1
                }
                // else {
                //     this.current = 2
                // }
            },

            //查找已加入的俱乐部
            async joinedClub(){
                this.loading = true
                try {
                    let res = await this.$request.get(`/clubInfo/selectMyClub?type=4&pageIndex=${this.joinClub.pageIndex}&pageSize=1000`)
                    if ( res.code === 200 && res.page.totalCount != 0) {
                    this.joinClub.list = res.page.list
                    this.totalList3 = res.page                   
                    } else {
                        this.isJoinNull = true
                    }
                } catch(e) {
									//
								}
                this.loading = false  
            },

            //路由跳转
            async gotoRouter(e){
                // this.$router.push({name: 'ClubDetail', params: {clubId: e}})
                let routeData = this.$router.resolve({name: 'ClubDetail', params: {clubId: e}});
                window.open(routeData.href, '_blank');
            },

            open(){
                this.$alert('敬请期待', '地区筛选', {
                    confirmButtonText: '确定',
                    customClass:'message_box_alert'
                });
            },

            // 初始化当前标签的数据
            async clubInitialization(){
                this.currentIds = 2
                this.openAll();
            },

            // 根据标签ID获取对应俱乐部
            async getAllClub(id){
                this.loadings = true
                this.currentId = id
                if(id){
                this.latteId = id 
                }
                if(!this.latteId) return
                let res = await this.$request.get(`/clubInfo/allClub?pageIndex=${this.currentClubObj.pageIndex}&pageSize=6&labelId=${this.latteId}`)
                if(res.code === 200 && res.page.totalCount != 0){
                    this.currentClubObj.list = res.page.list
                    this.totalList2 = res.page
                    this.isNull = false
                    this.allStatus = true
                }else{
                    this.isNull = true
                }
                this.loadings = false
                
            },

            //根据俱乐部名字搜索
            async selectClub(data){   
                this.loadings = true   
                let bool = this.$test.empty(data)
                if(!bool){
                    let res = await this.$request.get(`/clubInfo/selectClub/${data}`)
                    if(res.code === 200 && res.data.length!=0){
                        this.currentClubObj.list = res.data
                        this.currentId = null
                        this.currentIds = null
                    }else{
                        this.$message.error("没有查到相关的俱乐部资料呢！")
                    }
                }else{
                    this.$message.error("请输入正确的格式!")
                }     
                this.loadings = false
            },

            //展示全部标签
            async openAll(){
                this.loadings = true
                if(!this.currentIds){
                    this.loadings = false
                    this.$message.error("请选择标签！")
                }
                let id = this.currentIds
                this.currentId = this.currentIds
                let res = await this.$request.get(`/clubInfo/allClub?pageIndex=${this.currentClubObj.pageIndex}&pageSize=6&labelId=${id}`)
                if(res.code === 200 && res.page.totalCount != 0){
                    this.currentClubObj.list = res.page.list
                    this.totalList2 = res.page
                    this.isNull = false
                    this.allStatus = false
                }else{
                    this.isNull = true
                    this.loadings = false 
                }
                this.loadings = false
            },
        }
    }
</script>

<style lang="scss">
    .content{
        width: 1019px;
        // margin-top: 20px;
        .club-top{
            // margin-top: 20px;
            //修改页面抖动
            // width: 1728px;
            // margin: 20px 385px;
            .club-top-title{
                width: 102px;
                height: 48px;
                font-size: 34px;
                font-weight: 500;
                color: #000000;
                line-height: 48px;
            }
            .club-top-label{
                margin: 20px 0 20px 0;
                // margin: 20px 0;
                .top-label{
                    width: 72px;
                    height: 33px;
                    font-size: 24px;
                    font-weight: 500;
                    color:#888888;
                    line-height: 33px;
                    margin-right: 30px;
                    cursor: pointer;
                }
              .top-label:hover{
                color: #1465CF;
              }
            }
            .concerned{
                .club-top-content{
                    display: flex;
                    flex-wrap: wrap;
                    width: 1020px;
                    // height: 492px;
                    .top-contents:hover {
                        transform: scale(1.02);
                        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
                    }
                    .top-contents{
                        width: 500px;
                        height: 300px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        position: relative;
                        cursor: pointer;
                        transition: all ease 0.2s;
                        .contents-bg{
                            .club-img{
                                width: 500px;
                                height: 200px;
                                border-top-left-radius: 10px; 
                                border-top-right-radius: 10px;
                            }
                        }
                        .contents-detail{
                            display: flex;
                            flex-direction: row;
                            .contents-avatar{
                                margin: 21px 15px;
                                .club-avatar{
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                            .contents-details{
                                .contents-avatar{
                                    // width: 144px;
                                    // height: 22px;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #444444;
                                    line-height: 22px;
                                    margin-bottom: 8px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    max-width: 300px;
                                }
                                .contents-member{
                                    width: 67px;
                                    height: 20px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #888888;
                                    line-height: 20px;
                                    margin-right: 30px;
                                    margin-left: 15px;
                                }
                                .contents-fans{
                                    width: 67px;
                                    height: 20px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #888888;
                                    line-height: 20px;
                                }
                            }
                        }
                        .img-label{
                            position: absolute;
                            right: 0;
                            bottom: 35px;
                            margin-right: 10px;
                            background: #1465CF;
                            border-radius: 15px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 22px;
                            padding: 3px 10px;
                        }
                    }
                    .top-contents:nth-child(2n)
                    {
                        margin-left: 20px;
                    }
                }
                .default-page{
                    width: 1020px;
                    height: 472px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    .def-avatar{
                        width: 50px;
                        height: 50px;
                        padding: 186px 485px 236px 485px;
                    }
                    .default-text{
                        width: 216px;
                        height: 25px;
                        font-size: 18px;
                        font-weight: 400;
                        color: #444444;
                        line-height: 25px;
                        margin: -210px 402px 100px 402px;
                    }
                } 
            }
        }
        .elpagination{
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;
            //修改页面抖动
            // margin: 20px 445px;
        }
        .club-bottom{
            // margin-bottom: 192px;
             //修改页面抖动
            // margin: 100px 385px 100px 385px;        
            .club-bottom-titles{
                margin-bottom: 10px;
                .club-bottom-title{
                    width: 170px;
                    height: 48px;
                    font-size: 34px;
                    font-weight: 500;
                    color: #000000;
                    line-height: 48px;
                    margin: 80px 0 10px 0;
                }
                }
            .club-bottom-top{
                width: 1019px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-bottom: 10px;
                .club-bottom-middle{
                    .select-region{
                        display: flex;
                        flex-direction: row;
                        align-content: center;   
                        padding: 20px;                    
                        .region{
                            width: 73px;
                            height: 35px;                            
                            border-radius: 5px;
                            border: 1px solid #A8A8A8;
                            margin-right: 20px;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            cursor: pointer;
                            .region-text{
                                width: 32px;
                                height: 22px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #444444;
                                line-height: 22px;
                                margin-top: 7px;
                                margin-right: 5px;
                            }
                            .region-img{
                                margin-top: 5px;
                                width: 18px;
                                height: 18px;
                            }
                        }
                        .search {
                            width: 330px;
                            height: 35px;
                            background: #F5F5F5;
                            border-radius: 25px;
                            padding: 0 12px;
                            display: flex;
                            align-items: center;
                            .search-input {
                                height: 100%;
                                width: 100%;
                                font-size: 14px;
                                font-weight: 400;
                                color: #888888;
                                line-height: 20px;
                                padding-left: 10px;
                                background: #F5F5F5;
                                border-radius: 5px;
                                border: none;
                                outline: none;
                            }
                        }
                    }
                    .select-hot{
                        border-bottom: 1px solid #DDDDDD;
                        padding: 5px 20px 15px 20px;
                        .label{
                            width: 32px;
                            height: 22px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #444444;
                            line-height: 22px;
                            margin-right: 50px;
                            cursor: pointer;
                        }
                    }
                    .select-all{
                        padding: 20px 5px 5px 20px;
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        .all{
                            display: inline-block;
                            width: 33px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #444444;
                            line-height: 22px;
                            cursor: pointer;
                        }
                        .label-box{
                            display: inline-block;
                            width: 840px;
                            margin-left: 50px;
                            // margin-bottom: 50px;
                            // padding-bottom: 50px;
                            display: flex;
                            flex-direction: row;
                            align-content: center;
                            flex-wrap: wrap;                            
                            .label{
                                width: auto;
                                height: 22px;
                                font-size: 16px;
                                font-weight: 500;
                                color: #444444;
                                line-height: 22px;
                                margin-right: 50px;
                                cursor: pointer;
                                display: inline-block;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }                
                }
            .club-bottom-content{
                display: flex;
                flex-wrap: wrap;
                width: 1019px;
                height: 492px;
                .club-content{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .top-contents:hover {
                        transform: scale(1.02);
                    }
                    .top-contents{
                        width: 325px;
                        height: 226px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
                        border-radius: 5px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        position: relative;
                        cursor: pointer;
                        transition: all ease 0.2s;
                        .contents-bg{
                            .club-img{
                                width: 325px;
                                height: 129px;
                            }
                        }
                        .contents-detail{
                            display: flex;
                            flex-direction: row;
                            .contents-avatar{
                                margin: 15px 15px;
                                .club-avatar{
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                            .contents-details{
                                .contents-avatar{
                                    width: 144px;
                                    height: 22px;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #444444;
                                    line-height: 22px;
                                }
                                .contents-member{
                                    width: 67px;
                                    height: 20px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #888888;
                                    line-height: 20px;
                                    margin-right: 30px;
                                    margin-left: 15px;
                                }
                                .contents-fans{
                                    width: 67px;
                                    height: 20px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #888888;
                                    line-height: 20px;
                                }
                            }
                        }
                        .img-label{
                            position: absolute;
                            right: 0;
                            top: 15px;
                            margin-right: 10px;
                            background: #EDF5FF;
                            border-radius: 15px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #1465CF;
                            line-height: 20px;
                            padding: 3px 10px;
                        }
                        .view-label{
                            position: absolute;
                            left: 0;
                            top: 15px;
                            margin-left: 10px; 
                            background: #000000;
                            border-radius: 5px;
                            opacity: 0.59;
                            font-size: 12px;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 17px; 
                            padding: 4px 10px;                                                      
                        }                          
                    }
                    .top-contents:nth-child(2),
                    .top-contents:nth-child(5)
                    {
                        margin-right: 22px;
                        margin-left: 22px;
                    }                    
                }    
                .default-page{
                    width: 1020px;
                    height: 472px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    .def-avatar{
                        width: 50px;
                        height: 50px;
                        padding: 186px 485px 236px 485px;
                    }
                    .default-text{
                        width: 216px;
                        height: 25px;
                        font-size: 18px;
                        font-weight: 400;
                        color: #444444;
                        line-height: 25px;
                        margin: -210px 402px 100px 402px;
                    }
                } 
                .elpagination{
                    display: flex;
                    justify-content: flex-end;
                    // margin-top: 15px;
                    width: 100%;

                    //修改抖动页面
                    // margin-top: 20px;
                    // margin-right: -20px;
                }               
            }
        } 
        .elpagination{
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;
            //修改页面抖动
            // margin: 20px 700px;           
        }
    .active{
        color: #333333 !important;
    }      
    }
    .current{
        color: #1465CF !important;
    }
    .message_box_alert{
        width: 416px;
        height: 186px;
        background: #FFFFFF;
        border-radius: 5px;
    }
    .el-button--primary{
        width: 76px;
        height: 32px;
        background: #1465CF;
        border-radius: 5px;
        margin-right: 145px;
        margin-top: 20px;
    }
    .el-message-box__title{
        width: 64px;
        height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: #444444;
        line-height: 22px;
        margin-left: 170px;
        margin-top: 15px;
    }
    .el-message-box__message{
        p{
            width: 64px;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            color: #444444;
            line-height: 22px;
            margin-left: 170px;
            margin-top: 15px;            
        }
    }
    .el-pager li {
    padding: 0 4px;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    text-align: center;
    background: #F7F8FA !important;
}
  
</style>>

