import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global from './utils/global.js'
import uploadCredential from './utils/uploadCredential.js'
import uploadFile from './utils/uploadFile.js'
import request from './utils/request.js'
import login from './utils/login.js'
import beautifyTime from './utils/beautifyTime.js'
import timeFormat from './utils/timeFormat.js'
import { VueJsonp } from 'vue-jsonp'
import './utils/iconfont/iconfont.css'
import Clickoutside from '@/utils/clickoutside'
let echarts = require('echarts');
import test from './utils/test.js'

import dealTime from './utils/dealTime.js'

import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
//样式
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'

import 'tinymce/icons/default/icons'
//主题
import 'tinymce/themes/silver'

//插件
import 'tinymce/plugins/link' //链接插件
import 'tinymce/plugins/image' //图片插件
import 'tinymce/plugins/media' //媒体插件
import 'tinymce/plugins/table' //表格插件
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/quickbars' //快速栏插件
import 'tinymce/plugins/fullscreen' //全屏插件
Vue.prototype.$tinymce = tinymce        // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce)                     // 安装vue的tinymce组件
import '../public/tinymce/langs/zh_CN.js'

import ElementUI from 'element-ui'
import { message } from './utils/onlyMsgbox'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/reset.css'


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueJsonp)

Vue.prototype.$message = message
Vue.prototype.$global = global
Vue.prototype.$credential = uploadCredential
Vue.prototype.$uploadFile = uploadFile
Vue.prototype.$request = request
Vue.prototype.$login = login
Vue.prototype.$beautifyTime = beautifyTime
Vue.prototype.$timeFormat = timeFormat
Vue.prototype.$cosUrl = process.env.COS_CDN_URL_PREFIX
Vue.prototype.$test = test
Vue.prototype.$echarts = echarts
Vue.prototype.$eventBus = new Vue()

Vue.prototype.$dealTime = dealTime

export const eventBus = new Vue()

Vue.directive(Clickoutside)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')