<template>
  <div class="content">
    <div class="big-box" v-loading="loading">
      <div class="content-box" @click="toDetailPages(item)" v-for="(item,index) in list" :key="index">
        <div class="more-video" v-if="item.types==2"  style="z-index: 10">
          <el-image
          class="el-image-more"
          :src="require('@/assets/signVideo.png')"
          fit="cover">
          </el-image>
        </div>
        <div class="more-photo" v-if="item.types==1" style="z-index: 10" >
          <el-image
          class="el-image-more"
          :src="require('@/assets/signPhoto.png')"
          fit="cover"
          ></el-image>
        </div>
        <div class="top-photo" v-if="item.urlResponseVoList[0].url.indexOf('.mp4')!=-1">
          <el-image class="body-img" fit="cover" :src="$cosUrl + item.urlResponseVoList[0].cover" alt="" style="border-top-left-radius:10px;
          border-top-right-radius:10px;height: 290px;width: 100%">
            <div slot="error" class="image-slot" style="padding-bottom: 150px">
            <el-image 
            :src=" imgUrl "
            fit="contain" 
            style="width: 220px;height: 390px;border-radius: 50%;margin-bottom: 150px"
            ></el-image>
            </div>
          </el-image>
        </div>      
        <div class="top-photo" v-else>
          <el-image class="body-img" fit="cover" :src="$cosUrl + item.urlResponseVoList[0].url" alt="" style="border-top-left-radius:10px;
          border-top-right-radius:10px;height: 290px;width: 100%">
            <div slot="error" class="image-slot" style="padding-bottom: 150px">
            <el-image 
            :src=" imgUrl "
            fit="contain" 
            style="width: 220px;height: 390px;border-radius: 50%;margin-bottom: 150px"
            ></el-image>
            </div>
          </el-image>
        </div>      
        <div class="bottom-desc">
          <div class="desc-text">
            {{ item.title }}
          </div>
        </div>
        <div class="bottom-box">
          <div class="bottom-left">
            <div class="avatar">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="30"></img-head>	
            </div>
            <div class="nickname">
              <div class="nickname-text">{{ item.nickname }}</div>
            </div>
          </div>
          <div class="bottom-right">
            <div class="func-icon" >
              <icon-font class="liked" name="heart" size="15px" color="#444444" v-if="istrue"></icon-font>
              <div class="icon-text">{{ item.likeCount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="checked-more" @click.stop="isshow = !isshow">
      <div class="more-text">
        查看更多
      </div>
    </div>
    <download-app :flag="isshow" @close="closeQRcode"></download-app>
  </div>
</template>

<script>
import iconFont from '../components/IconFont.vue'
import downloadApp from '../components/download-app.vue'
import jpg from "@/assets/error-img.png"
import imgHead from '../components/img-head.vue';
export default {
  data(){
    return{
      imgUrl: jpg,
      istrue: true,
      isshow: false,
      list: [],
      errorImg: require('@/assets/error-img.png') ,				    // 错误图片
			playVideoError:false,																    // 视频加载出错
      loading: false,
    }
  },
  components:{
    iconFont,
    downloadApp,
    imgHead
  },
  mounted(){
    this.getRecommond()
    document.addEventListener('click', this.HiddenClick,true)
  },
  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,true)
  },
  methods:{
		//关闭下载二维码
		closeQRcode() {
			this.isshow = false
    },
    // 视频加载出错
		videoError(){
			this.playVideoError = true
    },
		// 取消右键菜单
		clickMenu(){
			return false
    },
		// 违禁视频播放
		errorPlay(){
			this.$message.warning('视频出错了！')
		},
    toDetailPages(item) {
      this.$router.push({name: 'DetailDynamic'})
			this.$store.commit('SET_NEWS_DETAIL', item);
    },  
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      this.isshow = false
    },
    // 获取关注内容
    async getRecommond(){
      this.Loading = true
			try{
        let res = await this.$request.get(`/home/getWebHomeDiscoveryMain`)
				if (res.code === 200) {
          //用types来区分类型 0为默认,1为多图,2为视频
          let arr = []
          let arrs = []
          for(let item of res.data){
            item.types = 0
            arr.push(item)
          }
          for(let item of arr){
            if(item.urlResponseVoList.length>1){
              this.$set(item, "types",1)    
            }
            for(let items of item.urlResponseVoList){
              if(items.url.indexOf('.mp4')!=-1){
                this.$set(item, "types",2)    
              }
            }
            arrs.push(item)
          }
          this.list = arrs
        }
			}
      catch(e){
				//
			}
      this.Loading = false
    },     
  }
}
</script>

<style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    width: 1180px;
    .big-box{
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      .content-box{
        box-sizing: border-box;
        width: 220px;
        height: 390px;
        background-color: #ffffff;
        transition: all ease 0.2s;
        border-radius: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        cursor: pointer;
        .more-photo{
          position: absolute;
          top: 10px;
          right: 10px;
          width: 32px;
          height: 32px;
          z-index: 999;
        }
        .more-video{
          position: absolute;
          top: 10px;
          right: 10px;
          width: 32px;
          height: 32px;
          z-index: 9999;
        }
        .top-video{
            width: 100%;
          height: 100%;
          box-sizing: border-box;
        }
        .top-photo{
          box-sizing: border-box;
          width: 100%;       
          height: 290px;
        }
        .bottom-desc{
          box-sizing: border-box;
          padding: 15px 23px 15px 15px;
          font-size: 14px;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
          font-family: "PingFang SC";
          width: 100%;
          .desc-text{
            height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          } 
        }
        .bottom-box{
          display: flex;
          justify-content: space-between;
          align-content: center;
          padding: 0 15px 15px 10px;
          .bottom-left{
            display: flex;
            justify-content: center;
            align-content: center;
            .avatar{
              display: flex;
              align-content: center;
              margin-right: 11px;
              margin-left: 5px;
              box-sizing: border-box;
            }
            .nickname{
              display: flex;
              align-content: center; 
              box-sizing: border-box;
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #444444;
              line-height: 17px;
              margin-top: 6px;
              width: 100%;
              .nickname-text{
                width: 106px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
            }
          }
          .bottom-right{
            display: flex;
            justify-content: center;
            align-content: center;
            .func-icon{
              display: flex;
              justify-content: center;
              align-content: center;
              .icon-text{
                font-size: 12px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #444444;
                line-height: 17px;
                margin-top: 7px;
                margin-left: 5px;
              }
            }
          }
        }
      }
      .content-box:nth-child(5n){
        margin-right: 0!important;
      }
      .content-box:hover{
        transform: scale(1.02);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
      }
    }
    .checked-more{
      .more-text{
        margin-top: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        color: #1465CF;
        line-height: 22px;
      }
    }
  }
</style>