<template>
  <div class="content-box">
    <div class="dynamic-title">发布动态</div>
    <div class="dynamic-cover">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">添加图片或视频（{{ upImgList.length }}/9）</div>
      </div>
      <div class="upload-cover">
        <div class="tips-pic-main">
          <div class="tips-pic-list" v-for="(item, index) of upImgList" :key="index">
            <el-image class="delete-icon" :src="require('@/assets/delete.png')" fit="contain" @click="delImg(item,index)"></el-image>
            <div class="more-video" v-if="!item.isImgs" style="z-index: 10">
              <el-image class="el-image-more" :src="require('@/assets/signVideo.png')" fit="cover"></el-image>
            </div>
            <el-image class="show-img" :src="item.url" fit="cover" ></el-image>
          </div>
          <div class="tips-pic-list up-pic" @click="selsectPic" v-if="upImgList.length < 9">
            <div class="up-icon iconfont icon-add"></div>
            <div class="upload-text">添加图片或视频</div>
            <input v-show="false" type="file" id="uploadIMG" @change="fileChangeImgs($event)" ref="inputImg" multiple/>
          </div>
        </div>
      </div>
    </div>   
    <div class="dynamic-topics">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">标题</div>        
      </div>
      <div class="topics-input">
        <el-input type="text" :value="dynamicTitle" class="activityTopics" style="width:480px;color: #888888;" @input="themelenMin"  placeholder="填写动态标题"></el-input>
        <span class="text-statistic">{{ titleTextstatistics }}/20</span>
      </div>
    </div> 
    <div class="dynamic-content">
      <div class="title-line">
        <div class="title-text">动态内容</div>
      </div>
      <div class="content-input" :class="showBorder ? 'border' : '' ">
        <el-input type="textarea" :value="dynamicContent" rows="3" resize="none" class="dynamicContent" @blur="closeBorders" @focus="showBorders" @input="introlenMin"  placeholder="描述动态内容会有更多赞哦（选填）" />
        <div class="mychoicelocation"  v-if="choiceAddress">
          <div class="img-location">
            <el-image :src="require('@/assets/location1.png')" fit="cover" style="height: 12px;width: 12px;margin-right: 2px;"></el-image>
          </div>
          <div class="location-texts">{{choiceAddress}}</div>
        </div>
        <span class="text-statistics">
          {{ textstatistics }}/600
        </span>
      </div>
    </div>
    <div class="buttons">
      <el-dropdown trigger='click' @visible-change="openDropdown($event)" :hide-on-click='false'>
          <div class="topics-box" ># 话题</div>
          <el-dropdown-menu slot="dropdown">
            <div  v-for="(item,index) in remmendLabeLsit" :key="index">
              <el-dropdown-item>
                <el-dropdown placement='right-start' trigger="click" >
                  <span class="el-dropdown-link" @click="getTopicType(item)">
                    <i>&nbsp;</i>{{item.labelName }}<i class="el-icon-arrow-right el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div v-for='(items,index) in topicList' :key="index" @click="choiceTopics(items)">
                      <el-dropdown-item ><i>&nbsp;&nbsp;</i># {{ items.labelName? items.labelName : items.topicContent}}<i>&nbsp;&nbsp;</i></el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
      </el-dropdown>
      <div class="location-box" @click.stop="showSearchComponent"> 
        <img class="location-icon" style="width: 12px;height:12px;" :src="require('@/assets/location2.png')" alt="">
        <div class="location-text">地点</div>
      </div>  
    </div> 
    <div class="searchLocations" v-if="showLocationSearchList">
      <div class="input-dress"  @keyup.enter="searchAddress">
        <div class="searchLocations">
        <search-location :showSearch="true" :showLocationSearchList="showLocationSearchList" :address="choiceAddress" :enterEvents="enterEvents" @show="shows" @chooseAddress="search" @donotClose="donotClose"></search-location>
        </div>
      </div>   
    </div> 
    <div class="permission-setting">
      <div class="title-line">
        <div class="title-text">权限设置</div>
      </div>
      <div class="setting-choice">
        <div class="permit-download">
          <div class="common-text">允许他人下载</div>
          <el-switch v-model="permitDownload"></el-switch>
        </div>
      </div>
    </div>
    <div class="submit-btn" @click="upAllRelease">发布动态</div>
  </div>
</template>



<script>
	import { eventBus } from '../../main.js'
  import searchLocation from '../../components/searchLocation.vue';
  export default {
    data(){
      return{
        upImgList: [], 								       // 上传图片地址
        upImgObj: [], 								       // 保存上传图片对象  
        videoUrl: [], 								       // 上传视频
        vidoeCover: null, 							     // 视频封面
        upVideoObj: null, 						       // 保存上传视频对象
        dynamicTitleLen: null,               // 动态标题长度
        dynamicContentLen: null,             // 动态内容长度
        dynamicTitle: '',                    // 动态标题
        dynamicContent: '',                  // 动态内容
        closeComments: false,                // 关闭评论
        permitDownload: false,               // 允许下载图片
        showLocationSearchList: false,       // 展示搜索组件
        choiceAddress: "",                   // 选择活动地址
        enterEvents: false,                  // 地址组件enter事件
        componentData: [],                   // 组件传递的内容 
        textstatistics: 0,                   // 动态内容文字统计
        titleTextstatistics: 0,              // 动态标题文字统计
        remmendLabeLsit : [                  // 话题列表
          {
            labelName : "推荐",
            childer :[
            ]
          },
        ],
        topicList:{},				                 // 将要展示的话题
        hotTopicList: '',                    // 推荐的热门话题
        showBorder: false,                   // focus时显示边框
        stopFlag: false,
      }
    },

    components:{
      searchLocation,
    },

    async mounted(){
      await this.addActivityType()
      await this.remmendLabe()
      await this.remmendTopic()
      document.addEventListener('click', this.HiddenClick,false)
      this.$watch('upImgList',res=>{
        if(res.length!=0){
          eventBus.$emit('dataChanges',0)
        }else{
          if(this.isNull()){
            eventBus.$emit('dataChanges', 1)
          }
        }
      })
      this.$watch('dynamicTitle',res=>{
        if(res.length!=0){
          eventBus.$emit('dataChanges',0)
        }else{
          if(this.isNull()){
            eventBus.$emit('dataChanges',1)
          }
        }
      })
      this.$watch('dynamicContent',res=>{
        if(res.length!=0){
          eventBus.$emit('dataChanges',0)
        }else{
          if(this.isNull()){
            eventBus.$emit('dataChanges',1)
          }
        }
      })
      this.$watch('choiceAddress',res=>{
        if(res.length!=0){
          eventBus.$emit('dataChanges',0)
        }else{
          if(this.isNull()){
            eventBus.$emit('dataChanges',1)
          }
        }
      })
      this.$watch('closeComments',res=>{
        if(res==true){
          eventBus.$emit('dataChanges',0)
        }else{
          if(this.isNull()){
            eventBus.$emit('dataChanges',1)
          }
        }
      })
      this.$watch('permitDownload',res=>{
        if(res.length==true){
          eventBus.$emit('dataChanges',0)
        }else{
          if(this.isNull()){
            eventBus.$emit('dataChanges',1)
          }
        }
      })
    },

    beforeDestroy(){
      document.removeEventListener('click', this.HiddenClick,false)
    },

    methods:{
      // 点击页面事件 隐藏需要隐藏的区域
      HiddenClick () {
        if(this.showLocationSearchList && this.stopFlag){
          this.showLocationSearchList = false
        }
      },
      
      // 显示搜索组件
      showSearchComponent() {
        this.showLocationSearchList = !this.showLocationSearchList
        this.stopFlag  = true
      },

      // 判断值是否为空
      isNull() {
        if(this.upImgList.length==0 && this.dynamicTitle.length==0 && this.dynamicContent.length==0 && this.choiceAddress.length==0 && this.closeComments==false && this.permitDownload==false ){
          return true
        }
      },

      donotClose() {
        this.stopFlag = false
      },

      // focus使文本高亮
      showBorders () {
        this.showBorder = true
      },

      // blur使文本恢复
      closeBorders () {
        this.showBorder = false
      },

      // 选择上传图片
      selsectPic() {
        this.$refs.inputImg.click()
      },

      // 判断是否是图片
      isPicture(fileName){
        let strFilter = /\.(jpeg|gif|jpg|png|bmp|pic|svg)$/
        if (!strFilter.test(fileName)) {
          return false
        } else {
          return true
        }
      },

      // 视频图片一起2
      async uploadDatas() {
        return new Promise(async (resolve) => {
          let picinfo = {}
          let localImg = this.upImgList
          let myUpList = []
          await this.$credential(2)
          for (let i = 0 ; i< localImg.length; i++) {
            if (localImg[i].isImgs) {
              let res = await this.$uploadFile(2, localImg[i].upFiles)
                picinfo = { url: res, urlHeight: localImg[i].urlHeight, urlWidth: 750,urlSort: null,defaultUrl:2,cover:null}
                myUpList.push(picinfo)
            } else {
              let res = await this.$uploadFile(2, localImg[i].upFiles);
              let coverRes = await this.$uploadFile(2,localImg[i].coverFiles)
              picinfo = { url: res, urlHeight: localImg[i].urlHeight, urlWidth: 750,urlSort: null,defaultUrl:2, cover:coverRes}
              myUpList.push(picinfo)
            }
          }
          resolve(myUpList)
        })
      },

      // 获取选择的视频封面文件2
      getVideoCover(fileUrl){
        return new Promise((resolve) => {
          let that = this
          let coverFiles = null
          var videoObj = document.createElement("video")
          videoObj.src = fileUrl
          videoObj.autoplay = true
          videoObj.muted = true
          videoObj.onloadeddata = () => {
            if(videoObj.duration > 60) {
              this.$message({
                message: '暂不支持1分钟以上的视频',
                type: 'warning'
              })
              return resolve({
                video: null,
                flag: false
              })
            }
            URL.revokeObjectURL(fileUrl)
            let width = videoObj.videoWidth
            let height = videoObj.videoHeight
            let filesHeight = 750 * (height / width)
            var canvas = document.createElement("canvas")
            var ctx = canvas.getContext("2d")
            canvas.width = width
            canvas.height = height
            ctx.drawImage(videoObj, 0, 0, canvas.width, canvas.height)
            let viodeImg = canvas.toDataURL("image/png", 1)
            let img = new Image()
            img.src = canvas.toDataURL("image/png")
            img.onload = async function() {
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
              let name = Math.random() * 1000 + ".png"
              coverFiles = await that.dataURLtoFile(
                canvas.toDataURL("image/png"),
                name
              )
              let vidoeObj = {
                url: viodeImg,
                urlHeight: filesHeight,
                urlWidth: 750,
                urlSort: null,
                defaultUrl: 2,
                cover: fileUrl,
                isImgs:false,
                coverFiles:coverFiles
              }
              return resolve({
                video: vidoeObj,
                flag: true
              })
              }
            }
        })
      },
      
      // 选择图片2
      async fileChangeImgs(event){
        let files = event.target.files
        let upImgNum = []
        let fileLength = files.length
        let upImgLength = this.upImgList.length
        let totalLength = fileLength + upImgLength
        let length = 0
        let num = 0
        if (totalLength > 9) {
          length = 9 - upImgLength
        } else {
          length = fileLength
        }
        let promise = new Promise(async(resolve)=>{
          for (let i = 0; i < length; i++) {
            let fileType = this.isPicture(files[i].name)
            let fileUrl = URL.createObjectURL(files[i])
            if (fileType) { 
              let fileHeight = await this.fileImgInfo(fileUrl)
              upImgNum.push({
                url: fileUrl,
                urlHeight: fileHeight,
                urlWidth: 750,
                urlSort: null,
                defaultUrl: 2,
                isImgs:true,
                upFiles:files[i]
              })
            } else {
              for(let item of this.upImgList){
                if(!item.isImgs){
                  this.$message.warning({
                    showClose: true,
                    message: "动态最多上传一个视频哦~",
                  })
                  return
                }
              }
              if(num>0){
                this.$message.warning({
                  showClose: true,
                  message: "动态最多上传一个视频哦~",
                })
                return
              }
              let videoObj = await this.getVideoCover(fileUrl)
              if(videoObj.flag) {
                videoObj.video.upFiles = files[i]
                upImgNum.push(videoObj.video)
                num = num + 1 
              }
            }
          }
          return resolve(upImgNum)
        })
        promise.then(()=>{
          this.upImgList = [...this.upImgList, ...upImgNum]
        })
      },

      // 获取图片信息
      fileImgInfo(url) {
        return new Promise((resolve) => {
          var img = new Image()
          img.src = url
          img.onload = function() {
            let urlHeight = 750 * (img.height / img.width)
            return resolve(urlHeight)
          }
        })
      },

      // 删除图片
      delImg(item,index) {
        if(item.isImgs){
          this.videonum = 0
        }
        this.upImgList.splice(index, 1)
        this.upImgObj.splice(index, 1)
      },

      // 选择视频
      fileChangeVideo(item) {
        let that = this
        that.videoUrl = []
        let files = item
        let filesUrl = URL.createObjectURL(files)
        var videoObj = document.createElement("video")
        videoObj.preload = "metadata"
        videoObj.src = filesUrl
        videoObj.autoplay = true
        videoObj.onloadeddata = function() {
          URL.revokeObjectURL(filesUrl)
          let width = videoObj.videoWidth
          let height = videoObj.videoHeight
          let filesHeight = 750 * (height / width)
          var canvas = document.createElement("canvas")
          var ctx = canvas.getContext("2d")
          canvas.width = width
          canvas.height = height
          ctx.drawImage(videoObj, 0, 0, canvas.width, canvas.height)
          that.viodeImg = canvas.toDataURL("image/png", 1)
          let img = new Image()
          img.src = canvas.toDataURL("image/png")
          img.onload = function() {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            let name = Math.random() * 1000 + ".png"
            that.vidoeCover = that.dataURLtoFile(
              canvas.toDataURL("image/png"),
              name
            )
          }
          that.videoUrl.push({
            url: filesUrl,
            urlSort: null,
            urlHeight: filesHeight,
            urlWidth: 750,
            cover: canvas.toDataURL("image/png", 1),
            defaultUrl: 2,
          })
          that.upVideoObj = files
        }
      },
      
      // 视频封面转化成文件
      dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      },

      //去除左侧空格
      LTrim(str) { 
      return str.replace(/^\s*/g,"")
      },
      
      //去右空格 
      RTrim(str) { 
      return str.replace(/\s*$/g,"")
      },

      // 获取动态标题
      themelenMin(e){
        let cursor = e.length								        // 获取光标位置
        let regSpace = /\s{3,}|[\r\n]/g							// 多个空格或换行
        let trimStrL = this.LTrim(e)			          // 去掉左边空格
        let trimStrC = ''														// 替换空格后数据
        let realLength = 0 													// 字节长度
        let byteItemLength = 0
        if(cursor !== -1) {
          let strL = trimStrL.slice(0,cursor)
          cursor = strL.replace(regSpace, '  ').length
        }
        trimStrC = Array.from(trimStrL.replace(regSpace,'  '))
        if(trimStrC.length == 0) {
          this.dynamicTitleLen = 0
        }
        // let textArray = Array.from(trimStrC)
        for(let i =0 ; i<trimStrC.length; i++){
          byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
          realLength += byteItemLength
          if(realLength >= 1 && realLength <= 3){
            this.dynamicTitleLen = 1
          } else {
            if(Math.ceil(parseFloat(realLength)/3) > 20) {
              trimStrC = trimStrC.splice(0,i)
              this.$message.warning({
                showClose: true,
                message: '标题最多不能超过20字哦～',
              })
              this.dynamicTitleLen = 20
            } else {
              this.dynamicTitleLen = Math.ceil(parseFloat(realLength)/3)
            }
          }
        }
        this.dynamicTitle = trimStrC.join("")
        this.titleTextstatistics = Math.ceil(parseFloat(unescape(encodeURIComponent( this.dynamicTitle)).split("").map(val => val.charCodeAt()).length)/3)
        return {
          value:trimStrC.join(""),
        }
      },

      // 获取动态内容
      introlenMin(e) {
        let cursor = e.length						// 获取光标位置
        let regSpace = /^\n{2,}/gm			// 多个空格
        let trimStrL = this.LTrim(e)		// 去掉左边空格
        let trimStrC = ''								// 替换空格后数据
        let realLength = 0 							// 实际字符串的长度
        let byteItemLength = 0					// 单个字节长度
        if(cursor !== -1) {
          let strL = trimStrL.slice(0,cursor)
          cursor = strL.replace(regSpace, '\n').length
        }
        trimStrC = trimStrL.replace(regSpace,"\n")
        trimStrC = Array.from(trimStrC)
        if(trimStrC.length == 0) {
          this.dynamicContentLen = 0
        }
        for(let i =0 ; i<trimStrC.length; i++){
          byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
          realLength += byteItemLength
          if(realLength >= 1 && realLength <= 3){
            this.dynamicContentLen = 1
          } else {
            if(Math.ceil(parseFloat(realLength)/3) > 600) {
              trimStrC = trimStrC.slice(0,i)
              this.dynamicContentLen = 600
              this.$message({
                message: '内容最多不能超过600字哦～',
                type: 'warning'
              })
            } else {
              this.dynamicContentLen = Math.ceil(parseFloat(realLength)/3)
            }
          }
        }
        this.dynamicContent = trimStrC.join("")
        this.textstatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.dynamicContent)).split("").map(val => val.charCodeAt()).length)/3)
        return {
          value:trimStrC.join("")
        }
      },

      // 打开话题菜单
      openDropdown(callback) {
        if(callback){
          this.showLocationSearchList = false
        }
      },

      // 查询热门推荐标签
      async remmendLabe() {
        try{
          let res = await this.$request.get('/labelInfo/labelRank/NEWS_LABEL_SCORE')
          if (res.code === 200) {
            this.hotTopicList = [...this.hotTopicList,...res.data]
          }
        }catch(e){
          // console.log(e)
        }
      },
      
      // 查询热门推荐话题
      async remmendTopic(){
        try{
          let res = await this.$request.get('/topicInfo/topicRank')
          this.hotTopicList = [...res.data,...this.hotTopicList]
        }catch(e){
          // console.log(e)
        }
      },

      // 查询常规话题
      async addActivityType() {
        try{
          let res = await this.$request.get('/labelInfo/labelInfoList?type=NEWS_LABEL_SCORE');
          if (res.code === 200) {
            let data = JSON.parse(JSON.stringify(res.data[0].labelInfoResponseVoList))
            for(let father of data){
              let visibleLabelList = []
              for(let son of father.labelInfoResponseVoList) {
                // 把可以展示的标签筛选出来
                if(son.isChoose === 1) {
                  visibleLabelList.push(son)
                }
              }
              father.labelInfoResponseVoList = visibleLabelList
              if(father.isChoose == 1){
                father.labelInfoResponseVoList.unshift({id:father.id, labelName: father.labelName, parentId: father.parentId, isChoose: 1})
              }
            }
            this.remmendLabeLsit = [...this.remmendLabeLsit,...data]
          }
        }catch(e){
          // console.log(e)
        }
      },   
      
      // 展示二级话题类型
      async getTopicType(e){
        if(e.labelName=="推荐"){
          this.topicList = this.hotTopicList
          return
        }else{
          this.topicList = e.labelInfoResponseVoList
        }
      },    
      
      // 选中话题
      choiceTopics(item) {
        if(item.labelName){
          let data = "#" + item.labelName + " "
          this.dynamicContent = this.dynamicContent + data 
        }
        if(item.topicContent){
          let data = "#" + item.topicContent + " "
          this.dynamicContent = this.dynamicContent + data 
        }
      },

      // 禁止输入空格
      keydown(e){
        if(e.keyCode == 32){
            e.returnValue = false
        }
      },

      // 地址组件enter事件
      searchAddress() {
        this.enterEvents = true
      },

      // 搜索地址返回内容
      search(msg) {
        this.choiceAddress = msg.addressInput
        this.componentData = msg                                                 
        this.showLocationSearchList = !this.showLocationSearchList
      },
      
      // 显示搜索组件
      shows() {
        this.showLocationSearchList = false
      },

      // 发布动态
      async upAllRelease() {
        if (this.upImgList.length < 1 && this.videoUrl.length < 1) {
          this.$message.warning({
            showClose: true,
            message: "请选择至少一张图片或视频哦~",
          })
          return
        }
        if (!this.dynamicTitle) {
          this.$message.warning({
            showClose: true,
            message: "动态标题不能为空",
          })
          return
        }
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        })
        // 筛选话题和标签
        let uploadRes
        // 获取上传图片地址
        if (this.upImgList.length > 0 || this.videoUrl.length > 0) {
          uploadRes = await this.uploadDatas()
        }
        try {
          let data = {
            id: "",
            title: this.dynamicTitle,
            content: this.dynamicContent,
            clubId: this.$route.params.clubId ? this.$route.params.clubId : null,
            longitude: this.componentData.id != 0 ? this.componentData.longitude : " ",
            latitude: this.componentData.id != 0 ? this.componentData.latitude : " ",
            country: "中国大陆",
            province: this.componentData.id != 0 ? this.componentData.province : " ",
            city: this.componentData.id != 0 ? this.componentData.city : " ",
            area: this.componentData.id != 0 ? this.componentData.area : " ",
            address: this.componentData.id != 0 ? this.componentData.address: " ",
            isComment: this.closeComments ? 2 : 1,
            accessRole: 1,
            topicRequestVoList: [],
            labelRequestVoList: [],
            turnPrivateTimeLong: 0,
            urlRequestVoList: uploadRes,
            publishType: 3,
            allowSave: this.permitDownload ? 2 : 1,
          }
          await this.$request.post("/newsInfo/publishNews", data)
          this.$message.success({
            showClose: true,
            message: "发布动态成功",
          })
          eventBus.$emit('haveConfirm',0)
          this.$router.push({ name: 'ClubDetail' });
        } catch (e) {
          this.$message.warning({
            showClose: true,
            message: "发布失败",
          })
        }
        loading.close()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .content-box{
    width: 790px;
    min-height: 688px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    .dynamic-title{
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #000000;
    }
    .dynamic-type{
      .activity-text{
        font-size: 14px;
        font-weight: bold;
        color:#000000;
      }
    }
    .title-line{
      display: flex;
      .tags{
        font-weight: bold;
        color: #EC4747;
      }
      .title-text{
        font-size: 14px;
        font-weight: bold;
        color:#000000;
        margin-bottom: 5px;
      }
    }
    .dynamic-cover{
      margin-top: 20px;
      .upload-cover{
        .tips-pic-main {
          display: flex;
          flex-wrap: wrap;
          padding-top: 12px;
          .tips-pic-list {
            width: 115px;
            height: 115px;
            margin-right: 8px;
            margin-bottom: 8px;
            box-sizing: border-box;
            position: relative;
            .show-img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 5px;
            }
            .shuow-video {
              width: 100%;
              height: 100%;
            }
            .del-img {
              position: absolute;
              right: -7px;
              top: -10px;
              z-index: 99;
            }
            .delete-icon{
              position: absolute;
              right: -5px;
              top: -6px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .more-video{
              position: absolute;
              bottom: 10px;
              left: 10px;
              width: 30px;
              height: 30px;
              z-index: 9999;
            }
          }
          .tips-pic-list:nth-child(3n) {
            margin-right: 0px;
          }
          .up-pic {
            cursor: pointer;
            border: 1px dashed #DDDDDD;
            position: relative;
            border-radius: 5px;
            .up-icon {
              font-size: 40px;
              color: #DDDDDD;
              margin-top: 25px;
              margin-left: 35px;
            }
            .upload-text{
              font-size: 14px;
              text-align: center;
              color: #a8a8a8;
              line-height: 20px;
              margin-top: 5px;
            }
          }
        }
      }
    }
    .dynamic-topics{
      margin-top: 20px;
      .topics-input{
        width: 480px!important;
        position: relative;
        .activityTopics{
          font-size: 14px;
          color: #A8A8A8;
          line-height: 20px;
        }
        .text-statistic{
          position: absolute;
          top: 9px;
          right: 10px;
          font-size: 12px;
          text-align: center;
          color: #a8a8a8;
          line-height: 17px;
          z-index: 99;
        }
      }
    }
    .dynamic-content{
      margin-top: 20px;
      .content-input{
        height: 100px;
        width: 480px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        position: relative;
        .dynamicContent{
          width: 470px;
          height: 65px;
          border-radius: 5px;
          border: none;
          min-height: 61px;
        }
        .mychoicelocation{
          height: 19px;
          position: absolute;
          top: 77px;
          left: 5px;
          display: flex;
          align-items: center;
          width: auto;
          background-color: #F1F7FF;
          padding: 0 14px;
          border-radius: 20px;
          .location-texts{
            font-size: 10px;
            text-align: left;
            color: #1465cf;
            line-height: 14px;
          }
        }
        .text-statistics{
          position: absolute;
          top: 77px;
          right: 10px;
          font-size: 12px;
          font-family: PingFang, PingFang-Regular;
          font-weight: 400;
          text-align: center;
          color: #a8a8a8;
          line-height: 17px;
        }
      }
    }
    .buttons{
      display: flex;
      margin-top: 10px;
      box-sizing: border-box;
      .topics-box{
        cursor: pointer;
        height: 21px;
        box-sizing: border-box;
        border: 1px solid #dddddd;
        border-radius: 5px;
        font-size: 12px;
        text-align: left;
        color: #333333;
        line-height: 12px;
        padding: 3px 10px 4px 10px;
        margin-right: 10px;
      }
      .location-box{
        cursor: pointer;
        display: flex;
        height: 21px;
        box-sizing: border-box;
        border: 1px solid #dddddd;
        border-radius: 5px;
        font-size: 12px;
        color: #333333;
        line-height: 15px;
        padding: 1px 10px;
        margin-right: 10px;
        .location-icon{
          margin-top: 2px;
          margin-right: 3px;
        }
      }
    }
    .searchLocations{
      .input-dress{
        position: relative;
      }
    }
    .permission-setting{
      margin-top: 30px;
      margin-bottom: 50px;
      .setting-choice{
        .permit-download{
          display: flex;
          .common-text{
            font-size: 14px;
            text-align: center;
            color: #444444;
            margin-right: 43px;
          }
        }
      }
    }
    .submit-btn{
      box-sizing: border-box;
      width: 120px;
      height: 40px;
      background: #1465CF;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  
  .border{
    border: 1px solid rgb(64, 158, 255) !important;
  }
</style>

<style lang="scss">
.el-switch.is-checked .el-switch__core {
    border-color: #1465CF;
    background-color: #1465CF;
}
.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner {
  border-color: #dddddd;
}
textarea{
  resize: none;
  outline: none;
}
input[class='dynamicContent']::-webkit-input-placeholder, textarea[class='dynamicContent']::-webkit-input-placeholder {
  font-size: 13px;
  font-family: PingFang, PingFang-Regular;
  font-weight: 400;
  text-align: left;
  color: #888888;
  line-height: 17px;
  // padding: 5px;
}
.el-popper[x-placement^=right] {
    margin-left: 2px;
    min-width: 80px;
    // padding-left: 10px;
}
.el-dropdown-link{
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0 10px 0;
  margin: 5px 10px;
  background-color: #FFF;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
}
.el-dropdown-menu:hover {
  color: #000;
}

.el-dropdown-menu__item {
  list-style: none;
  line-height: 36px;
  /* padding: 0 20px; */
  padding: 0 !important;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: 0;
}

</style>
