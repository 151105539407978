<template>
  <div class="club-roles">
    <div class="release-button" @click="releaseNews" v-if="this.$store.state.clubRoles.role != 0">
      <div class="button-text">发布动态</div>
    </div>
    <div class="release-button"  @click="releaseActivity" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditActivity) || this.$store.state.clubRoles.role == 3">
      <div class="button-text">发布活动</div>
    </div>
    <div class="club-role-item" id="clubHome" :class="navIndex ==  1? 'active' : '' " @click="openNewPage(1)">
			<span class="albums-icon iconfont icon-club-navz"></span>
      <div class="role-text">俱乐部主页</div>
    </div>
    <div class="role-item" :class="navIndex ==  2? 'active' : '' " @click="openNewPage(2)">
			<span class="albums-icon iconfont icon-club-navx"></span>
      <div class="role-text">俱乐部相册</div>
    </div>
    <div class="role-items">
			<span class="albums-icon iconfont icon-club-navc"></span>
      <div class="role-text">俱乐部成员</div>
    </div>
		<div class="role-item data-line" :class="navIndex == 6 ? 'active' : '' " @click="openNewPage(6)">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">全部成员</div>
		</div>
		<div class="role-item data-line" :class="navIndex == 7 ? 'active' : '' " @click="openNewPage(7)" v-if="this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditMember">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">成员管理</div>
		</div>
		<div class="role-item data-line" :class="navIndex == 8 ? 'active' : '' " @click="openNewPage(8)" v-if="this.$store.state.clubRoles.role === 3">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">管理员</div>
		</div>
		<div class="role-item data-line" :class="navIndex == 9 ? 'active' : '' " @click="openNewPage(9)" v-if="this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditMember">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">黑名单</div>
		</div>
    <div class="role-items" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditData) || this.$store.state.clubRoles.role == 3">
			<span class="albums-icon iconfont icon-club-navs"></span>
      <div class="role-text">
				数据中心
			</div>
    </div> 
		<div class="role-item data-line" :class="navIndex == 4 ? 'active' : '' " @click="openNewPage(4)" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditData) || this.$store.state.clubRoles.role == 3">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">数据概览</div>
		</div>
		<div class="role-item data-line" :class="navIndex == 10 ? 'active' : '' " @click="openNewPage(10)" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditData) || this.$store.state.clubRoles.role == 3">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">俱乐部活跃数据</div>
		</div>
		<div class="role-item data-line" :class="navIndex == 11 ? 'active' : '' " @click="openNewPage(11)" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditData) || this.$store.state.clubRoles.role == 3">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">活动数据概览</div>
		</div>
		<div class="role-item data-line" :class="navIndex == 5 ? 'active' : '' " @click="openNewPage(5)" v-if="this.$store.state.clubRoles.role == 3">
			<span class="albums-icon iconfont icon-club-navs"></span>
			<div class="role-text">更多</div>
		</div>
  </div>
</template>

<script>
import { eventBus } from '../../main.js'
export default {
  data() {
    return {
			showLink: 1,
			isOpenNewPages: false,
			num: -2,
    }
  },
	props:{
		navIndex:{
			type:Number,
			default:1
		}
	},

	async created(){
		eventBus.$on("dataChanges",(num) => {			
			if(num==0){
				this.isOpenNewPages = true
			}else{
				this.isOpenNewPages = false
			}
		})
		eventBus.$on("haveConfirm",(num) => {			
			if(num==0){
				this.isOpenNewPages = false
			}
		})
	},
	watch:{
		$route:{
			handler(val){
				if (val.name == 'ClubReleaseNew') {
					this.num = -1
				} else if(val.name == 'ClubReleaseEvent') {
					this.num = 0
				}
			},
		}
	},

	mounted(){
		if(this.$store.state.clubRoles.role === 0)	{
			const element = document.getElementById('clubHome')
			element.style.setProperty('--clubHome', '0')
		}
	},
  methods: {
		openNewPage(index){
			if(this.isOpenNewPages && this.navIndex === 0){
				this.$confirm('离开页面无法保存数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.isOpenNewPages = false
					eventBus.$emit('haveConfirm',0)
					if (index === 1) {
						this.$router.push({name: 'ClubDetail'})
						this.$emit('currentNav',index)
					} else if (index === 2) {
						this.$router.push({name: 'ClubTheme'})
						this.$emit('currentNav',index)
					} else if (index === 3) {
						this.$router.push({name: 'AllMember'})
						this.$emit('currentNav',6)
					} else if (index === 4){
						if (this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditData) {
							this.$router.push({name: 'ClubData'})
							this.$emit('currentNav',index)
						} else {
							this.$alert('你暂无权限','提示', {
								confirmButtonText: '确定',
							})
						}
					} else if(index === 5) {
						this.$alert('后续功能正在开发中，敬请期待~','提示', {
							confirmButtonText: '确定',
						}).then(()=>{
							// this.$emit('currentNav',1)
						})
					} else if(index === 6) {
						this.$router.push({name: 'AllMember'})
						this.$emit('currentNav',index)
					} else if(index === 7) {
						this.$router.push({name: 'ManageMember'})
						this.$emit('currentNav',index)
					} else if(index === 8) {
					if (this.$store.state.clubRoles.role === 3) {
						this.$router.push({name: 'Administrator'})
						this.$emit('currentNav',index)	
					}else{
						this.$alert('你暂无权限','提示', {
							confirmButtonText: '确定',
						})				
					}
					} else if(index === 9) {
						this.$router.push({name: 'Blacklist'})
						this.$emit('currentNav',index)
					} else if(index === 10) {
						this.$router.push({name: 'ClubActiveData'})
						this.$emit('currentNav',index)
					}
				}).catch(() => {
					this.isOpenNewPages = true
					// this.$message.warning({
					// 	showClose: true,
					// 	type: 'info',
					// 	message: '已取消跳转'
					// });
				});
			}else{
				if (index === 1) {
					this.$router.push({name: 'ClubDetail'})
					this.$emit('currentNav',index)
				} else if (index === 2) {
					eventBus.$emit('closeEditMode')
					this.$router.push({name: 'ClubTheme'})
					this.$emit('currentNav',index)
				} else if (index === 3) {
					this.$router.push({name: 'AllMember'})
					eventBus.$emit('closeEditMode')
					this.$emit('currentNav',6)
				} else if (index === 4){
					if (this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditData) {
						this.$router.push({name: 'ClubData'})
						this.$emit('currentNav',index)
					} else {
						this.$alert('你暂无权限','提示', {
							confirmButtonText: '确定',
						})
					}
				} else if(index === 5) {
					this.$alert('后续功能正在开发中，敬请期待~','提示', {
						confirmButtonText: '确定',
					}).then(()=>{
						// this.$emit('currentNav',1)
					})
				} else if(index === 6) {
					eventBus.$emit('closeEditMode')
					this.$router.push({name: 'AllMember'})
					this.$emit('currentNav',index)
				} else if(index === 7) {
					eventBus.$emit('closeEditMode')
					this.$router.push({name: 'ManageMember'})
					this.$emit('currentNav',index)
				} else if(index === 8) {
					eventBus.$emit('closeEditMode')
					if (this.$store.state.clubRoles.role === 3) {
						this.$router.push({name: 'Administrator'})
						this.$emit('currentNav',index)	
					}else{
						this.$alert('你暂无权限','提示', {
							confirmButtonText: '确定',
						})				
					}
				} else if(index === 9) {
					eventBus.$emit('closeEditMode')
					this.$router.push({name: 'Blacklist'})
					this.$emit('currentNav',index)
				}	else if(index === 10) {
					this.$router.push({name: 'ClubActiveData'})
					this.$emit('currentNav',index)
				} else if(index === 11) {
					this.$router.push({name: 'ClubActivityData'})
					this.$emit('currentNav',index)
				}
			}
		},

		releaseNews(){
			if(this.isOpenNewPages && this.num==0){
				this.$confirm('离开页面无法保存数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
        }).then(() => {
					eventBus.$emit('haveConfirm',0)
					this.isOpenNewPages = false
					this.$router.push({name: 'ClubReleaseNew'})	
					this.$emit('currentNav',0)
        }).catch(() => {
					this.isOpenNewPages = true
					// this.$message.warning({
					// 	showClose: true,
					// 	type: 'info',
					// 	message: '已取消跳转'
					// });
        });
			}else{
				this.$router.push({name: 'ClubReleaseNew'})	
				this.$emit('currentNav',0)
			}
		},

		releaseActivity(){
			if(this.isOpenNewPages && this.num==-1){
				this.$confirm('离开页面无法保存数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
        }).then(() => {
					eventBus.$emit('haveConfirm',0)
					this.isOpenNewPages = false
					if (this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditActivity) {
						this.$router.push({name: 'ClubReleaseEvent'})
						this.$emit('currentNav',0)			
					}else{
						this.$alert('你暂无权限','提示', {
							confirmButtonText: '确定',
						})				
					}
        }).catch(() => {
					this.isOpenNewPages = true
					// this.$message.warning({
					// 	showClose: true,
					// 	type: 'info',
					// 	message: '已取消跳转'
					// });
        });
			}else{
				if (this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditActivity) {
					this.$router.push({name: 'ClubReleaseEvent'})
					this.$emit('currentNav',0)			
				}else{
					this.$alert('你暂无权限','提示', {
						confirmButtonText: '确定',
					})				
				}
			}
		}
  }
}
</script>

<style lang="scss" scoped>
  .club-roles{
    width: 215px;
    min-height: 196px;
    background: #FFFFFF;
    border-radius: 5px;
    box-sizing: border-box;
    margin-right: 17px;
    .release-button{
			.button-text{
				box-sizing: border-box;
				width: 185px;
				height: 45px;
				background: #1465CF;
				border-radius: 5px;
				font-size: 16px;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				cursor: pointer;
				margin-bottom: 15px;
			}
    }
		.release-button:nth-child(1){
			padding-top: 15px;
		}
    .club-role-item{
      cursor: pointer;
			position: relative;
			padding:15px 15px 15px 22px;
			box-sizing: border-box;
			color:#000;
			font-size: 14px;
      display: flex;
			align-items: center;
			transition: all 0.5s;
			.albums-icon{
				margin-right: 15px;
			}
			.role-text{
				flex:1;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.arrow-down{
					font-size: 12px;
					color:#444;
					transform: rotateX(0deg);
					transition: all 0.5s;
				}
			}
    }
    .club-role-item:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: var(--clubHome);
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .role-item{
      cursor: pointer;
			position: relative;
			padding:15px 15px 15px 22px;
			box-sizing: border-box;
			color:#000;
			font-size: 14px;
      display: flex;
			align-items: center;
			transition: all 0.5s;
			.albums-icon{
				margin-right: 15px;
			}
			.role-text{
				flex:1;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.arrow-down{
					font-size: 12px;
					color:#444;
					transform: rotateX(0deg);
					transition: all 0.5s;
				}
			}
    }
    .role-item:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .role-items{
			position: relative;
			padding:15px 15px 15px 22px;
			box-sizing: border-box;
			color:#000;
			font-size: 14px;
      display: flex;
			align-items: center;
			transition: all 0.5s;
			.albums-icon{
				margin-right: 15px;
			}
			.role-text{
				flex:1;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.arrow-down{
					font-size: 12px;
					color:#444;
					transform: rotateX(0deg);
					transition: all 0.5s;
				}
			}
    }
    .role-items:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #ddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
		.data-line{
			position: relative;
			font-size: 13px;
			.albums-icon{
				color:#fff;
			}
		}
    .data-line:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #fff;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
		.role-item:hover{
			color:#1465CF;
			.role-text{
				color:#1465CF;
				.arrow-down{
					color:#1465CF;
				}
			}	
		}
		.active{
			background-color: #EDF5FF;
			color:#1465CF;
			.role-text{
				color:#1465CF;
				.arrow-down{
					color:#1465CF;
					transform: rotateX(180deg);
				}
			}
		}
		.active-icon{
			.role-text{
				.arrow-down{
					transform: rotateX(180deg);
				}
			}
		}
		.data-line.active {
			.albums-icon{
				color:#EDF5FF;
			}
		}
    
  }
</style>

<style lang="scss">
	.el-message-box .el-message-box__header .el-message-box__title span {
    color: #000;
    font-size: 17px;
    font-weight: bold;
	}
	:root{
		--clubHome: 0.05rem;
	}
</style>