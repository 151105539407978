<template>
  <div class="content-box">
    <div class="activity-title">发布活动</div>
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">活动类型</div>
      </div>
      <el-cascader
        ref="myCascader"
        v-model="showActivityType"
        :options="activityTypeList"
        separator=" - "
        :props="{ expandTrigger: 'hover', value:'id', label: 'labelName', children: 'labelInfoResponseVoLists', disabled: ''}"
        @change="handleChange">
      </el-cascader>
    </div>
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">活动封面设置（{{ upImgList.length }}/9）</div>
        <div class="cover-template" @click="labelRecommendPic">封面模板</div>
      </div>
      <div class="upload-cover">
        <div class="tips-pic-main">
          <div class="tips-pic-list" v-for="(item, index) of upImgList" :key="index">
            <el-image class="delete-icon" :src="require('@/assets/delete.png')" fit="contain" @click="delImg(index)"></el-image>
            <el-image class="show-img" :src="item.url" fit="cover"></el-image>
          </div>
          <div class="tips-pic-list up-pic" @click="selsectPic" v-if="upImgList.length < 9">
            <div class="up-icon iconfont icon-add"></div>
            <div class="upload-text">添加封面</div>
            <input v-show="false" accept="image/*" type="file" id="uploadIMG" @change="fileChangeImg($event)" ref="inputImg" multiple />
          </div>
        </div>
      </div>
    </div>
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">活动主题</div>        
      </div>
      <div class="topics-input">
        <el-input type="text" :value="activityTopics" style="width:480px" @input="themelenMin"  placeholder="填写活动主题"></el-input>
        <span class="text-statistic">{{ this.titleTextstatistics }}/10</span>
      </div>
    </div>
    <div class="activity-content default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">活动内容</div>        
      </div>
      <div class="content-input">
        <el-input type="textarea" :value="activityContent" resize='none' @input="introlenMin" rows="4"  placeholder="简单介绍下活动内容吧～"></el-input>
        <span class="text-statistic">{{ this.contentTextstatistics }}/600</span>
      </div>
    </div> 
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">报名截止</div>
        <div class="tips">该时间前用户可主动退出活动</div>      
      </div>
      <div class="deadline-choice">
        <el-date-picker
          v-model="registerDate"
          type="date"
          size="small"
          prefix-icon=""
          placeholder="选择日期">
        </el-date-picker>
        <div class="divided">—</div>
        <el-time-picker
          v-model="registerTime"
          size="small"
          placeholder="选择时间">
        </el-time-picker>
      </div>
    </div> 
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">活动时间</div>
        <div class="tips">选择活动开始和结束时间</div>      
      </div>
      <div class="activity-choice">
        <el-date-picker
          v-model="activityStartDate"
          type="date"
          size="small"
          prefix-icon=""
          placeholder="选择日期">
        </el-date-picker>
        <div class="divided">—</div>
        <el-time-picker
          v-model="activityStartTime"
          size="small"
          placeholder="选择时间">
        </el-time-picker>
        <div class="divided-text">至</div>
        <el-date-picker
          v-model="activityEndDate"
          type="date"
          size="small"
          prefix-icon=""
          placeholder="选择日期">
        </el-date-picker>
        <div class="divided">—</div>
        <el-time-picker
          v-model="activityEndTime"
          size="small"
          placeholder="选择时间">
        </el-time-picker>
      </div>
    </div> 
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">活动地点</div>
      </div>
      <div @click.stop="showLocationSearchList = !showLocationSearchList" @keyup.enter="searchAddress">
        <input type="text" :class="showActivityBorder? 'border' : '' " @blur="activitycloseBorders" @focus="activityshowBorders" style="border:1px solid #dddddd;padding-left:5px;color: #333333;height: 34px;width: 340px;border-radius: 5px;" v-model="choiceAddress" placeholder="选择活动地点" />
        <div class="searchLocations">
          <search-location :showLocationSearchList="showLocationSearchList" :address="choiceAddress" :enterEvents="enterEvents" @show="shows" @chooseAddress="search"></search-location>
        </div>
      </div> 
    </div>
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">费用类型</div>
        <div class="tips">目前暂不支持在Web端发布付费活动</div>           
      </div>
      <div class="radio-button-line">
        <div class="radio-button">
          <div class="button-unchoice"><div class="button-choice"></div></div>
          <div class="button-text">免费</div>
        </div>
      </div>
    </div>
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">最多人数</div>
      </div>
      <input :class="showBorder? 'border' : '' " type="number" style="border:1px solid #dddddd;padding-left:5px;color: #333333;height: 34px;width: 160px;border-radius: 5px;" @input="isZero(activityNumber)"  @change="testNumber(activityNumber)" @blur="closeBorders" @focus="showBorders"  v-model="activityNumber" placeholder="输入活动最多人数(2～99)" />
    </div>
    <div class="default-top">
      <div class="title-line">
        <div class="tags">*</div>
        <div class="title-text">活动范围</div>        
      </div>
      <div class="radio-button-line">
        <div class="radio-button" @click="togglePeopleType(1)">
          <div class="button-unchoice"><div class="button-choice" v-if="peopleType === 1"></div></div>
          <div class="button-text">所有人</div>
        </div>
        <div class="radio-button" @click="togglePeopleType(2)">
          <div class="button-unchoice"><div class="button-choice" v-if="peopleType === 2"></div></div>
          <div class="button-text">粉丝和成员</div>
        </div>
        <div class="radio-button" @click="togglePeopleType(3)">
          <div class="button-unchoice"><div class="button-choice" v-if="peopleType === 3"></div> </div>
          <div class="button-text">仅成员</div>
        </div>
      </div>
    </div>  
    <div class="submit-btn" @click="complete">发布活动</div>

    <!-- 选择封面模板弹窗 -->
    <div class="box-card" v-if="selectCover">
      <span class="box-view-top">
        <div class="title">
          <span class="title-text">封面模板</span>
          <icon-font name="close" color="#444444" size="16px"  @click="closepopup" style="cursor: pointer;margin-right: 15px;margin-top: -20px"></icon-font>
        </div>
          <el-scrollbar style="height: 266px; width: 100%">
            <div class="default-cover-list" v-if="activityCoverList.length>0">
              <div class="default-cover-img" v-for="(item, index) in activityCoverList" :key="index" @click="chooseCover(item)">
                <el-image class="cover-img" :src="item.url" fit="cover"></el-image>
                <div class="nochoice" >
                  <div class="content">
                    <el-image
                      class="more-roles" 
                      :src="require('@/assets/unchoicePhoto.png')"
                      fit="cover">
                    </el-image>                  
                  </div>
                  <div class="contents" v-if="item.checked">
                    <el-image
                      class="more-roles" 
                      :src="require('@/assets/choicePhoto.png')"
                      fit="cover">
                    </el-image>
                  </div>
                </div> 
              </div>
            </div>
          </el-scrollbar>
        <div class="button-box">
          <div class="confirm">
            <div class="confirm-text" @click="chooseDefaultCover()">确定</div>
          </div>	
        </div>			
      </span>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../main.js'
import searchLocation from '../../components/searchLocation.vue';
import iconFont from "../../components/IconFont";
export default {
  data(){
    return{
      id: '',
      activityTopicsLen: '',               // 活动主题长度
      activityContentLen: '',              // 活动内容长度
      upImgList: [], 								       // 上传图片地址
      upImgObj: [], 								       // 保存上传图片对象
      activityCoverList: [],               // 存放封面模板
      selectCover: false,                  // 是否选择封面模板
      choiceCover: [],                     // 选中的封面信息
      showActivityType: '',                // 输入框显示的活动类型
      activityTypeList: [],                // 存放活动类型数组
      commendTypeList: [],                 // 活动推荐标签
      activityType: '',                    // 活动类型
      activityTopics: '',                  // 活动主题
      activityContent: '',                 // 活动内容
      registerDate: null,                  // 报名日期
      registerTime: null,                  // 报名时间
      activityStartDate: null,             // 活动开始日期
      activityEndDate: null,               // 活动结束日期
      activityStartTime: null,             // 活动开始时间
      activityEndTime: null,               // 活动结束时间
      joinEndTimeLong: '',                 // 报名截止时间戳
      startTimeLong: '',                   // 活动开始时间戳
      endTimeLong: '',                     // 活动截止时间戳
      nowDate: new Date().getTime(),       // 当前日期时间戳
      over3hours: false,                   // 是否超过三小时
      titleTextstatistics: 0,              // 活动主题字数统计
      contentTextstatistics: 0,            // 活动主题字数统计
      showLocationSearchList: false,       // 展示搜索组件  
      choiceAddress: '',                   // 选择活动地址
      enterEvents: false,                  // 地址组件enter事件
      componentData: [],                   // 组件传递的内容
      peopleType: 1,                       // 活动人员范围：1.所有人；2.俱乐部粉丝和俱乐部成员；3.俱乐部成员   
      activityNumber: null,                // 活动人数上限
      showBorder: false,                   // 人数上限focus时显示边框
      showActivityBorder: false,           // 活动地点focus时显示边框
    }
  },

  components:{
    iconFont,
    searchLocation
  },

  mounted(){
    this.getActivityLabelCommend()
    this.$watch('showLocationSearchList', res =>{
      if(res){
        document.addEventListener('click', this.HiddenClick,false)
      }
    })
    this.$watch('showActivityType',res=>{
      if(res.length!=0){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('upImgList',res=>{
      if(res.length!=0){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('activityTopics',res=>{
      if(res.length!=0){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('activityContent',res=>{
      if(res.length!=0){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('registerDate',res=>{
      if(res!=null){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('registerTime',res=>{
      if(res!=null){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('activityStartDate',res=>{
      if(res!=null){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('activityEndDate',res=>{
      if(res!=null){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('activityStartTime',res=>{
      if(res!=null){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('activityEndTime',res=>{
      if(res!=null){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('choiceAddress',res=>{
      if(res.length!=0){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
    this.$watch('activityNumber',res=>{
      if(res!=null){
        eventBus.$emit('dataChanges',0)
      }else{
        if(this.isNull()){
          eventBus.$emit('dataChanges',1)
        }
      }
    })
  },

  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,false)
  },

  methods:{
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      if(this.showLocationSearchList){
        this.showLocationSearchList = false
      }
    },

    // 选择上传图片
    selsectPic() {
      this.$refs.inputImg.click()
    },

    //选择类型
    handleChange() {
      // 总结六
      this.showActivityType.id = this.$refs["myCascader"].getCheckedNodes()[0].value;
      this.showActivityType.name = this.$refs["myCascader"].getCheckedNodes()[0].label;
      let okactivityType = {
        labelId: this.showActivityType.id,
        labelName: this.showActivityType.name,
        parentId: this.showActivityType[0]
      }
      this.activityType = okactivityType
    },

    // 判断内容是否改变
    isNull() {
      if(this.showActivityType.length==0 && this.upImgList.length==0 && this.activityTopics.length==0 && this.activityContent.length==0 && this.registerDate==null  && this.registerTime==null  && this.activityStartDate==null  && this.activityEndDate==null && this.activityStartTime==null && this.activityEndTime==null && this.choiceAddress.length==0 && this.activityNumber==null){
        return true
      }
    },

		// 判断是否是图片
		isPicture(fileName){
			let strFilter = /\.(jpeg|gif|jpg|png|bmp|pic|svg)$/
			if (!strFilter.test(fileName)) {
				return false
			} else {
				return true
			}
    },
    
    // 选择图片
    async fileChangeImg(event) {
      let files = event.target.files
      let upImgNum = []
      let fileLength = files.length
      let upImgLength = this.upImgList.length
      let totalLength = fileLength + upImgLength
      let length = 0
      if (totalLength > 9) {
        length = 9 - upImgLength
      } else {
        length = fileLength
      }
      for (let i = 0; i < length; i++) {
        let fileUrl = URL.createObjectURL(files[i])
        let fileHeight = await this.fileImgInfo(fileUrl)
        upImgNum.push({
          url: fileUrl,
          urlHeight: fileHeight,
          urlWidth: 750,
          urlSort: null,
          defaultUrl: 2,
        });
      }
      this.upImgObj = [...this.upImgObj, ...files]
      this.upImgList = [...this.upImgList, ...upImgNum]
    },

    // 获取图片信息
    fileImgInfo(url) {
      return new Promise((resolve) => {
        var img = new Image()
        img.src = url
        img.onload = function() {
          let urlHeight = 750 * (img.height / img.width)
          return resolve(urlHeight)
        }
      })
    },

    // 计算图片宽高
    getImageInfo(url){
      return new Promise((resolve, reject) => {
        var img = new Image()
        img.src = url
        img.onload = function(){
          // 打印
          let urlHeight = 750 * (img.height / img.width);
          return resolve(urlHeight)
        }
        img.onerror = function(){
          reject()
        }
      })
    },

    // 上传图片
    async uploadData() {
      return new Promise(async (resolve) => {
        await this.$credential(3)
        let listDatas = []
        let uploadPath = ''
        for (let i = 0; i < this.upImgObj.length; i++) {
          // 如果图片链接为本地临时地址，则上传图片至COS，系统默认图则不上传
          if(!this.upImgList[i].default){
            uploadPath = await this.$uploadFile(3, this.upImgObj[i])
            listDatas.push({url: uploadPath, urlHeight: this.upImgList[i].urlHeight, urlWidth: 750, defaultUrl: i == 0 ? 1 : 2})
          }else{
            listDatas.push({url:this.upImgObj[i].url, urlHeight: this.upImgObj[i].urlHeight, urlWidth: 750, defaultUrl: i == 0 ? 1 : 2})
          }
        }
        resolve(listDatas)
      })
    },

    // 删除图片
    delImg(index) {
      this.upImgList.splice(index, 1)
      this.upImgObj.splice(index, 1)
    },

    // 查询活动推荐标签
    async getActivityLabelCommend() {
      try {
        let res = await this.$request.get(`/labelInfo/activityLabelCommend/${this.$route.params.clubId}`);
        if(res.code === 200) {
          this.commendTypeList = res.data
          this.addActivityType()
        }
      } catch (error) {
        // 
      }
    },

    // 查询活动类型
    async addActivityType() {
      try{
        let res = await this.$request.get(`/labelInfo/labelInfoList?type=ACTIVITY_LABEL_SCORE&clubId=${this.$route.params.clubId}`);
        if (res.code === 200) {
          let data = JSON.parse(JSON.stringify(res.data[0].labelInfoResponseVoList))
          for(let father of data){
            let visibleLabelList = []
            for(let son of father.labelInfoResponseVoList) {
              // 把可以展示的标签筛选出来
              if(son.isChoose === 1) {
                visibleLabelList.push(son)
              }
            }
            father.labelInfoResponseVoList = visibleLabelList
            if(father.isChoose === 1) {
              let labelObj = {
                "labelName": father.labelName,
                "id": father.id
              }
              if(father.labelInfoResponseVoList.length > 0) {
                father.labelInfoResponseVoList.unshift(labelObj)
              } else {
                father.labelInfoResponseVoList = [labelObj, ...father.labelInfoResponseVoList]
              }
            }
            father.labelInfoResponseVoLists = father.labelInfoResponseVoList
          }
          // 插入活动推荐标签
          if(this.commendTypeList.length > 0) {
            let fatherLabelObj = {
              "labelName": "推荐",
              "labelInfoResponseVoLists": this.commendTypeList
            }
            data.unshift(fatherLabelObj)
          }
          this.activityTypeList = data
        }
      }catch(e){
        // console.log('error',e)
      }
    },

    // 查询主题图片
    async labelRecommendPic() {
      if (!this.activityType) {
        this.$message.warning({
          showClose: true,
          message: '请选择活动类型'
        });
        return
      }
      if (this.upImgList.length >8){
        this.$message.warning({
          showClose: true,
          message: '最多上传9张图片哦'
        });
        return
      }
      try{
        let res = await this.$request.get(`/labelRecommendPic/labelRecommendPicList?labelId=${this.activityType.labelId}`);
        if (res.code == 200) {
          if (res.data) {
            let data = res.data
            for(let item of data){
              item.checked = false
              item.url =  this.$cosUrl + item.url
            }
            this.activityCoverList = data
          }else{
            this.activityCoverList = []
          }
        }
        if(this.activityCoverList.length==0){
          this.$message.warning({
            showClose: true,
            message: '目前还没有该主题的封面哦'
          });
          return
        }
        this.selectCover = true
      }catch(e){
        //TODO handle the exception
      }
    },

    // 选中封面图
    chooseCover(item) {
      item.checked = !item.checked
    },

    // 确定选择活动封面图
    async chooseDefaultCover(){
      for(let item of this.activityCoverList){
        if(item.checked){
          this.choiceCover = item
          for(let items of this.upImgList){
            if(items.url == this.choiceCover.url){
              this.$message.warning({
                showClose:true,
                message: "请勿重复选择"
              })
              return
            }
          }
          if(this.upImgList.length>8){
            this.$message.warning({
              showClose:true,
              message: "活动最多上传9张图片哦"
            })
            this.selectCover = false
            return            
          }
          let urlHeight = await this.getImageInfo( this.choiceCover.url)
          this.upImgList.push({url: this.choiceCover.url, urlHeight: urlHeight, urlWidth: 750, defaultUrl: 2, default:true})
          this.upImgObj.push({url:'/label' + (this.choiceCover.url).split('label')[1], urlHeight: urlHeight, urlWidth: 750, defaultUrl: 2, default:true})
        }
      }
      this.selectCover = false
    },

    // 关闭选择封面弹窗
    closepopup() {
      this.selectCover = false
      for(let item of this.activityCoverList){
        if(item.checked){
          item.checked = false
        }
      }
    },

    // 选择活动类型触发事件
    choiceType(event) {
      let okactivityType = {
        labelId: event.id,
        labelName: event.labelName,
        parentId: event.parentId
      }
      this.activityType = okactivityType
    },

    //去除左侧空格
		LTrim(str) { 
		  return str.replace(/^\s*/g,"")
		},

		// 获取活动主题
		themelenMin(e){
			let cursor = e.length								        // 获取光标位置
			let regSpace = /\s{3,}|[\r\n]/g							// 多个空格或换行
      let trimStrL = e.replace(/^\s*/g,"")        // 去掉左边空格
			let trimStrC = ''														// 替换空格后数据
			let realLength = 0 													// 字节长度
			let byteItemLength = 0
			if(cursor !== -1) {
				let strL = trimStrL.slice(0,cursor)
				cursor = strL.replace(regSpace, '  ').length
			}
			trimStrC = Array.from(trimStrL.replace(regSpace,'  '))
			if(trimStrC.length == 0) {
				this.activityTopicsLen = 0
			}
			for(let i =0 ; i<trimStrC.length; i++){
				byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
				realLength += byteItemLength
				if(realLength >= 1 && realLength <= 3){
					this.activityTopicsLen = 1
				} else {
					if(Math.ceil(parseFloat(realLength)/3) > 10) {
						trimStrC = trimStrC.splice(0,i)
						this.$message.warning({
              showClose: true,
							message: '主题最多不能超过10字哦～',
						})
						this.activityTopicsLen = 10
					} else {
						this.activityTopicsLen = Math.ceil(parseFloat(realLength)/3)
					}
				}
			}
      this.activityTopics = trimStrC.join("")
      this.titleTextstatistics = Math.ceil(parseFloat(unescape(encodeURIComponent( this.activityTopics)).split("").map(val => val.charCodeAt()).length)/3)
			return {
				value:trimStrC.join(""),
			}
    },

    // 获取活动内容
		introlenMin(e) {
			let cursor = e.length						// 获取光标位置
			let regSpace = /^\n{2,}/gm			// 多个空格
			let trimStrL = this.LTrim(e)		// 去掉左边空格
			let trimStrC = ''								// 替换空格后数据
			let realLength = 0 							// 实际字符串的长度
			let byteItemLength = 0					// 单个字节长度
			if(cursor !== -1) {
				let strL = trimStrL.slice(0,cursor)
				cursor = strL.replace(regSpace, '\n').length
			}
			trimStrC = trimStrL.replace(regSpace,"\n")
			trimStrC = Array.from(trimStrC)
			if(trimStrC.length == 0) {
				this.activityContentLen = 0
			}
			for(let i =0 ; i<trimStrC.length; i++){
				byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
				realLength += byteItemLength
				if(realLength >= 1 && realLength <= 3){
					this.activityContentLen = 1
				} else {
					if(Math.ceil(parseFloat(realLength)/3) > 600) {
						trimStrC = trimStrC.slice(0,i)
						this.activityContentLen = 600
						this.$message({
							message: '内容最多不能超过600字哦～',
							type: 'warning'
						})
					} else {
						this.activityContentLen = Math.ceil(parseFloat(realLength)/3)
					}
				}
			}
      this.activityContent = trimStrC.join("")
			this.contentTextstatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.activityContent)).split("").map(val => val.charCodeAt()).length)/3)
			return {
				value:trimStrC.join("")
			}
    },

    // 人数上限focus使文本高亮
    showBorders () {
      this.showBorder = true
    },

    // 人数上限blur使文本恢复
    closeBorders () {
      this.showBorder = false
    },

    // 活动地点focus使文本高亮
    activityshowBorders () {
      this.showActivityBorder = true
    },

    // 活动地点blur使文本恢复
    activitycloseBorders () {
      this.showActivityBorder = false
    },

    isZero(num){
      if(num == 0){
        this.activityNumber = null
        return
      }
    },

    //限制只能输入数字
    testNumber(num){
      let bool = this.$test.number(num)
      if(!bool){
        this.activityNumber = null
        this.$message.warning({
          showClose: true,
          message: '请输入正确的格式'
        });
      }else{
        if(num==1){
          this.$message.warning({
            showClose: true,
            message: '活动人数不少于2人'
          });
        }
        if(num>=100){
          this.activityNumber = 99
        }
      }
    },

    // 地址组件enter事件
    searchAddress() {
      this.enterEvents = true
    },

    // 搜索地址返回内容
    search(data) {
      this.choiceAddress = data.myShowAddres
      this.componentData = data
      this.showLocationSearchList = !this.showLocationSearchList
    },
    
    // 处理选择的时间(转化为时间戳)
    dealtime() {
      // 报名截止时间处理
      let origintime = null
      origintime = this.registerTime
      let time1 = this.timestampToTime(this.registerTime).split(/\s+/)
      this.registerTime = time1[1]
      let time2 = this.timestampToTime(this.registerDate).split(/\s+/)
      this.registerDate = time2[0]  
      this.joinEndTimeLong =  new Date(this.registerDate + ' ' + this.registerTime).getTime()
      this.registerTime = origintime

      // 活动开始时间处理
      origintime = this.activityStartTime
      let time3 = this.timestampToTime(this.activityStartTime).split(/\s+/)
      this.activityStartTime = time3[1]
      let time4 = this.timestampToTime(this.activityStartDate).split(/\s+/)
      this.activityStartDate = time4[0]  
      this.startTimeLong =  new Date(this.activityStartDate + ' ' + this.activityStartTime).getTime()
      this.activityStartTime = origintime

      // 活动结束时间处理
      origintime = this.activityEndTime
      let time5 = this.timestampToTime(this.activityEndTime).split(/\s+/)
      this.activityEndTime = time5[1]
      let time6 = this.timestampToTime(this.activityEndDate).split(/\s+/)
      this.activityEndDate = time6[0]  
      this.endTimeLong =  new Date(this.activityEndDate + ' ' + this.activityEndTime).getTime()
      this.activityEndTime = origintime
      
      // 判断是否大于三个小时
      var difftime = (this.startTimeLong - this.joinEndTimeLong)/1000; //计算时间差,并把毫秒转换成秒
      var days = parseInt(difftime/86400); // 天  24*60*60*1000 
      var hours = parseInt(difftime/3600)-24*days;    // 小时 60*60 总小时数-过去的小时数=现在的小时数 
      // var minutes = parseInt(difftime%3600/60); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
      // var seconds = parseInt(difftime%60);  // 以60秒为一整份 取余 剩下秒数
      if(days==0 && hours<=3){
        this.over3hours = true
      }
    },
    
    // 时间戳转换
    timestampToTime(timestamp){
      var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = date.getDate() + ' ';
      var h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':' ;
      var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':' ;
      var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
      return Y+M+D+h+m+s;
    },

    // 显示搜索组件
    shows() {
      this.showLocationSearchList = true
    },

    // 切换活动范围
    togglePeopleType(index) {
      this.peopleType = index
    },

    // 发布活动
    async complete(){
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      if (!this.activityTopics) {
        this.$message.warning({
            showClose: true,
            message: '活动主题不能为空'
          });
        loading.close()	
        return
      }
      if (!this.registerDate || !this.registerTime) {
        this.$message.warning({
            showClose: true,
            message: '请选报名结束时间'
          });
        loading.close()	
        return
      }
      if (!this.activityStartDate || !this.activityEndDate || !this.activityStartTime || !this.activityEndTime) {
        this.$message.warning({
            showClose: true,
            message: '请选择活动开始~结束时间'
          });
        loading.close()	
        return
      }
      if (!this.activityNumber) {
        this.$message.warning({
            showClose: true,
            message: '请填写活动人数'
          });
        loading.close()	
        return
      }
      if (!this.choiceAddress) {
        this.$message.warning({
            showClose: true,
            message: '请选择活动地点'
          });
        loading.close()	
        return
      }else if(this.componentData.length==0){
        this.$message.warning({
            showClose: true,
            message: '请选择正确的活动地址'
          });
        loading.close()	
        return       
      }
      if (!this.activityContent) {
        this.$message.warning({
            showClose: true,
            message: '活动内容不能为空'
          });
        loading.close()	
        return
      }
      if (!this.showActivityType) {
        this.$message.warning({
            showClose: true,
            message: '请选择活动类型'
          });
        loading.close()	
        return
      }
      if (this.upImgList.length == 0) {
        this.$message.warning({
            showClose: true,
            message: '还没有选择活动主题图片噢~'
          });
        loading.close()	
        return
      }
      this.dealtime()
      // if (this.nowDate > this.joinEndTimeLong ) {
      //   this.registerTime = null
      //   this.$message.warning({
      //       showClose: true,
      //       message: '报名开始时间应该小于报名截止时间'
      //     });
      //   loading.close()	
      //   return
      // }
      if (this.startTimeLong > this.endTimeLong ) {
        this.activityStartTime = null
        this.activityEndTime = null
        this.$message.warning({
            showClose: true,
            message: '报名结束时间要大于活动开始时间~'
          });
        loading.close()	
        return
      }
      if (this.over3hours) {
        this.activityStartTime = null
        this.activityEndTime = null
        this.$message.warning({
            showClose: true,
            message: '活动报名截止时间早于活动开始时间三小时~'
          });
        loading.close()	
        return
      }
 
      let listDatas = await this.uploadData()
      let data = {
        id: null,
        activityName: this.activityTopics,
        joinStartTimeLong: this.nowDate,
        joinEndTimeLong: this.joinEndTimeLong,
        startTimeLong: this.startTimeLong,
        endTimeLong: this.endTimeLong,
        longitude: this.componentData.longitude,
        latitude: this.componentData.latitude,
        country: '中国大陆',
        province: this.componentData.province,
        city: this.componentData.city,
        area: this.componentData.area,
        address: this.componentData.address,
        feeType: 1,
        nonMemberAmount: 0,
        minPeople: 1,
        maxPeople: parseInt(this.activityNumber),
        clubId: this.$route.params.clubId ? this.$route.params.clubId : null,
        peopleType: this.peopleType,
        description: this.activityContent,
        labelRequestVoList: [this.activityType],
        publishType: 3,
        urlRequestVoList: listDatas,
      };
      try {
        let sendRes = await this.$request.post('/activityReviewInfo/publishActivity', data);
        if( sendRes.code === 200 ){
          this.$message.success({
            showClose: true,
            message: '成功发布活动'
          });
          eventBus.$emit('haveConfirm',0)
          this.$router.push({ name: 'ClubActivity' });
          loading.close()	
        }
      } catch (e) {
        this.$message.warning({
          message: "发布失败",
          type: "error",
        })
        loading.close()	
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .content-box{
    width: 790px;
    min-height: 1072px;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    .activity-title{
      font-size: 18px;
      font-weight: bold;
      color: #000000;
    }
    .default-top {
      margin-top: 20px;
    }
    .title-line{
      display: flex;
      .tags{
        font-weight: bold;
        color: #EC4747;
      }
      .title-text{
        font-size: 14px;
        font-weight: bold;
        color:#000000;
        margin-bottom: 5px;
      }
      .cover-template{
        font-size: 12px;
        color: #1465cf;
        cursor: pointer;
        margin-top: 2px;
        margin-left: 5px;
      }
      .tips{
        margin-left: 10px;
        margin-top: 3px;
        font-size: 12px;
        color: #888888;
      }
    }
    .radio-button-line {
      display: flex;
      align-items: center;
      .radio-button{
        display: flex;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        margin-right: 30px;
        .button-unchoice{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid #DDDDDD;
          margin-right: 10px;
          margin-top: 2px;
          .button-choice{
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: #1465CF;
          }
        }
        .button-text{
          font-size: 14px;
          text-align: center;
          color: #444444;
          line-height: 20px;
        }
      }
    }
    .upload-cover{
      .tips-pic-main {
        display: flex;
        flex-wrap: wrap;
        padding-top: 12px;
        .tips-pic-list {
          width: 115px;
          height: 115px;
          margin-right: 8px;
          margin-bottom: 8px;
          box-sizing: border-box;
          position: relative;
          .show-img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 5px;
          }
          .shuow-video {
            width: 100%;
            height: 100%;
          }
          .del-img {
            position: absolute;
            right: -7px;
            top: -10px;
            z-index: 99;
          }
          .delete-icon{
            position: absolute;
            right: -5px;
            top: -6px;
            width: 18px;
            height: 18px;
            z-index: 99;              
          }
        }
        .up-pic {
          border: 1px dashed #DDDDDD;
          border-radius: 5px;
          cursor: pointer;
          position: relative;
          .up-icon {
            font-size: 40px;
            color: #DDDDDD;
            margin-top: 25px;
            margin-left: 35px;
          }
          .upload-text{
            font-size: 14px;
            text-align: center;
            color: #a8a8a8;
            margin-top: 5px;            
          }
        }
      }
    }
    .topics-input{
      width: 480px !important;
      position: relative;
      .text-statistic{
        position: absolute;
        top: 9px;
        right: 10px;
        font-size: 12px;
        color: #a8a8a8;
        z-index: 99;
      }
    }
    .activity-content{
      .content-input{
        width:480px;
        height: 120px;
        position: relative;
        border: 1px solid #ddd;
        border-radius: 5px;
        .text-statistic{
          position: absolute;
          bottom: 9px;
          right: 10px;
          font-size: 12px;
          color: #a8a8a8;
          z-index: 99;
        }
      }
    }
    .deadline-choice{
      display: flex;
      .divided{
        width: 10px;
        height: 1px;
        margin-left: 10px;
        margin-right: 15px;
        margin-top: 3px;
      }
    }
    .activity-choice{
      display: flex;
      .divided{
        width: 10px;
        height: 1px;
        margin-left: 10px;
        margin-right: 15px;
        margin-top: 3px;
      }
      .divided-text{
        font-size: 14px;
        color: #333333;
        margin: 5px 10px 0 10px;
      }
    }
    .searchLocations{
      position: relative;
    }
    .submit-btn{
      width: 120px;
      height: 40px;
      background: #1465CF;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      margin: 50px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  
  .border{
    border: 1px solid rgb(64, 158, 255) !important;
  }
	.box-card{
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .2);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.box-view-top{
			box-sizing: border-box;
			width: 480px;
			height: 381px;
			background: #FFFFFF;
			border-radius: 10px;
			overflow: hidden;
			position: relative;
			.title{
				margin-top: 20px;
				display: flex;
				.title-text{
					width: 100%;
					margin-left: 200px;
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: bold;
					color: #000000;
					line-height: 25px;;
				}
				.close{
					width: 15px;
					height: 15px;
					cursor: pointer;
				}
      }
      .default-cover-list{
        display: flex;
        flex-wrap: wrap;
        margin: 20px 10px 10px 20px;
        .default-cover-img{
          cursor: pointer;
          margin-bottom: 10px;
          box-sizing: border-box;
          margin-right: 10px;
          position: relative;
          .cover-img{
            box-sizing: border-box;
            width: 210px;
            height: 113px;
            border-radius: 5px;
          }
          .nochoice{
            position: absolute;
            left: 150px;
            top: 10px;
            width: 20px;
            height: 20px;
            z-index: 999;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 20px;
              height: 20px;
              background: #1465CF ;
              border-radius: 50%;
              z-index: 99;              
            }
          }
        }
      }
			.button-box{
        margin-right: 55px;
				.confirm{
					width: 100px;
					height: 30px;
					background:#1465CF;
					border-radius: 5px;
					cursor: pointer;
					margin-right: 130px;
					.confirm-text{
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
						padding: 4px 35px;
					}
				}
			}
		}
	}
</style>

<style lang="scss">
.el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 5px;
  box-sizing: border-box;
  color: #333333;
  display: inline-block;
  font-size: inherit;
  height: 34px;
  line-height: 40px;
  outline: 0;
  padding: 0 40px 0 7px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 140px;
}
.el-input--small .el-input__inner {
  height: 32px;
  width: 150px;
  line-height: 32px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: auto;
}
.el-select-dropdown__item {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 40px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
} 
.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 6px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: none;
  border-radius: 4px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  min-height: 60px;
}
/* 谷歌 */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
/* 火狐 */
 input{
    -moz-appearance:textfield;
}
.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all .3s;
    line-height: 30px;
}
.el-cascader-menu__list {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 6px 0 20px 0;
  list-style: none;
  box-sizing: border-box;
}
</style>