<template>
	<div class="home">
		<!-- 导航栏 -->
		<div class="home-top">
			<navbar-card :showLink="current" ></navbar-card>
		</div>
		<transition name="fade-transform" mode="out-in">
			<router-view/>
		</transition>
		
		<div class="footer">
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import { eventBus } from '../main.js'
	import navbarCard from '../components/navbar-card.vue'
	import Footer from '../components/Footer.vue';
	export default {
		name: 'Home',
		
		data(){
			return{
				current: 0
			}
		},

		watch:{
			$route:{
				handler(val){
					if(val.name == 'DetailDynamic'){
						this.current = 1
					}
					else if(val.matched[1].name == 'Article'){
						this.current = 2
					}
					else if(val.name == 'ClubRecommendation' || val.name == 'ClubDetail' || val.name == 'ClubMoment'){
						this.current = 3
					}
					else {
						this.current = 3
					}
				},
			}
		},
		
		components:{
			navbarCard,
			Footer
		},
		
		beforeCreate () {
			document.querySelector('body').setAttribute('style', 'margin: 0 auto; width: 100%; background:#eff3f6;margin-bottom: 0px;');
		},

		async created(){
			const token = window.localStorage.getItem('imlatte_token')
			if (token != null) {
				try{
					let res = await this.$request.post(`/userInfo/personalInfo`)
					this.$store.commit("SET_USER", res.data)
					this.userInfo = res.data
				} catch(e) {
					this.$store.commit('CLEAR_STORAGE')
				}
			} else {
				//
			}
		},

		mounted() {
			if(this.$route.matched[1].name == 'Moment' || this.$route.matched[1].name == 'DetailDynamic'){
				this.current = 1
			} else if(this.$route.matched[1].name == 'Article'){
				this.current = 2
			} else if(this.$route.matched[1].name == 'ClubRecommendation' || this.$route.matched[1].name == 'ClubDetail'){
				this.current = 3
			} 
			else {
				this.current = 99
			}
		},
		
		methods:{
			// 获取用户在俱乐部的权限
			async getMyClubRole() {
				try {
					let res = await this.$request.get(`/clubMemberInfo/getMyRole/${this.$route.params.clubId}`)
					let data = {
						clubId: this.$route.params.clubId,
						role: 0,
						canEditNews: false,
						canEditActivity: false,
						canEditMember: false,
						canEditTheme: false,
						canEditData: false,
						canEditClub: false
					}
					if(res.data.role) {
						this.myRole = res.data.role
						this.clubRoles = res.data.roles
						data.role = res.data.role
						for(let i = 0; i < res.data.roles.length; i++) {
							if(res.data.roles[i].roleId == 2) {
								// 俱乐部动态管理
								data.canEditNews = true
							}
							else if(res.data.roles[i].roleId == 3) {
								// 俱乐部活动管理
								data.canEditActivity = true
							}
							else if(res.data.roles[i].roleId == 4) {
								// 俱乐部成员管理
								data.canEditMember = true
							}
							else if(res.data.roles[i].roleId == 5) {
								// 俱乐部相册管理
								data.canEditTheme = true
							}
							else if(res.data.roles[i].roleId == 6) {
								// 编辑俱乐部信息
								data.canEditClub = true
							}
							else if(res.data.roles[i].roleId == 7) {
								// 查看俱乐部数据
								data.canEditData = true
							}
						}
					}
					if(res.data.role===1){
						eventBus.$emit('roleChanges',1)
					}
					this.$store.commit('SET_CLUBROLES', data)
				} catch (error) {
					//
				}
			}			
		}
	}
</script>

<style lang="scss" scoped>
	a{
		text-decoration: none;
	}
	.home{
		padding-bottom: 200px;
		box-sizing: border-box;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
    background: #F7F8FA;
		height: auto;
		min-height: 1050px;
		.home-top{
			width: 100%;
		}
	}
	.footer{
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	/* 
	enter-active 定义进入过渡的结束状态
	leave-active 定义离开过渡的结束状态
	*/
	.fade-transform-leave-active,
	.fade-transform-enter-active {
		transition: all 0.5s;
	}

	/* 
	enter定义进入过渡的开始状态
	*/
	.fade-transform-enter {
		opacity: 0;
		transform: translateY(-30px);
	}
	/* 
	leave-to离场动画结束后的状态
	*/
	.fade-transform-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}
</style>
