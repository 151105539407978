<template>
	<div class="box-card"  v-if="flag">
		<span class="box-view-top">
			<div class="title">
				<span class="title-text">提示</span>
				<icon-font name="close" color="#444444" size="16px"  @click="close" style="cursor: pointer;margin-right: 15px;margin-top: -40px"></icon-font>
			</div>
			<div class="type-box" v-loading="loading">
				<div class="type" v-for="(item,index) in reportTypeList" :key="index">
					<span @click="chooseType(item.id, item.typeName)" class="label" :class="item.id === currentId ? 'box-click' : '' " >{{item.typeName}}</span>
				</div>
			</div>
			<div class="button-box">
				<div class="cancel">
					<div class="cancel-text" @click="close">取消</div>
				</div>
				<div class="confirm">
					<div class="confirm-text" @click="submit">提交</div>
				</div>	
			</div>			
		</span>
	</div>
</template>

<script>
import iconFont from '../IconFont'
export default {
	data() {
		return {
			loading: false,
			description: '',
			getReply:[],
			reportTypeList: [],																						// 投诉类型	
			id: null,																											// 被投诉对象的ID	
			currentId: null,
			reportObj: {
				reoportName: null,
				reportId: null,
				remark: null,
				type: null,
				contentCover: null,
				contentId:null,
				userAvatar: null,
				userId: null,
				userName: null,
			},	
		}
	},
	components:{
		iconFont,
	},
	props:{
		flag:{
			type: Boolean,
			default: false
		},
		item: {
			type: Object,
			default: () => {}
		},
		isClub:{
			type: Boolean,
			default: false
		},
		detail: {
			type: Object,
			default: () => {}
		},
	},

	watch: {
		flag(newVal) {
			if(newVal == true) {
				this.getReportType()
			}
		},
	},

	methods:{
		//关闭投诉框
		close(){
			this.$emit('close')
		},

		//提交投诉
		async submit(){
			if(!this.currentId){
				this.$message.warning("请选择投诉类型！！")
			}
				if(!this.isClub){
				let reportRes
				this.reportObj.reoportName = this.reoportName
				this.reportObj.reportId = this.currentId
				this.reportObj.type = 1
				this.reportObj.contentCover	= this.item.urlResponseVoList[0].url,
				this.reportObj.contentId	= this.item.newsId,
				this.reportObj.userAvatar	= this.item.avatarUrl,
				this.reportObj.userId	= this.item.userId,
				this.reportObj.userName	= this.item.nickname,
				reportRes = await this.$request.post('/reportInfo/report', this.reportObj)
					if(reportRes.code === 200){
						this.$message.success("你已投诉成功")
						this.close()
					}
				}
				else{
					let reportRes
					this.reportObj.reoportName = this.reoportName
					this.reportObj.reportId = this.currentId
					this.reportObj.type = 2
					this.reportObj.contentCover	= this.detail.urlResponseVoList[0].url,
					this.reportObj.contentId	= this.detail.activityId,
					this.reportObj.userAvatar	= this.detail.avatarUrl,
					this.reportObj.userId	= this.detail.userId,
					this.reportObj.userName	= this.detail.nickname,
					reportRes = await this.$request.post('/reportInfo/report', this.reportObj)
						if(reportRes.code === 200){
							this.$message.success("你已投诉成功")
							this.close()
						}				
				}
		},

		//获得投投诉类型
		async getReportType() {
			this.loading = true
			try {
				let res = await this.$request.get('/reportType/getType')
				this.reportTypeList = res.data
			} catch(e){
				//error
			}
			this.loading = false
		},

		//选择投诉类型
		chooseType(id, name){
			this.currentId = id
			this.reoportName = name
		},
	}
}
</script>

<style lang="scss" scoped>
	.box-card{
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .2);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.box-view-top{
			box-sizing: border-box;
			width: 480px;
			height: 230px;
			background: #FFFFFF;
			border-radius: 10px;
			overflow: hidden;
			position: relative;
			.title{
				margin-top: 20px;
				display: flex;
				.title-text{
					width: 100%;
					margin-left: 229px;
					margin-bottom: 30px;
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: bold;
					color: #000000;
					line-height: 25px;;
				}
				.close{
					width: 15px;
					height: 15px;
					cursor: pointer;
				}
			}
			.type-title{
				width: 154px;
				height: 20px;
				font-size: 14px;
				font-weight: 400;
				color: #444444;
				line-height: 20px;
				margin-left: 40px;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			.type-box{
				display: flex;
				justify-content: center;
				align-content: center;
				flex-wrap: wrap;
				box-sizing: border-box;
				margin-left: 12px;
				.type{
					box-sizing: border-box;
					margin-right: 20px;
					.label{
						box-sizing: border-box;
						font-size: 14px;
						font-weight: 400;
						color: #444444;
						line-height: 20px;
						border-radius: 5px;
						border: 1px solid #979797;
						cursor: pointer;
						padding: 10px 32px;
					}
				}
				.type:nth-child(1),
				.type:nth-child(2),
				.type:nth-child(3){
					margin-bottom: 30px;
				}
			}
			.introduction{
				font-size: 12px;
				font-weight: 500;
				color: #444444;
				line-height: 17px;
				margin-left: 40px;
				.textarea{
					width: 442px;
					font-size: 16px;
					color: #999;
					margin-top: 10px;
					outline: none;                   
				}
			}
			.button-box{
				display: flex;
				justify-content: center;
				margin-top: 10px;
				.confirm{
					width: 100px;
					height: 30px;
					background: #EC4747;
					border-radius: 5px;
					cursor: pointer;
					margin-right: 130px;
					.confirm-text{
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
						padding: 6px 35px;
					}
				}
				.cancel{
					width: 100px;
					height: 30px;
					border: 1px solid #DDDDDD;
					border-radius: 5px;
					margin: 25px auto;
					margin-left: 135px;
					cursor: pointer;
					.cancel-text{
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #444444;
						line-height: 20px;
						padding: 6px 35px;
					}
				}
			}
		}
	}
	.box-click {
		background-color: #fff;
		border: 1px solid #1465CF!important;
	}
</style>
