<template>
  <div class="content-box">
    <div v-if="!selectheirs">
      <div class="top-card">
        <div class="card-title">
          <div class="title-left">全部成员</div>
          <div class="title-right">
            <div class="member-number">成员{{ this.allmember }}人 / 粉丝{{ this.fansObj.list.length }}人</div>
            <div class="more-roles" @click.stop="openChoice" v-if="this.$store.state.clubRoles.role === 3">
              <el-image
              class="images" 
              :src="require('@/assets/more-roles.png')"
              fit="cover">
              </el-image>
            </div>
          </div>
        </div>
        <div class="sign-out" :class="showOut?'show-out':''">
          <div class="edit-information" @click.stop="permissionTransfer()"><span class="out-text">创建者权限转让</span></div>
          <div class="delete-image" @click.stop="dissolutionClub()"><span class="out-text">解散俱乐部</span></div>
        </div>
        <div class="card-creater" v-for="(item) in ownList" :key="item.id">
          <div class="creater-avatar">
            <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="50"></img-head>	
          </div>
          <div class="creater-name">{{ item.nickname }}</div>
          <div class="creater-name">创建者</div>        
        </div>
      </div>
      <div class="manage-card" v-loading="loadingMember" v-if="this.manageList.length!=0">
        <div class="fans">管理员（{{ this.manageList.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in manageList" :key="index">
            <div class="fans-image">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
          </div>
        </div>
      </div>
      <div class="group-box" v-if="this.groupMember.length!=0">
        <div class="group-card" v-loading="loadingMember"  v-for="(item, index) in groupMember" :key="index">
          <div class="fans">{{ item.groupName }}（{{ item.groupMember.length }}人）</div>
          <div class="fans-box">
            <div class="fans-detail" v-for="(items, indexs) in item.groupMember" :key="indexs">
              <div class="fans-image">
                <img-head :avatarUrl="items.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ items.groupNickname }}</div>
            </div>
          </div>
        </div>      
      </div>
      <div class="member-card" v-loading="loadingMember" v-if="this.memberList.length!=0">
        <div class="fans">成员（{{ this.memberList.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in memberList" :key="index">
            <div class="fans-image">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
          </div>
        </div>
      </div>
      <div class="fans-card" v-loading="loadingMember" v-if="this.fansObj.list.length!=0">
        <div class="fans">粉丝（{{ this.fansObj.list.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in fansObj.list" :key="index">
            <div class="fans-image">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="top-card">
        <div class="card-title">
          <div class="title-left">全部成员</div>
          <div class="title-right">
            <div class="member-number">{{ this.allmember-1 }}人</div>
          </div>
        </div>
      </div>
      <div class="manage-card" v-loading="loadingMember" v-if="this.manageList.length!=0">
        <div class="fans">管理员（{{ this.manageList.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in manageList" :key="index" @click="choiceAdmin(item)" >
            <div class="fans-image">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
            <div class="nochoices" >
              <div class="content">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/unchoicePhoto.png')"
                  fit="cover">
                </el-image>                  
              </div>
              <div class="contents" v-if="item.checked">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/choicePhoto.png')"
                  fit="cover">
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group-box" v-if="this.groupMember.length!=0">
        <div class="group-card" v-loading="loadingMember"  v-for="(item, index) in groupMember" :key="index">
          <div class="fans">{{ item.groupName }}（{{ item.groupMember.length }}人）</div>
          <div class="fans-box">
            <div class="fans-detail" v-for="(items, indexs) in item.groupMember" :key="indexs" @click="choiceGroup(items)">
              <div class="fans-image">
                <img-head :avatarUrl="items.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ items.groupNickname }}</div>
              <div class="nochoices" >
                <div class="content">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/unchoicePhoto.png')"
                    fit="cover">
                  </el-image>                  
                </div>
                <div class="contents" v-if="items.checked">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/choicePhoto.png')"
                    fit="cover">
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>      
      </div>
      <div class="member-card" v-loading="loadingMember" v-if="this.memberList.length!=0">
        <div class="fans">成员（{{ this.memberList.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in memberList" :key="index"  @click="choiceNogroup(item)">
            <div class="fans-image">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
            <div class="nochoices" >
              <div class="content">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/unchoicePhoto.png')"
                  fit="cover">
                </el-image>                  
              </div>
              <div class="contents" v-if="item.checked">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/choicePhoto.png')"
                  fit="cover">
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-box" >
        <div class="cancel">
          <div class="text" @click="cancelRemove">取消</div>
        </div>
        <div class="unadd" v-if="!buttonDisable">
          <div class="text">转让</div>
        </div>
        <div class="add" v-else>
          <div class="text" @click="isRemoveAdmin">转让</div>
        </div>
      </div> 
    </div>
     
    <!-- 提示弹窗 -->
    <tip-popup :title="this.titles" :isOpen="isOpen" @close="closePopup" @confirm="confirm"></tip-popup>
    
  </div>
</template>

<script>
import jpg from "@/assets/default-avatar.png"
import tipPopup from '../../components/tip-popup.vue'
import imgHead from '../../components/img-head.vue'
import { eventBus } from '../../main.js'
export default {
  data() {
    return {
      imgUrl: jpg,                                   // 加载不出的图片
      showOut: false,                                // 显示更多操作窗口
      clubRoles: 2,                                  // 俱乐部权限
      loadingMember: false,                          // 是否显示加载
      manageList: [],                                // 俱乐部管理员      
      memberList: [],                                // 俱乐部成员
      ownList: {},                                   // 俱乐部创建者
      groupMember: [],                               // 俱乐部分组信息
      allmember: 0,                                  // 俱乐部除粉丝以外人员总数        
      fansObj: {                                     // 俱乐部粉丝列表
        list: [],
        totalCount: 0,
        pageIndex: 1,
        nomore: false,
      },
      titles: '',                                     // 弹窗提示标题
      isOpen: false,                                  // 是否打开提示弹窗
      selectheirs: false,                             // 是否创建者权限转让
      buttonDisable: false,                           // 是否高亮转移按钮
      selectedid: false,                              // 记录已选中成员
    }
  },
  components:{
    tipPopup,
    imgHead
  },

  beforeMount() {
    eventBus.$on("closeEditMode", () => {
      this.cancelRemove()
		})
  },

  async mounted() {
    this.searchMember()
    this.searchFans()
    this.clubRoles = this.$store.state.clubRoles.role
    document.addEventListener('click', this.HiddenClick,false)
  },

  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,false)
  },

  methods:{
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      if(this.showOut){
        this.showOut = false
      }
    },

    // 打开图片权限窗口
    openChoice() {
      this.showOut = !this.showOut
    },

    // 查询俱乐部创建者、管理员、成员
    async searchMember() {
      try {
        let data = {
          clubId: this.$route.params.clubId,
        }
        let res = await this.$request.post("/clubMemberInfo/selectMemberGroupList", data)
        if (res.code === 200) {         
          this.memberList = res.data.notGroupMember
          this.manageList = res.data.managers
          this.groupMember = res.data.groupMember
          this.allmember = res.data.memberCount 
          this.ownList[0] = res.data.create
        }
      } catch (e) {
				//
			}
    },

    // 查询俱乐部粉丝
    async searchFans() {
      this.loadingMember = true
      let data = {
        clubId: this.$route.params.clubId,
        pageIndex: this.fansObj.pageIndex,
        pageSize: 80,
      };
      try {
        let res = await this.$request.post("/clubFansInfo/selectAllFans", data);
        this.fansObj.list = res.page.list
        if(res.page.totalPage == res.page.pageIndex) {
          this.fansObj.nomore = true
        }
        else {
          this.fansObj.nomore = false
          this.fansObj.pageIndex++
        }
        this.canLoading = true
      } catch (e) {
        //TODO handle the exception
      }
      this.loadingMember = false
    },

    // 解散俱乐部功能 
    dissolutionClub() {
      if(this.clubRoles==2){
        this.$message.warning({
          showClose: true,
          message: "无法做此操作",
        })
        return
      }
			this.isOpen = true
      this.titles = "俱乐部解散之后无法恢复,确定要解散吗？"
    },

    // 俱乐部权限转让功能 
    permissionTransfer() {
      if(this.clubRoles==2 || this.allmember-1 ==0){
        this.$message.warning({
          duration: 10000,
          showClose: true,
          message: "无法做此操作",
        })
        return
      }

      this.selectheirs = true
      this.showOut = false
    },

    // 名单-判断是否已有选中
    judgmentChoice() {
      for(let items of this.manageList){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            this.buttonDisable = true
            return 
          }else{
            this.buttonDisable = false
          }    
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      }
    },    

    // 权限转让-点击管理员
    choiceAdmin(item) {
      if(this.selectedid == item.userId){
        item.checked = !item.checked
        this.judgmentChoice() 
        return
      }
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }  
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      item.checked = !item.checked
      this.judgmentChoice() 
      this.selectedid = item.userId
    },

    // 权限转让-点击group
    choiceGroup(items) {
      if(this.selectedid == items.userId){
        items.checked = !items.checked
        this.judgmentChoice() 
        return
      }
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }  
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      items.checked = !items.checked
      this.judgmentChoice() 
      this.selectedid = items.userId
    },

    // 权限转让-点击nogroup
    choiceNogroup(item) {
      if(this.selectedid == item.userId){
        item.checked = !item.checked
        this.judgmentChoice() 
        return
      }
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }  
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      item.checked = !item.checked
      this.judgmentChoice() 
      this.selectedid = item.userId
    },

    // 确认移除弹窗
    isRemoveAdmin() {
			this.isOpen = true
      this.titles = "您将失去俱乐部创建者身份,是否确定转让？"
    },

    // 取消权限转让人员选择
    cancelRemove() {
      this.selectheirs = false
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      this.buttonDisable = false      
    },

    // 关闭删除动态弹窗
		closePopup() {
      this.isOpen = false
      this.showOut = false
    },

    // 确认创建者权限转让
    async transferCreator() {
			let data = {
        clubId:this.$route.params.clubId,
        userId:this.selectedid
			}
      try {
        let res = await this.$request.post('/clubMemberInfo/changeCreater', data)
        if(res.code === 200){
          this.$message.success({
            showClose: true,
            message: "转让成功",
          })
          this.selectheirs = false
          this.searchMember();
          this.searchFans();
        }
        if(res.code === 2015){
          this.$alert('该用户已经是其他俱乐部的创建者了','提示', {
            confirmButtonText: '确定',
          })	
        }
      } catch(e) {
        // 
      }
    },

    // 确认解散俱乐部
    async confirmDisbandClub() {
      try{
        let res = await this.$request.post('/clubInfo/disbandClub', {id:this.$route.params.clubId})
        if(res.code === 200){
          this.$message.success({
            showClose: true,
            message: "俱乐部已解散",
          })
          this.$router.push( {name: 'Home'} )
        }
        if(res.code === 2016){
          this.$alert('俱乐部需要在解散前30天内无任何活动或账','提示', {
            confirmButtonText: '确定',
          })	
        }
      }catch(e){
        // 
      }
    },

    // 确认回调事件
    confirm() {
      if(this.titles=="俱乐部解散之后无法恢复,确定要解散吗？"){
        this.confirmDisbandClub()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
      if(this.titles=="您将失去俱乐部创建者身份,是否确定转让？"){
        this.transferCreator()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
    },
    
  }
}
</script>

<style lang="scss" scoped>
  .content-box {
    width: 790px;
    min-height: 780px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    .top-card{
      position: relative;
      .card-title{
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .title-left {
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #000000;
          line-height: 25px;
        }
        .title-right {
          display: flex;
          align-items: center;
          .member-number{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            margin-bottom: 5px;
            margin-right: 20px;
          }
          .more-roles{
            cursor: pointer;
            .images{
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .sign-out{
        cursor: pointer;
        width: 120px;
        height: 0;
        background: #fff;
        border-radius: 5px;
        position:absolute;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
        right: -52px;
        z-index: 99;
        //修改页面抖动
        top: 28px;
        box-sizing: border-box;
        transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .edit-information{
          margin-bottom: 5px;
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .edit-information:hover{
          color: #1465CF !important;
        }
        .delete-image{
          margin-bottom: 5px;
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .delete-image:hover{
          color: #1465CF !important;
        }
        .delete-album{
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .delete-album:hover{
          color: #1465CF !important;
        }
      }
      .show-out{
      height: 70px;
      box-shadow: 0px 2px 10px 0px undefined; 
      }
      .card-creater{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 15px;
        .creater-avatar{
          .images{
            width: 60px;
            height: 60px;
            border-radius: 50%; 
          }
        }
        .creater-name{
          margin-top: 5px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: center;
          color: #444444;
          line-height: 22px;
        }
      }
    }
    .manage-card{
      padding-top: 10px;
      position: relative;
      .fans{
        margin-bottom: 20px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #444444;
        line-height: 22px;
      }
      .fans-box{
        display: flex;
        flex-wrap: wrap;
        .fans-detail{
          display: flex;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          height: 85px;
          width: 60px;
          margin-right: 20px;
          margin-bottom: 20px;
          .fans-image{
            .images{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .fans-name{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
          .nochoices{
            position: absolute;
            left: 17px;
            top: 40px;
            width: 20px;
            height: 20px;
            z-index: 99;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 18px;
              height: 18  px;
              border-radius: 50%;
              z-index: 99;
            }
          }
        }
      }
    }
    .manage-card:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .group-box {
      .group-card{
        padding-top: 10px;   
        position: relative;  
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          .fans-detail{
            display: flex;
            flex-direction: column;
            cursor: pointer;
            position: relative;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            .fans-image{
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
            }
            .nochoices{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 99;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                z-index: 99;
              }
            }
          }
        }
      }
      .group-card:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #dddddd;
        transform: scaleY(0.5);
        transform-origin: center top;
        z-index: 2;
      }
    }
    .member-card{
      padding-top: 10px;
      position: relative;
      .fans{
        margin-bottom: 20px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #444444;
        line-height: 22px;
      }
      .fans-box{
        display: flex;
        flex-wrap: wrap;
        .fans-detail{
          display: flex;
          flex-direction: column;
          position: relative;
          cursor: pointer;
          height: 85px;
          width: 60px;
          margin-right: 20px;
          margin-bottom: 20px;
          .fans-image{
            .images{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .fans-name{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            width: 52px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
          .nochoices{
            position: absolute;
            left: 17px;
            top: 40px;
            width: 20px;
            height: 20px;
            z-index: 99;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              z-index: 99;
            }
          }
        }
      }
    }
    .member-card:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .fans-card{
      padding-top: 10px;
      position: relative;
      .fans{
        margin-bottom: 20px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #444444;
        line-height: 22px;
      }
      .fans-box{
        display: flex;
        flex-wrap: wrap;
        .fans-detail{
          display: flex;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          height: 85px;
          width: 60px;
          margin-right: 20px;
          margin-bottom: 20px;
          .fans-image{
            .images{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .fans-name{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            width: 52px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
          .nochoices{
            position: absolute;
            left: 17px;
            top: 40px;
            width: 20px;
            height: 20px;
            z-index: 99;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              z-index: 99;
            }
          }
        }
      }
    }
    .fans-card:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .buttons-box{
      position: absolute;
      bottom: 0;
      left: 310px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .cancel{
          width: 100px;
          height: 30px;
          background: #F7F8FA;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;  
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
            padding: 5px 35px;
          }
        }  
      .add{
          width: 100px;
          height: 30px;
          background:#EC4747;
          border-radius: 5px;
          cursor: pointer;  
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            padding: 5px 35px;
          }
        }
      .unadd{
          width: 100px;
          height: 30px;
          background: #A8A8A8;
          border-radius: 5px;  
          cursor: pointer;  
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            padding: 5px 35px;
          }
      }
    } 
  }
</style> 