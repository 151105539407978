<template>
  <div class="club-detail" v-if="info">
    <div class="club-head-left">
      <div class="club-head-top">
        <el-image :size="80" fit="cover" :src="$cosUrl + info.url" style="border-radius: 20px;height: 80px;width: 80px"></el-image>
        <div class="top-head">
          <div class="name-edit">
            <span class="club-name">{{ info.clubName }}</span>
            <div class="editImg" v-if="roles? roles.canEditClub : false" @click="openEdit">
              <el-image
                class="addClass el-image-more" 
                :src="require('@/assets/edit.png')"
                fit="cover"
              ></el-image>
            </div>
          </div>
          <div class="club-head-label">{{ info.clubLabelInfoResponseVos[0].labelName }}</div>
        </div>
      </div>
      <div class="club-head-middle">
        <div class="name-introduction">
          <div class="club-introduction">{{ info.introduction ? info.introduction : "还未填写任何说明嗷~" }}</div>
          <div class="showDetail">
            <el-popover
              placement="bottom"
              width="300"
              trigger="click"
              :content='info.introduction'>
              <span slot="reference">{{ info.introduction && info.introduction.length>42 ? "查看" : "" }}</span>
            </el-popover>
          </div>
        </div>
        <div class="club-time">入驻时间：{{ info.createTime.substring(0,10).replace(/-/g, "/") }}</div>
        <div class="address-box">
          <div class="club-address">俱乐部地址：{{ info.address.split('|')[1] }}</div>
          <div class="showclubDetail">
            <el-popover
              placement="bottom"
              width="250"
              trigger="click"
              :content='info.address.split(`|`)[1]'>
              <span slot="reference">{{ info.address && (info.address.split('|')[1]).length>11 ? "查看" : "" }}</span>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="club-head-right">
      <el-image class="club-bg" :src="info.clubBackgroundUrl ? $cosUrl + info.clubBackgroundUrl.url : $cosUrl + info.url " fit="cover"></el-image>
      <span class="club-views-bg" @click="chooseBackground" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditClub) || this.$store.state.clubRoles.role == 3">
        <span class="photo" style="box-sizing: border-box;margin-top: 5px">
          <el-image :src="require('@/assets/uploadPhoto.png')" fit="contain" style="height: 12px;width: 12px;margin-right: 7px;"></el-image>
        </span>
        <span class="club-views">上传俱乐部背景</span>
      </span>
      <div class="hide"></div>
    </div>

    <!-- 编辑资料 -->
		<div class="edit-popup-wrap" v-if="showActivityType">
      <div class="edit-popup">
        <div class="close">
          <icon-font name="close" color="#A8A8A8" size="12px" @click="closePopup"></icon-font>
        </div>
        <div class="edit-title">
          <span class="type-title">编辑俱乐部资料</span>
        </div>
        <div class="edit-detail">
          <div class="edit-left">
            <div class="img-avatar">
              <el-image
                class="upload"
                :src="currentAvatar ? currentAvatar : $cosUrl + editInfos.url"
                fit="cover">
              </el-image>
            </div>
            <div class="img-intro1">上传俱乐部头像</div>
            <div class="img-intro2">(支持jpg、png格式)</div>
            <div class="upload-btn" @click="chooseAvatar">
              <span>上传</span>
            </div>
          </div>
          <div class="edit-right">
            <div class="inputContent">
              <el-input  class="input-theme"  :value="editInfos.clubName"  placeholder="俱乐部主题" @input="getThemeLength" ></el-input>
              <span class="text-statistics">
                {{ this.clubNameStatistics }}/15
              </span>
            </div>
            <div class="edit-introduction">
              <!-- <textarea name="" id="" cols="30" :value="editInfos.introduction" rows="3"  placeholder="俱乐部介绍" @input="getIntroduvtionLen" style="border:none"/> -->
              <div style="border-radius:5px;border: 1px solid #ddd;width: 310px;height: 100px;">
                <el-input  type="textarea"  class="input-intro" :value="editInfos.introduction" rows="3"  placeholder="俱乐部介绍" @input="getIntroduvtionLen"></el-input>
              </div>
                <span class="text-statistics">
                  {{ this.clubIntroStatistics }}/300
                </span>
            </div>
            <div class="label-line">
              <div class="label" @click="openMenus">
                <span class="label-title">标签</span>
                <span class="label-item">
                  <span class="labels-text">{{ editInfos.clubLabelInfoResponseVos[0].labelName }}</span>
                </span>
                  <icon-font class="arrows" name="arrow-up" size="12px" color="#000000" v-if="openMenu"></icon-font>
                  <icon-font class="arrows" name="arrow-down" size="12px" color="#000000" v-else></icon-font>                          
              </div>
            </div>
            <div class="label-box-item" v-loading="loading" v-if="openMenu">
              <div class="label-box-mian" v-for="(items) in activityTypeList" :key="items.id">
                <div class="box-main-title">{{ items.labelName }}</div>
                <div class="box-main-item">
									<div v-for="(itemChild) in items.labelInfoResponseVoList" :key="itemChild.id">
										<div class="label-style" v-if="itemChild.isChoose == 1" :class="itemChild.id == choiceactivityType ? 'active' : ''" @click="choiceType(itemChild)">
											{{ itemChild.labelName }}
										</div>
									</div>
                </div>
              </div>
            </div>
            <div class="clubDress">
							<div class="drees-father-box">
								<span class="dress-text">地址</span>
								<div class="input-dress" @click="openAddressInput" @keyup.enter="searchAddress()">
									<input type="text" style="border:1px solid #dddddd;height: 34px;width: 269px;border-radius: 5px;padding-left:5px" class="dress-input"  v-model="inputAddress" placeholder="俱乐部地址"/>
									<div class="address-father-box" @click.stop="noNeverClick">
										<search-location :showLocationSearchList="showLocationSearchList" :address="inputAddress" :enterEvents="enterEvents" @show="show" @search="search"></search-location>
									</div>
								</div>
							</div>
            </div>
          </div>
        </div>
        <div class="confirm" @click="submit">
          <span>确定</span>
        </div>
        <input v-show="false" accept="image/jpg,image/jpeg,image/png,image/gif" type="file" id="uploadIMG" @change="fileChangeImg($event)" ref="inputImg" />
      </div>
    </div>

    <!-- 俱乐部投诉 -->
    <club-complaint :flag="iscomplaint" @close="closeComplaint" :detail="this.detail" :isClub="isClub"></club-complaint>
    
    <input v-show="false" accept="image/jpg,image/jpeg,image/png,image/gif" type="file" id="uploadIMG" @change="fileChangeImgs($event)" ref="inputImgs" />
	
    <!-- 提示弹窗 -->
    <tip-popup :title="tipsObj.title" :isOpen="tipsObj.isOpen" @close="closeTipsPopup" @confirm="confirmTips"></tip-popup>
    
  </div>
</template>

<script>
import clubComplaint from '@/components/club/club-complaint.vue';
import iconFont from "../../components/IconFont.vue";
import searchLocation from '../searchLocation.vue';
import tipPopup from '../../components/tip-popup.vue'
export default {
  data() {
    return {
      info: null,												// 俱乐部资料
      showActivityType: false,					// 显示编辑资料
      activityTypeList: [],							// 俱乐部标签
			releaseNameLen: null,							// 名称长度
			introductionLen: null,						// 介绍长度
      editInfos: null,									// 修改资料对象
			inputAddress: '',									// 收索地址值
      choiceactivityType: 0,						// 选中的标签
      loading: false,										// 是否加载
      currentAvatar: '',
      currentAvatarObj: null,
      iscomplaint: false,
      isClub: false,
      upPop: false,											// 加入俱乐部
      description: '',									// 加入俱乐部理由
      openMenu: false,									// 显示标签
      showLocationSearchList: false,		// 显示地理位置
      enterEvents: false,
			tipsObj:{													// 提示窗对象
				title: '提示',
				isOpen:false,
      },
      clubNameStatistics: 0,            // 统计俱乐部主题字数
      clubIntroStatistics: 0,           // 俱乐部介绍字数
      clubId: this.$route.params.clubId,// 俱乐部id
    }
  },

  components: {
    iconFont,
    clubComplaint,
    searchLocation,
		tipPopup
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    myRole: {
      type: Number,
      default: 1,
    },
    clubRoles: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    detail(newVal) {
			this.info = newVal
    }
  },

  computed:{
    //这里需要把store 动态的数据放到computed里面才会同步更新 视图
    roles(){
      return this.$store.state.clubRoles
    }
  },
	
	mounted(){
		this.addActivityType()
	},

  methods: {
    clickMessagePopover(index) {
      if(index == 0) {
        this.$router.push({name: 'ClubAdmin'})
      }
      else if(index == 1) {
        this.$alert('敬请期待', {
            confirmButtonText: '确定',
            customClass:'message_box_alert'
          }
        )
      }
      else if(index == 2) {
        this.$alert('敬请期待', {
            confirmButtonText: '确定',
            customClass:'message_box_alert'
          }
        )
      }
      else if(index == 2) {
        this.$alert('敬请期待', {
            confirmButtonText: '确定',
            customClass:'message_box_alert'
          }
        )
      }
      else if(index == 3) {
        this.iscomplaint = true
        this.isClub = true
      }
    },

    //关闭投诉
		closeComplaint() {
			this.iscomplaint = false
		},

    // 关注
    async postFollow() {
      try{
        let res = await this.$request.post('/clubFansInfo/followClub', {clubId: this.info.id})
        if(res.code === 200){
          this.$message({
						message: '关注成功',
						type: 'success'
          })
          this.$emit('refresh', true)
        }
      }catch(e){
        //
      }
    },

    // 取消关注
    async postUnFollow() {
      try{
        let res = await this.$request.post('/clubFansInfo/unfollowClub', {clubId: this.info.id})
        if(res.code === 200){
          this.$message({
						message: '已取关该俱乐部',
						type: 'success'
					})
          this.$emit('refresh', true)
        }
      }catch(e){
        //
      }
    },

    // 加入俱乐部
    async joinClub() {
      try{
        let data = {"clubId": this.info.id, "joinRemarks": this.description,}
        let res = await this.$request.post('/clubRecordInfo/applyToJoinClub', data)
        if(res.code === 200){
          this.$message({
						message: '提交成功',
						type: 'success'
          })
          this.upPop = false
        }
      }catch(e){
        //
      }
      this.$emit('refresh', true)
      // loading.close()
    },

    // 退出俱乐部
    async quitClub() {
      try{
        await this.$confirm('是否确定退出俱乐部?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        let data = {"clubId": this.info.id,"outRemarks": ""}
        let res = await this.$request.post('/clubRecordInfo/applyToQuitClub', data)
        if(res.code === 200){
          this.$message({
						message: '您已退出俱乐部',
						type: 'success'
					})
          this.$emit('refresh', true)
        }
      }catch(e){   
				//
      }
    },
		
		// 打开编辑俱乐部资料弹窗
    openEdit() {
      let data = JSON.parse(JSON.stringify(this.info))
      this.editInfos = data
			this.inputAddress = data.address.split('|')[1]
			this.choiceactivityType = data.clubLabelInfoResponseVos[0].labelId
			this.showActivityType = true
			this.currentAvatar = ''
			this.openMenu = false
      this.showLocationSearchList =  false
      this.clubNameStatistics = this.editInfos.clubName.length
      this.clubIntroStatistics = this.editInfos.introduction.length
      this.clubNameStatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.editInfos.clubName)).split("").map(val => val.charCodeAt()).length)/3)
    },
		
		// 关闭编辑资料弹窗
    closePopup() {
			if (this.editInfos.clubName != this.info.clubName) {
				this.tipsObj = {
					title: '俱乐部资料有改动，确定要退出吗？',
					isOpen:true
				}
			}else if (this.editInfos.introduction != this.info.introduction) {
				this.tipsObj = {
					title: '俱乐部资料有改动，确定要退出吗？',
					isOpen:true
				}
			} else if (this.editInfos.longitude != this.info.longitude) {
				this.tipsObj = {
					title: '俱乐部资料有改动，确定要退出吗？',
					isOpen:true
				}
			} else if (this.editInfos.clubLabelInfoResponseVos[0].labelId != this.info.clubLabelInfoResponseVos[0].labelId) {
				this.tipsObj = {
					title: '俱乐部资料有改动，确定要退出吗？',
					isOpen:true
				}
			} else if (this.editInfos.url  != this.info.url) {
				this.tipsObj = {
					title: '俱乐部资料有改动，确定要退出吗？',
					isOpen:true
				}
			} else {
				this.showActivityType = false
				this.currentAvatar = ''
			}
    },

		// 警告提示取消
		closeTipsPopup(){
			this.tipsObj.isOpen = false
    },
    
		// 警告提示确认
		confirmTips() {
			this.showActivityType = false
			this.currentAvatar = ''
			this.tipsObj.isOpen = false
		},

    // 查询活动类型
    async addActivityType() {
      this.loading = true
      try{
        let res = await this.$request.get('/labelInfo/labelInfoList?type=CLUB_LABEL_SCORE');
        if (res.code === 200) {
          let data = res.data[0].labelInfoResponseVoList
          for(let father of data){
            if(father.isChoose == 1){
              father.labelInfoResponseVoList.unshift({id:father.id, labelName: father.labelName, parentId: father.parentId, isChoose: 1})
            }
          }
          this.activityTypeList = data
        }
      } catch(e) {
        //
      }
      this.loading = false
    },

    // 选择标签
    choiceType(data) {
      this.editInfos.clubLabelInfoResponseVos[0] = {'labelId': data.id, 'labelName': data.labelName, 'parentId': data.parentId}
      this.choiceactivityType = data.id
    },

    // 选择头像
    chooseAvatar() {
      this.$refs.inputImg.click()
    },

    // 选择背景
    chooseBackground() {
      if(!this.roles.canEditClub){
        this.$message.warning("您暂无俱乐部权限")
        return
      }else{
        this.$refs.inputImgs.click()
      }
    },

    // 选择背景图片回调
    async fileChangeImgs(event) {
      let files = event.target.files
      let fileUrl = URL.createObjectURL(files[0])
      this.currentAvatar = fileUrl
      this.currentAvatarObj = files[0]
      let url = await this.uploadDatas()
      let data = {
        clubId: this.info.id,
        url: url
      }
      let res = await this.$request.post('/clubBackgroundUrl/editClubBackgroundUrl', data)
      if(res.code===200){
        this.$emit('refresh', true)
        this.$message({
          showClose: true,
          message: '俱乐部背景已更改',
          type: 'success'
        });
      }
    },

    // 选择头像图片回调
    async fileChangeImg(event) {
      let files = event.target.files
      let fileUrl = URL.createObjectURL(files[0])
      this.currentAvatar = fileUrl
      this.currentAvatarObj = files[0]
    },

    // 上传头像图片
    async uploadData() {
      return new Promise(async (resolve) => {
        await this.$credential(4)
        let uploadPath = await this.$uploadFile(4, this.currentAvatarObj)
        resolve(uploadPath)
      })
    },


    // 上传背景图片
    async uploadDatas() {
      return new Promise(async (resolve) => {
        await this.$credential(14,this.clubId)
        let uploadPath = await this.$uploadFile(4,this.currentAvatarObj)
        resolve(uploadPath)
      })
    },

    // 点击确定编辑俱乐部
    async submit(){
      if(this.currentAvatar) {
        this.editInfos.url = await this.uploadData()
      }
      if(!this.editInfos.address) {
				this.$message({
					message: '俱乐部地址不能为空',
					type: 'warning'
				})
        return
			}
      if(!this.editInfos.clubName) {
				this.$message({
					message: '俱乐部名称不能为空',
					type: 'warning'
				})
        return
			}
			if(!this.editInfos.introduction) {
				this.$message({
					message: '俱乐部介绍不能为空',
					type: 'warning'
				})
				return
			}
      let data = {
        "id": this.editInfos.id,
        "clubName": this.editInfos.clubName,
        "labelRequestVoList": this.editInfos.clubLabelInfoResponseVos,
        "country": "中国",
        "province": this.editInfos.province,
        "city": this.editInfos.city,
        "area": this.editInfos.area,
        "address": this.editInfos.address,
        "longitude": this.editInfos.longitude,
        "latitude": this.editInfos.latitude,
        "introduction": this.editInfos.introduction,
        "url": this.editInfos.url
      }
      // 待图片上传完成后再请求创建俱乐部API
			if(data.clubName == this.info.clubName && data.introduction == this.info.introduction && data.longitude == this.info.longitude && data.url == this.info.url && data.labelRequestVoList[0].labelId == this.info.clubLabelInfoResponseVos[0].labelId) {
				this.showActivityType = false
				return
			}
			const loading = this.$loading({
				lock: true,
				text: "Loading",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			})
      try{
        let res = await this.$request.post('/clubInfo/editClub', data)
        if(res.code === 200){
          this.$emit('refresh', true)
          this.showActivityType = false
          this.$message({
            showClose: true,
            message: '俱乐部资料已保存',
            type: 'success'
          });
          this.currentAvatar = ''
					this.inputAddress = ''
        }
      }catch(e){
				// console.log(e)
				this.showActivityType = false
      }
      loading.close()
    },

    //打开加入俱乐部标签
    openClub(){
      this.upPop = true      
    },

    //关闭加入俱乐部弹窗
    close(){
      this.upPop = false
    },
		
		//去除左侧空格
		LTrim(str) { 
		return str.replace(/^\s*/g,"")
		},
		
		//去右空格 
		RTrim(str) { 
		return str.replace(/\s*$/g,"")
    },
    
		// 获取俱乐部名称长度
		getThemeLength(e){
			let cursor = e.length								// 获取光标位置
			let regSpace = /\s{3,}|[\r\n]/g			// 多个空格或换行
			let trimStrL = this.LTrim(e)				// 去掉左边空格
			let trimStrC = ''										// 替换空格后数据
			let realLength = 0 									// 字节长度
			let byteItemLength = 0
			if(cursor !== -1) {
				let strL = trimStrL.slice(0,cursor)
				cursor = strL.replace(regSpace, '  ').length
			}
			trimStrC = Array.from(trimStrL.replace(regSpace,'  '))
			if(trimStrC.length == 0) {
				this.releaseNameLen = 0
			}
			for(let i =0 ; i<trimStrC.length; i++){
				byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
				realLength += byteItemLength
				if(realLength >= 1 && realLength <= 3){
					this.releaseNameLen = 1
				} else {
					if(Math.ceil(parseFloat(realLength)/3) > 15) {
						trimStrC = trimStrC.splice(0,i)
						this.$message.warning({
              showClose: true,
							message: '标题最多不能超过15字哦～',
						})
						this.releaseNameLen = 15
					} else {
						this.releaseNameLen = Math.ceil(parseFloat(realLength)/3)
					}
				}
      }
      this.editInfos.clubName = trimStrC.join("")
      this.clubNameStatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.editInfos.clubName)).split("").map(val => val.charCodeAt()).length)/3)
			return {
				value:trimStrC.join(""),
			}
    },
    
		// 获取俱乐部介绍长度
		getIntroduvtionLen(e) {
			let cursor = e.length						// 获取光标位置
			let regSpace = /^\n{2,}/gm			// 多个空格
			let trimStrL = this.LTrim(e)		// 去掉左边空格
			let trimStrC = ''								// 替换空格后数据
			let realLength = 0 							// 实际字符串的长度
			let byteItemLength = 0					// 单个字节长度
			if(cursor !== -1) {
				let strL = trimStrL.slice(0,cursor)
				cursor = strL.replace(regSpace, '\n').length
			}
			trimStrC = trimStrL.replace(regSpace,"\n")
			trimStrC = Array.from(trimStrC)
			if(trimStrC.length == 0) {
				this.introductionLen = 0
			}
			for(let i =0 ; i<trimStrC.length; i++){
				byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
				realLength += byteItemLength
				if(realLength >= 1 && realLength <= 3){
					this.introductionLen = 1
				} else {
					if(Math.ceil(parseFloat(realLength)/3) > 300) {
						trimStrC = trimStrC.slice(0,i)
						this.introductionLen = 300
						this.$message({
							message: '介绍最多不能超过300字哦～',
							type: 'warning'
						})
					} else {
						this.introductionLen = Math.ceil(parseFloat(realLength)/3)
					}
				}
			}
      this.editInfos.introduction = trimStrC.join("")
			this.clubIntroStatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.editInfos.introduction)).split("").map(val => val.charCodeAt()).length)/3)
			return {
				value:trimStrC.join("")
			}
    },
    
    // 打开俱乐部标签编辑
    openMenus(){
      this.openMenu = !this.openMenu
      this.showLocationSearchList = false
    },
		
    show(){
      this.showLocationSearchList = false
			this.openMenu = false
    },
		
		// 显示地址列表
		openAddressInput(){
			this.openMenu = false
			this.showLocationSearchList = !this.showLocationSearchList
    },
    
    search(msg){
			this.inputAddress = msg.addressInput
			this.editInfos.latitude = msg.latitude
			this.editInfos.longitude = msg.longitude
			this.editInfos.province = msg.province
			this.editInfos.city = msg.city
			this.editInfos.area = msg.area
			this.editInfos.address = msg.address
			this.showLocationSearchList = false
    },

		noNeverClick(){
			return
    },
    
    searchAddress() {
      this.enterEvents = true
    }
  }
}
</script>

<style lang="scss">
.club-detail{
  width: 790px;
  height: 289px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  .club-head-left{
    flex: 1;
    width: 0;
    height: 100%;
    padding: 20px 0 20px 20px;
    margin-top: 10px;
    box-sizing: border-box;
    .club-head-top{
      display: flex;
      position: relative;
      .top-head{
        .club-head-label{
          height: 26px;
          line-height: 26px;
          padding: 0 10px;
          font-size: 14px;
          color: #1465CF;
          background-color:#EDF5FF;
          border-radius: 50px;
          margin-top: 10px;
          margin-left: 18px;
          box-sizing: border-box;
          display: inline-block;
        }
        .name-edit {
          display: flex;
          .club-name{
            font-size: 18px;
            font-weight: bold;
            color: #444444;
            margin-left: 20px;
            margin-top: 10px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .editImg{
            cursor: pointer;
            margin-left: 10px;
            margin-top: 12px;
            .addClass{
              width: 20px;
              height: 20px;
              display: block;
            }  
          }
        }
      }
    }
    .club-head-middle {
      width: 100%;
        position: relative;
      .name-introduction {
        margin-top: 20px;
        margin-bottom: 30px;
        position: relative;
        .club-introduction{
          width: 300px;
          font-size: 14px;
          color: #888888;
          margin-top: 5px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .showDetail{
          position: absolute;
          right: 0px;
          top: 19px;
          font-size: 14px;
          cursor: pointer;
          color: rgb(49, 120, 212);
        }
      }
      .club-time{
        font-size: 14px;
        color: #444444;
        margin-bottom: 15px;
      }
      .address-box{
        box-sizing: border-box;
				display: flex;
				.drees-box{
					display: flex;
				}
        .club-address{
          box-sizing: border-box;
          width: 100%;
          font-size: 14px;
          color: #444444;
          margin-bottom: 15px;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
        }
        .showclubDetail{
					width: 2em;
					flex-shrink: 0;
          font-size: 14px;
          cursor: pointer;
          color: rgb(49, 120, 212);
        }
      }
    }
    .club-head-bottom{
      display: flex;
      align-content: center;
      .club-label{
        display: flex;
        justify-content: center;
        align-content: center;
        width: auto;
        height: 26px;
        border-radius: 5px;
        border: 1px solid #DDDDDD;
        font-size: 14px;
        font-weight: 400;
        color: #444444;
        line-height: 20px;
        margin-right: 20px;
        padding: 3px 13px 3px 13px;
        box-sizing: border-box;
        cursor: pointer;
        .plus{
          height: 12px;
          color: #1465CF;
          font-size: 25px;
          margin-top: 5px;
          display: inline-block;
          padding-right: 5px;
        }
      }
      .club-label-more{
        display: inline-block;
        width: 28px;
        height: 26px;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;                        
        .el-image-more{
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 5px;
          box-sizing: border-box;
          border: 1px solid #DDDDDD;
          border-radius: 5px;
        }
      }
    }
  }
  .club-head-right{
    flex-shrink: 0;
    height: 287px;
    position: relative;
    .hide {
      border-top: 144px solid #fff;
      border-left: 55px solid #fff;
      border-right: 55px solid transparent;
      border-bottom: 144px solid transparent;
      position: absolute;
      left: 0;
      top: 0;
    }
    .club-bg{
      width: 442px;
      height: 287px;
      position: relative;
    }
    .club-views-bg{
      position: absolute; 
      right: 10px;
      top: 250px;
      height: 25px;
      padding: 0 10px;
      opacity: 0.69;      
      background: #FFFFFF;
      border-radius: 5px;
      cursor: pointer;
      .club-views{
        font-size: 12px;
        color: #000000;
      }
    }
  }
}

.message-popover {
  .message-popover-item {
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #444;
  }
  .message-popover-item:last-child {
    margin-bottom: 0;
  }
  .message-popover-item:hover {
    color: #1465CF;
  }
}

.edit-popup-wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: transform 0.3s;
  z-index: 999;
}
.edit-popup {
  width: 630px;
  border-radius: 5px;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .edit-title{
    text-align: center;
    padding-bottom: 14px;
    font-size: 16px;
    color: #444444;
    font-weight: bold;
  }
  .edit-detail{
    display: flex;
    .edit-left{
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 35px;
      .img-avatar{
        width: 150px;
        height: 150px;
        border-radius: 5px;
        overflow: hidden;
        .upload{
          width: 100%;
          height: 100%;
        }
      }
      .img-intro1{
        font-size: 12px;
        font-weight: bold;
        color: #444444;
        margin-top: 10px;
      }
      .img-intro2{
        font-size: 12px;
        font-weight: bold;
        color: #444444;
      }                    
      .upload-btn {
        width: 76px;
        height: 32px;
        background: #1465CF;
        border-radius: 5px;
        margin-top: 20px;
        font-size: 14px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .edit-right{
      flex: 1;
      .inputContent{
        position: relative;
        width: 309px;
        border-radius: 5px;
        .input-theme{
          width: 309px;
          height: 34px;
          border-radius: 5px;      
          font-size: 12px;  
					outline: none;
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          border: none!important;
        }
        .input-theme>>>.el-input__inner {
          -webkit-appearance: none;
          background-color: #FFF;
          background-image: none;
          border-radius: 5px;
          border: 1px solid #fff !important; //改成0，边框就消失了！
          width: 100%;
        }
        .text-statistics{
          position: absolute;
          top: 9px;
          right: 5px;
          font-size: 12px;
          font-family: PingFang, PingFang-Regular;
          font-weight: 400;
          text-align: center;
          color: #a8a8a8;
          z-index: 99;
          line-height: 17px;        
        }
      }

      .edit-introduction{
        margin-top: 20px;
        position: relative;
        .input-intro{
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          border: none!important;
        }
        textarea {
          width: 309px;
          font-size: 13px;
          color: #444444;
          resize: none;
          outline: none;
          line-height: 20px;
          font-weight: 400;
          box-sizing: border-box;
          padding: 5px 6px;
          border-radius: 5px;
        }
        .text-statistics{
          position: absolute;
          top: 80px;
          right: 52px;
          font-size: 12px;
          font-family: PingFang, PingFang-Regular;
          font-weight: 400;
          text-align: center;
          color: #a8a8a8;
          z-index: 99;
          line-height: 17px;        
        }
      }

      .label-line {
        width: 309px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 20px;
        .label{
          font-size: 14px;
          color: #444444;
          margin-right: 30px;
          display: flex;
          align-items: center;
          .label-title {
            flex-shrink: 0;
          }
          .label-item{
            width: 282px;
            .labels-text{
              color: #1465CF;
              padding: 2px 10px;
              border-radius: 50px;
              background: #EDF5FF;
              margin-left: 30px;
            }
          }
        }
        .moreIcon{
          margin-left: 9px;
          .mores{
            width: 16px;
            height: 7px;
          }
        }
      }

      .label-box-item {
        max-height: 400px;
        overflow: scroll;
        overflow-x: hidden;
        padding-bottom: 5px;
        overflow-y: auto;
        box-sizing: border-box;
        .label-box-mian {
          margin: 10px 0;
          font-size: 24px;
          .box-main-title {
            margin-bottom: 10px;
            font-size: 14px;
            color: #000;
          }
          .box-main-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            flex-shrink: 0;
						.label-style {
							font-size: 14px;
							color: #666;
							padding: 2px 8px;
							border-radius: 15px;
							margin: 0 15px 7px 0;
							border: 1px solid #d3d3d3;
							cursor: pointer;
							margin-bottom: 10px;
						}
						.label-style.active {
							color: #005faf;
							background: rgba(0, 95, 175, 0.05);
							border-color: rgba(0, 95, 175, 0.05);
						}
          }
        }
        
      }

      .clubDress{
        margin-top: 20px;
				.drees-father-box{
					margin-bottom: 20px;
					display:flex;
					.dress-text{
						font-size: 14px;
						font-family: PingFang-SC-Regular, PingFang-SC;
						font-weight: 400;
						color: #444444;
						line-height: 20px;
						margin-right: 12px;
						margin-top: 10px;
					}
					.input-dress{
						margin-bottom: 10px;
						.address-father-box{
							position: relative;
						}
					}
				}
				
      }
    }
  }
  .confirm{
    width: 76px;
    height: 32px;
    background: #1465CF;
    border-radius: 5px;
    margin: 63px auto;
    margin-bottom: 20px;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.el-message-box__message p {
    width: auto !important;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #444444;
    line-height: 22px;
    margin-left: 15px;
    margin-top: 15px;
}

.box-card{
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .box-view-top{
    width: 522px;
    height: 304px;
    background: #FFFFFF;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin-bottom: 58px;
    .title{
      .title-text{
        margin-left: 229px;
      }
      .close{
        width: 15px;
        height: 15px;
        margin-left: 195px;
        margin-top: 25px;
        cursor: pointer;
      }
    }
    .type-titles{
      width: 154px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #F5A623;
      line-height: 20px;
      margin-left: 40px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .introduction{
      font-size: 12px;
      font-weight: 500;
      color: #444444;
      line-height: 17px;
      margin-left: 40px;
      .textarea{
        width: 442px;
        font-size: 16px;
        color: #999;
        margin-top: 10px;
        outline: none;                   
      }
    }
    .confirm{
      width: 76px;
      height: 32px;
      background: #1465CF;
      border-radius: 5px;
      margin: 25px auto;
      cursor: pointer;
      .confirm-text{
        width: 28px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        padding: 7px 22px;
      }
    }
  }
  .box-view-bottom{
    width: 630px;
    height: 376px;
    background: #FFFFFF;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    .title{
      .title-text{
        margin-left: 305px;
      }
      .close{
        width: 15px;
        height: 15px;
        margin-left: 220px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
    .content-box{
      border-bottom: 1px solid #DDDDDD;	
      padding-bottom: 20px;			
      .title{
        display: flex;
        justify-content: space-around;
        align-content: center;
        margin-right: 65px;
          margin-left: -20px;
        margin-top: 20px;
        .labels{
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #A8A8A8;
          line-height: 20px;						
        }
      }
      .reply{
        margin-top: 8px;
        display: flex;
        align-content: center;
        .labels1{
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 30px;					
        }
        .labels2{
          height: 40px;
          width: 126px;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 30px;
          display: inline-block;		
          .labels2-text{
            width: 100%;
            height: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;							
          }					
        }
        .labels3-loading{
          height: 26px;
          border-radius: 5px;
          border: 1px solid #DDDDDD;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 27px;	
          padding: 3px 10px 0 10px;				
        }
        .labels3-success{
          height: 26px;
          border-radius: 5px;
          border: 1px solid#1465CF;
          font-size: 14px;
          font-weight: 400;
          color: #1465CF;
          line-height: 20px;
          margin-left: 27px;	
          padding: 3px 10px 0 10px;				
        }					
        .labels4{
          height: 40px;
          width: 75px;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 100px;
          display: inline-block;
          .labels4-text{
            width: 100%;
            height: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }					
        }										
      }
      .reply:last-child{
      border: none !important;
    }
    }
    .content-box:last-child{
      border: none !important;
      margin-bottom: 15px;
    }
  }
}
.box-click {
  background: #EDF5FF;
  color: #1465CF !important;
}


</style>
<style lang="scss">
  .el-message_icon{
    font-size: 30px;
    margin-left: 20px;
  }
  .el-icon-success {
    color: #FFAF5A !important;
    font-size: 16px;
  }
  .el-icon-warning {
    color: #FFAF5A !important;
    font-size: 16px;
  }
  .el-message--warning .el-message__content {
    color: #fff;
  }
  .el-message{
    min-width: auto;
    background-color: #000000;
    border-radius: 5px;
    opacity: 0.8;
    padding: 11px 15px 9px 20px;
  }
  .el-message--success .el-message__content{
    color: #FFFFFF!important;
  }
  .el-message__closeBtn {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 19px;
  }
  .el-message__icon {
    margin-right: 10px;
    font-size: 30px;
    position: relative;
  }
  .el-icon-success:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 5px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    font-size: 16px;
    z-index: -1;
  }
  .el-icon-warning:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 5px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    font-size: 16px;
    z-index: -1;
  }
  .el-icon-success:before {
    content: "\e79c";
  }
</style>