<template>
	<div class="box-card"  v-if="isOpen">
		<span class="box-view-top">
			<div class="title">
				<span class="title-text">提示</span>
				<icon-font name="close" color="#444444" size="16px"  @click="close" style="cursor: pointer;margin-right: 15px;margin-top: -40px"></icon-font>
			</div>
			<div class="introductions">
				<div class="intro-text">{{title}}</div>
			</div>
			<div class="button-box">
				<div class="cancel">
					<div class="cancel-text" @click="close">取消</div>
				</div>
				<div class="confirm" :style="{background: confirmColor }">
					<div class="confirm-text" @click="submit">确定</div>
				</div>	
			</div>			
		</span>
	</div>
</template>

<script>
import iconFont from './IconFont'
export default {
	data() {
		return {
			loading: false,
			description: '',
			getReply:[],
			reportTypeList: [],																						// 投诉类型	
			id: null,																											// 被投诉对象的ID	
			currentId: null,
		}
	},
	components:{
		iconFont,
	},
	props:{
		isOpen:{																				// 是否打开弹窗
			type: Boolean,
			default: false
		},
		title: {
			type: String,																	// 弹窗标题
			default: ""
		},
		confirmColor: {
			type: String,
			default: "#EC4747"
		}
	},

	methods:{
		//关闭投诉框
		close(){
			this.$emit('close')													// 提示关闭
		},
		submit(){
			this.$emit('confirm')												// 提示确认
		}
	}
}
</script>

<style lang="scss" scoped>
	.box-card{
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .2);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99999;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.box-view-top{
			box-sizing: border-box;
			width: 480px;
			height: auto;
			background: #FFFFFF;
			border-radius: 10px;
			overflow: hidden;
			position: relative;
			// margin-bottom: 58px;
			.title{
				margin-top: 20px;
				display: flex;
				.title-text{
					width: 100%;
					// margin: 20px 258px;
					margin-left: 229px;
					margin-bottom: 30px;
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: bold;
					color: #000000;
					line-height: 25px;;
				}
				.close{
					width: 15px;
					height: 15px;
					// margin-right: 195px;
					// margin-top: 25px;
					cursor: pointer;
				}
			}
			.type-title{
				width: 154px;
				height: 20px;
				font-size: 14px;
				font-weight: 400;
				color: #444444;
				line-height: 20px;
				margin-left: 40px;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			.introductions{
				width: 100%;
				box-sizing: border-box;
				.intro-text{
					box-sizing: border-box;
					text-align: center;
					// margin: 0 auto;
					font-size: 14px;
					font-family: PingFang-SC-Regular, PingFang-SC;
					font-weight: 400;
					color: #444444;
					line-height: 20px;
				}
			}
			.button-box{
				display: flex;
				justify-content: center;
				margin-top: 10px;
				.confirm{
					width: 100px;
					height: 30px;
					background: #EC4747;
					border-radius: 5px;
					// margin: 25px auto;
					cursor: pointer;
					margin-right: 130px;
					.confirm-text{
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
						padding: 6px 35px;
					}
				}
				.cancel{
					width: 100px;
					height: 30px;
					// background: #DDDDDD;
					border: 1px solid #DDDDDD;
					border-radius: 5px;
					margin: 25px auto;
					margin-left: 135px;
					cursor: pointer;
					.cancel-text{
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #444444;
						line-height: 20px;
						padding: 6px 35px;
					}
				}
			}
		}
	}
	.box-click {
		background-color: #fff;
		border: 1px solid #1465CF!important;
	}
</style>
