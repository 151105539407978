<template>
  <div>
    <!-- 数据看板 -->
    <data-board></data-board>

    <!-- 积极参与活动用户 -->
    <active-user></active-user>

    <!-- 数据列表 -->
    <data-table></data-table>
  </div>
</template>

<script>
  import dataBoard from '../../components/clubActivityData/data-board.vue'
  import activeUser from '../../components/clubActivityData/active-user.vue'
  import dataTable from '../../components/clubActivityData/data-table.vue'
  export default {
    data() {
      return {
        
      }
    },

    components: {
      dataBoard,
      activeUser,
      dataTable
    },

    methods: {
      deleteRow(index, rows) {
        rows.splice(index, 1);
      }
    },
  }
</script>

<style scoped lang="scss">
  
</style>